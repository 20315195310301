// import axios from "axios";

import courses from "./courses.store";
import members from "./members.store";
import monthlyTemplates from "./monthly-templates.store";
import products from "./products.store";

// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

// modules
const modules = {
  members,
  monthlyTemplates,
  courses,
  products
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
