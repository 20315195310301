<template>
  <div>
    <div v-if="value">{{ prefix }}{{ value }}</div>
    <v-dialog v-model="dialog" max-width="500" @click:outside="reset()" v-else>
      <template v-slot:activator="{ on: onDialog, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: onTooltip }">
            <v-btn icon v-bind="attrs" v-on="{ ...onDialog, ...onTooltip }"
              ><v-icon>mdi-lock</v-icon></v-btn
            >
          </template>
          <span>Unlock</span>
        </v-tooltip>
      </template>
      <v-card>
        <v-card-title>Enter Sensitive Data Password</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4" v-if="!appPassword.checkComplete"
          >Loading...</v-card-text
        >
        <v-card-text
          class="pt-4"
          v-if="appPassword.checkComplete && !appPassword.hasPassword"
        >
          <p>
            Your app doesn't have a password. You will need to create one so you
            can unlock this field.
          </p>
          <v-btn
            v-if="
              hasPermission('edit-sensitive-data-password', $route.params.id)
            "
            depressed
            color="accent"
            :to="{ name: 'app-settings', params: { id: $route.params.id } }"
            >Create Password</v-btn
          >
        </v-card-text>
        <v-card-text
          class="pt-7 pb-0 grey lighten-4"
          v-if="appPassword.hasPassword"
        >
          <v-form
            @submit.prevent="runDecryption"
            method="post"
            id="decryption-form"
          >
            <v-text-field
              label="Password"
              v-model="fields.sensitive_data_password"
              type="password"
              background-color="white"
              outlined
              :error="checkError"
              :error-messages="checkError ? 'Password Incorrect' : null"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider v-if="appPassword.hasPassword"></v-divider>
        <v-card-actions v-if="appPassword.hasPassword">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="reset()">Close</v-btn>
          <v-btn
            type="submit"
            color="accent"
            text
            :loading="checkInProgress"
            form="decryption-form"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    table: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      appPassword: {
        checkComplete: false,
        hasPassword: false,
      },
      isLocked: true,
      dialog: false,
      checkInProgress: false,
      fields: {
        sensitive_data_password: null,
      },
      checkError: false,
      value: null,
    };
  },

  created() {
    this.$store
      .dispatch("appSettings/appHasPassword", {
        appId: this.$route.params.id,
      })
      .then(resp => {
        this.appPassword.checkComplete = true;
        this.appPassword.hasPassword = resp.has_password;
      })
      .catch(() => {
        this.dialog = false;
      });
  },

  methods: {
    runDecryption() {
      this.checkInProgress = true;
      this.checkError = false;

      this.$store
        .dispatch("sensitiveData/decrypt", {
          appId: this.$route.params.id,
          fields: {
            sensitive_data_password: this.fields.sensitive_data_password,
            table: this.table,
            field: this.field,
            id: this.id,
          },
        })
        .then(resp => {
          this.isLocked = false;
          this.dialog = false;
          this.checkInProgress = false;
          this.value = resp.value;
        })
        .catch(() => {
          this.checkError = true;
          this.checkInProgress = false;
        });
    },

    reset() {
      this.isLocked = true;
      this.dialog = false;
      this.fields.sensitive_data_password = null;
      this.checkInProgress = false;
      this.checkError = false;
    },
  },
};
</script>
