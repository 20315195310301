import applications from "./applications.store";
import breeds from "./breeds.store";
import customers from "./customers.store";
import dogs from "./dogs.store";
import litters from "./litters.store";
import orders from "./orders.store";
import products from "./products.store";
import resources from "./resources.store";
import testimonials from "./testimonials.store";
import tasks from "./tasks.store";

// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

// modules
const modules = {
  products,
  dogs,
  breeds,
  orders,
  applications,
  testimonials,
  tasks,
  resources,
  customers,
  litters,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
