import store from "@/stores/";

export default [
  {
    path: "workload",
    component: () => import("@/modules/workload/views/Template"),

    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("workload/loadModule", {
          appId: to.params.id,
          module: "workload",
        }),
        store.dispatch("customers/loadModule", {
          appId: to.params.id,
          module: "customers",
        }),
        store.dispatch("team/loadTeamMembers", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-workload-tasks",
        component: () => import("@/modules/tasking/views/my-tasks/Tasks"),
      },
      {
        path: "work-groups",
        name: "module-workload-work-groups",
        component: () => import("@/modules/workload/views/groups/WorkGroups"),
      },
      {
        path: "planning",
        name: "module-workload-task-planning",
        component: () => import("@/modules/tasking/views/planning/Tasks"),
      },
      {
        path: "activity",
        name: "module-workload-activity",
        component: () => import("@/modules/workload/views/activity/Dashboard"),
      },
      {
        path: "time-analysis",
        name: "module-workload-task-time-analysis",
        component: () =>
          import("@/modules/tasking/views/time-analysis/Dashboard"),
      },
      {
        path: "orphan-tasks",
        name: "module-workload-task-orphan-tasks",
        component: () => import("@/modules/tasking/views/orphan-tasks/Tasks"),
      },
      {
        path: "list",
        name: "module-workload-all",
        component: () => import("@/modules/workload/views/Dashboard"),
      },
      {
        path: "recurring",
        name: "module-workload-recurring",
        component: () => import("@/modules/tasking/views/Tasks"),
      },
      {
        path: "archived",
        name: "module-workload-recurring-archived",
        component: () => import("@/modules/tasking/views/ArchiveTasks"),
      },
      {
        path: "my-time",
        component: () => import("@/modules/workload/views/Template"),
        children: [
          {
            path: "",
            name: "module-workload-my-time",
            component: () =>
              import("@/modules/workload/views/my-time/Individual"),
          },
        ],
      },
      {
        path: "groups",
        component: () => import("@/modules/workload/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("workload/loadModule", {
              appId: to.params.id,
              module: "workload",
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: [
          {
            path: "",
            name: "module-workgroup",
            component: () =>
              import("@/modules/workload/views/groups/WorkGroups"),
          },
          {
            path: "closed",
            name: "module-workgroup-closed",
            component: () => import("@/modules/workload/views/groups/Closed"),
          },
          {
            path: ":groupId",
            name: "module-group-individual",
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              store
                .dispatch("workload/loadGroup", {
                  appId: to.params.id,
                  groupId: to.params.groupId,
                })
                .then(() => {
                  store.commit("showHideLoader", false);
                  next();
                });
            },
            component: () =>
              import("@/modules/workload/views/groups/Individual"),
          },
        ],
      },
      {
        path: "chunks",
        component: () => import("@/modules/workload/views/Template"),
        children: [
          {
            path: ":chunkId",
            name: "module-workload-individual",
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              store
                .dispatch("workload/loadWorkChunk", {
                  appId: to.params.id,
                  chunkId: to.params.chunkId,
                })
                .then(() => {
                  store.commit("showHideLoader", false);
                  next();
                });
            },
            component: () =>
              import("@/modules/workload/views/chunks/Individual"),
          },
        ],
      },
    ],
  },
];
