import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  products: [],
  archived: [],
};

// getters
const getters = {};

// actions
const actions = {
  loadProducts({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/products`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadProductsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadProduct(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/products/${data.productId}`,
        method: "GET",
      })
        .then((resp) => {
          resolve(resp.data.product);
        })
        .catch((err) => reject(err));
    });
  },
  saveProduct({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/products/${data.productId}`
      : `${getApiUrl(data.appId)}/module/lhl/products`;

    let fields = data.fields;

    if (fields.image_has_changed === false) {
      fields.image = null;
    }

    if (fields.composition_image_has_changed === false) {
      fields.composition_image = null;
    }

    if (fields.kibble_size_image_has_changed === false) {
      fields.kibble_size_image = null;
    }

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (
        fields[field] === null ||
        typeof fields[field] === "undefined"
      ) {
        formData.append(field, "");
      } else if (field === "analytical_constituents") {
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(
            `analytical_constituents[${i}][text]`,
            data[i]["text"]
          );
          formData.append(
            `analytical_constituents[${i}][value]`,
            data[i]["value"]
          );
        }
      } else if (typeof fields[field] === "object") {
        if (fields[field] instanceof File) {
          formData.append(field, fields[field]);
        }
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(field + "[]", data[i]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveProductSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/products/${data.productId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteProductSuccess", { productId: data.productId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
};

// mutations
const mutations = {
  loadProductsSuccess(state, data) {
    state.products = data.products;
    state.archived = data.archived;
  },
  saveProductSuccess(state, data) {
    if (data.isEditing) {
      const index = state.products.findIndex((p) => p.id === data.product.id);
      state.products.splice(index, 1, data.product);
    } else {
      state.products.push(data.product);
    }
  },
  deleteProductSuccess(state, data) {
    const index = state.products.findIndex((p) => p.id === data.productId);
    state.archived.push(state.products[index]);
    state.products.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
