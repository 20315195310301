import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  courses: [],
  archived: [],
  course: {},
};

// getters
const getters = {};

// actions
const actions = {
  loadCourses({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/flightmode/courses`,
        method: "GET",
      })
        .then(resp => {
          commit("loadCoursesSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  loadCourse({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/flightmode/courses/${
          data.courseId
        }`,
        method: "GET",
      })
        .then(resp => {
          commit("loadCourseSuccess", { ...resp.data });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  saveCourse({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/flightmode/courses/${data.courseId}`
      : `${getApiUrl(data.appId)}/module/flightmode/courses`;

    return new Promise((resolve, reject) => {
      axios
        .post(url, data.formData)
        .then(resp => {
          commit("saveCourseSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteCourse({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/flightmode/courses/${
          data.courseId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteCourseSuccess", { courseId: data.courseId });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  restoreCourse({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/flightmode/courses/${
          data.courseId
        }/restore`,
        method: "POST",
      })
        .then(resp => {
          commit("restoreCourseSuccess", { ...resp.data });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  saveDay({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/flightmode/courses/${
          data.courseId
        }/days/${data.dayId}`
      : `${getApiUrl(data.appId)}/module/flightmode/courses/${
          data.courseId
        }/days`;

    return new Promise((resolve, reject) => {
      axios
        .post(url, data.formData)
        .then(resp => {
          commit("saveDaySuccess", {
            ...resp.data,
            isEditing: data.isEditing,
            index: data.index,
          });
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteDay({ commit }, data) {
    if (data.isSaved) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${getApiUrl(data.appId)}/module/flightmode/courses/${
            data.courseId
          }/days/${data.dayId}`,
          method: "DELETE",
        })
          .then(() => {
            commit("deleteDaySuccess", {
              courseId: data.courseId,
              dayId: data.dayId,
            });
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    } else {
      commit("deleteDayLocally", {
        index: data.index,
        courseId: data.courseId,
      });
    }
  },
};

// mutations
const mutations = {
  loadCoursesSuccess(state, data) {
    state.courses = data.courses;
    state.archived = data.archived;
  },
  loadCourseSuccess(state, data) {
    state.course = data.course;
  },
  saveCourseSuccess(state, data) {
    if (data.isEditing) {
      const index = state.courses.findIndex(c => c.id === data.course.id);
      state.courses.splice(index, 1, data.course);

      if (state.course) {
        state.course.name = data.course.name;
        state.course.description = data.course.description;
        state.course.price = data.course.price;
      }
    } else {
      state.courses.push(data.course);
    }
  },
  deleteCourseSuccess(state, data) {
    const index = state.courses.findIndex(c => c.id === data.courseId);
    state.archived.push(state.courses[index]);
    state.courses.splice(index, 1);
  },
  restoreCourseSuccess(state, data) {
    const index = state.archived.findIndex(c => c.id === data.course.id);
    state.archived.splice(index, 1);
    state.courses.push(data.course);
  },
  saveDaySuccess(state, data) {
    const courseIndex = state.courses.findIndex(
      c => c.id === data.day.course_id
    );

    if (data.isEditing) {
      const dayIndex = state.courses[courseIndex].days.findIndex(
        d => d.id === data.day.id
      );
      state.courses[courseIndex].days[dayIndex] = data.day;
      state.course.days[dayIndex] = data.day;
    } else {
      state.courses[courseIndex].days.splice(data.index, 1, data.day);
      state.course.days.splice(data.index, 1, data.day);
    }
  },
  deleteDaySuccess(state, data) {
    const courseIndex = state.courses.findIndex(c => c.id === data.courseId);
    const dayIndex = state.courses[courseIndex].days.findIndex(
      d => d.id === data.dayId
    );
    state.courses[courseIndex].days.splice(dayIndex, 1);
    state.course.days.splice(dayIndex, 1);
  },
  deleteDayLocally(state, data) {
    const courseIndex = state.courses.findIndex(
      c => c.id === parseInt(data.courseId)
    );
    state.courses[courseIndex].days.splice(data.index, 1);
    state.course.days.splice(data.index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
