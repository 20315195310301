// import axios from "axios";
// import { getApiUrl } from "@/utils";

// initial state
const state = {
  enquiries: [
    {
      id: 1,
      name: "Joe",
      email: "joe@example.com",
      phone: "01234567890",
      company: "Test LTD",
      date: "2012-02-02",
      embroidery_required: "Yes",
      additional_info: "lorem ipsum",
      postcode: "ASC 123",
      preferred_contact_method: "Phone",
    },
  ],
  enquiry: {},
};

// getters
const getters = {};

// actions
const actions = {
  loadEnquiries() {
    // return new Promise((resolve, reject) => {
    //   axios({
    //     url: `${getApiUrl(data.appId)}/module/grogreen/enquiries`,
    //     method: "GET",
    //   })
    //     .then((resp) => {
    //       commit("loadEnquiriesSuccess", { ...resp.data });
    //       resolve();
    //     })
    //     .catch((err) => {
    //       reject(err);
    //     });
    // });
    return Promise.resolve();
  },

  loadEnquiry({ commit }, data) {
    commit("loadEnquirySuccess", data);
  },
  saveEnquire() {
    // const url = data.isEditing
    //   ? `${getApiUrl(data.appId)}/module/grogreen/enquiries/${
    //       data.enquireId
    //     }`
    //   : `${getApiUrl(data.appId)}/module/grogreen/enquiries`;

    // let fields = data.fields;

    // if (fields.image_has_changed === false) {
    //   fields.image = null;
    // }

    // let formData = new FormData();
    // for (const field in fields) {
    //   if (typeof fields[field] === "boolean") {
    //     formData.append(field, fields[field] ? 1 : 0);
    //   } else if (fields[field] === null) {
    //     formData.append(field, "");
    //   } else {
    //     formData.append(field, fields[field]);
    //   }
    // }

    // return new Promise((resolve, reject) => {
    //   axios({
    //     url,
    //     data: formData,
    //     method: "POST",
    //   })
    //     .then((resp) => {
    //       commit("saveEnquiriesuccess", {
    //         ...resp.data,
    //         isEditing: data.isEditing,
    //       });
    //       resolve(resp);
    //     })
    //     .catch((err) => reject(err));
    // });
    return Promise.resolve();
  },
  deleteEnquire() {
    // return new Promise((resolve, reject) => {
    //   axios({
    //     url: `${getApiUrl(data.appId)}/module/grogreen/enquiries/${
    //       data.enquireId
    //     }`,
    //     method: "DELETE",
    //   })
    //     .then(() => {
    //       commit("deleteEnquiriesuccess", {
    //         enquireId: data.enquireId,
    //       });
    //       resolve();
    //     })
    //     .catch(() => {
    //       reject();
    //     });
    // });
    return Promise.resolve();
  },
};

// mutations
const mutations = {
  loadEnquiriesSuccess(state, data) {
    state.enquiries = data.enquiries;
  },
  loadEnquirySuccess(state, data) {
    state.enquiry = state.enquiries.find((p) => {
      return p.id === parseInt(data.enquiryId);
    });
  },
  saveEnquiriesuccess(state, data) {
    if (data.isEditing) {
      const index = state.enquiries.findIndex((t) => t.id === data.enquiry.id);
      state.enquiries.splice(index, 1, data.enquiry);
    } else {
      state.enquiries.push(data.enquiry);
    }
  },
  deleteEnquiriesuccess(state, data) {
    const index = state.enquiries.findIndex((t) => t.id === data.enquiryId);
    state.enquiries.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
