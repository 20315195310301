import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  bios: [],
  images: [],
  archived: [],
};

// getters
const getters = {
  all: (state) => state.bios,
  allImages: (state) => state.images,
  archived: (state) => state.archived,
};

// actions
const actions = {
  loadBios({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/includem/bios`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadBiosSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveBio({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/includem/bios/${data.bioId}`
      : `${getApiUrl(data.appId)}/module/includem/bios`;

    let fields = data.fields;

    if (fields.image_has_changed === false) {
      fields.image = null;
    }

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else if (typeof fields[field] === "object") {
        if (fields[field] instanceof File) {
          formData.append(field, fields[field]);
        }
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(field + "[]", data[i]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveBioSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteBio({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/includem/bios/${data.bioId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteBioSuccess", {
            bioId: data.bioId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  restoreBio({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/includem/bios/${
          data.bioId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreBioSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadBiosSuccess(state, data) {
    state.bios = data.bios;
    state.images = data.images;
    state.archived = data.archived;
  },
  saveBioSuccess(state, data) {
    if (data.isEditing) {
      const index = state.bios.findIndex((t) => t.id === data.bio.id);
      state.bios.splice(index, 1, data.bio);
    } else {
      state.bios.push(data.bio);
    }
  },
  restoreBioSuccess(state, data) {
    const index = state.archived.findIndex((c) => c.id === data.bio.id);
    state.archived.splice(index, 1);
    state.bios.push(data.bio);
  },
  deleteBioSuccess(state, data) {
    const index = state.bios.findIndex((t) => t.id === data.bioId);
    state.bios.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
