import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  users: [],
  appRoles: [],
  appPermissions: [],
};

// getters
const getters = {
  currentUserId: (state, getters, rootState, rootGetters) => {
    return rootGetters.user.id;
  },
  currentUser: (state, getters, rootState, rootGetters) => {
    return rootGetters.user;
  },
};

// actions
const actions = {
  loadModule({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("moduleLoadRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/${data.module}`,
        method: "GET",
      })
        .then((resp) => {
          const module = resp.data;
          commit("moduleLoadSuccess", { module });
          resolve(resp);
        })
        .catch((err) => {
          commit("moduleLoadError");
          reject(err);
        });
    });
  },
  addUser({ commit }, data) {
    commit("addUserRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/users`,
        data: { email: data.email, roles: data.roles },
        method: "POST",
      })
        .then((resp) => {
          const user = resp.data.user;
          commit("addUserSuccess", { user });
          resolve(resp.data);
        })
        .catch((err) => {
          commit("addUserError");
          reject(err);
        });
    });
  },
  editUser({ commit }, data) {
    commit("editUserRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/users/${data.userId}/edit`,
        data: {
          ...data,
        },
        method: "POST",
      })
        .then((resp) => {
          const user = resp.data.user;
          commit("editUserSuccess", { user });
          resolve();
        })
        .catch((err) => {
          commit("editUserError");
          reject(err);
        });
    });
  },
  updateStatus({ commit }, data) {
    commit("updateStatusRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/users/${data.id}/status`,
        data: {
          ...data,
        },
        method: "POST",
      })
        .then((resp) => {
          const user = resp.data.user;
          commit("updateStatusSuccess", { user });
          resolve();
        })
        .catch((err) => {
          commit("updateStatusError");
          reject(err);
        });
    });
  },
  resentSetupEmail({ commit }, data) {
    commit("resendSetupEmailRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/users/${data.userId}/resendSetupEmail`,
        method: "POST",
      })
        .then(() => {
          commit("resendSetupEmailSuccess");
          resolve();
        })
        .catch(() => {
          commit("resendSetupEmailError");
          reject();
        });
    });
  },
  updateRolePermissions({ commit }, data) {
    commit("updateRolePermissionsRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/users/roles/${data.roleId}/updatePermissions`,
        method: "POST",
        data: {
          permissions: data.permissions,
        },
      })
        .then((resp) => {
          const roleId = resp.data.role_id;
          const permissions = resp.data.permissions;
          commit("updateRolePermissionsSuccess", { roleId, permissions });
          resolve();
        })
        .catch(() => {
          commit("updateRolePermissionsError");
          reject();
        });
    });
  },
  loadPermissions({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/users/permissions`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadPermissionsSuccess", resp.data);
          resolve();
        })
        .catch(() => reject());
    });
  },
  loadRoles({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/users/roles`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadRolesSuccess", resp.data);
          resolve();
        })
        .catch(() => reject());
    });
  },
  createRole({ commit }, data) {
    commit("createRoleRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/users/roles`,
        method: "POST",
        data: {
          name: data.name,
          permissions: data.permissions,
        },
      })
        .then((resp) => {
          const role = resp.data.role;
          commit("createRoleSuccess", { role });
          resolve();
        })
        .catch((err) => {
          commit("createRoleError");
          reject(err);
        });
    });
  },
  cloneRole({ commit }, data) {
    commit("cloneRoleRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/users/roles/${data.roleId}/clone`,
        method: "POST",
        data: {
          name: data.name,
        },
      })
        .then((resp) => {
          const role = resp.data.role;
          commit("cloneRoleSuccess", { role });
          resolve();
        })
        .catch((err) => {
          commit("cloneRoleError");
          reject(err);
        });
    });
  },
  deleteRole({ commit }, data) {
    commit("deleteRoleRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/users/roles/${data.roleId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteRoleSuccess", { id: data.roleId });
          resolve();
        })
        .catch(() => {
          commit("deleteRoleError");
          reject();
        });
    });
  },
};

// mutations
const mutations = {
  moduleLoadRequest() {},
  moduleLoadSuccess(state, data) {
    state.users = data.module.users;
  },
  moduleLoadError() {},
  addUserRequest() {},
  addUserSuccess(state, data) {
    state.users.push(data.user);
  },
  addUserError() {},
  updateStatusRequest() {},
  updateStatusSuccess(state, data) {
    const index = state.users.findIndex((u) => u.id === data.user.id);
    state.users[index].pivot.status = data.user.pivot.status;
  },
  updateStatusError() {},
  editUserRequest() {},
  editUserSuccess(state, data) {
    const index = state.users.findIndex((u) => u.id === data.user.id);
    state.users[index].email = data.user.email;
    state.users[index].roles = data.user.roles;
  },
  editUserError() {},
  resendSetupEmailRequest() {},
  resendSetupEmailSuccess() {},
  resendSetupEmailError() {},
  updateRolePermissionsRequest() {},
  updateRolePermissionsSuccess(state, data) {
    const index = state.appRoles.findIndex(
      (r) => r.id === parseInt(data.roleId)
    );
    state.appRoles[index].permissions = data.permissions;
  },
  updateRolePermissionsError() {},
  loadPermissionsSuccess(state, data) {
    state.appPermissions = data.permissions;
  },
  loadRolesSuccess(state, data) {
    state.appRoles = data.roles;
  },
  createRoleRequest() {},
  createRoleSuccess(state, data) {
    state.appRoles.push(data.role);
  },
  createRoleError() {},
  cloneRoleRequest() {},
  cloneRoleSuccess(state, data) {
    state.appRoles.push(data.role);
  },
  cloneRoleError() {},
  deleteRoleRequest() {},
  deleteRoleSuccess(state, data) {
    state.appRoles = state.appRoles.filter(function (r) {
      return r.id !== data.id;
    });
  },
  deleteRoleError() {},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
