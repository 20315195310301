import axios from "axios";
import { getApiUrl } from "@/utils";

const state = {
  properties: [],
  groups: [],
  applications: [],
  property: {},
};

// getters
const getters = {
  all: (state) => state.properties,
  get: (state) => state.property,
  properties: (state) => state.properties,
  applications: (state) => state.applications,
  groups: (state) => state.groups,
};

// actions
const actions = {
  loadProperties({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/properties`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadPropertiesSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadViewingListPdf(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/properties/${
          data.propertyId
        }/pdf`,
        method: "POST",
        responseType: "arraybuffer",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  assignFormToGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/properties/${
          data.propertyId
        }/merge-to-group`,
        data: {
          application_group_id: data.application_group_id,
          application_id: data.application_id,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("assignFormToGroupSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  sendToLandlord({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/properties/${
          data.propertyId
        }/send-to-landlord`,
        method: "POST",
      })
        .then((resp) => {
          commit("sendToLandlordSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  sendToSubmittedApplications({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/properties/${
          data.propertyId
        }/send-to-all-applications`,
        method: "POST",
      })
        .then((resp) => {
          commit("sendToSubmittedApplicationsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  inviteToGroup({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/sbpm/properties/${
      data.propertyId
    }/group/${data.groupId}`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("assignFormToGroupSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadProperty({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/properties/${
          data.propertyId
        }`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadPropertySuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  rejectApplication({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/applications/${
          data.applicationId
        }/reject`,
        data: {
          comment_text: data.comment_text,
          reason: data.reason,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("rejectApplicationSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  rejectGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/applications/groups/${
          data.groupId
        }/reject`,
        data: {
          comment_text: data.comment_text,
          reason: data.reason,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("rejectGroupApplicationSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  rejectAllIfStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/properties/${
          data.propertyId
        }/reject-if-status`,
        data: {
          comment_text: data.comment_text,
          reason: data.reason,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("rejectAllIfStatusSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  savePropertyVisible({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/sbpm/properties/${
      data.propertyId
    }/visible`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("savePropertyVisibleSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  moveApplicationToProperty({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/sbpm/applications/groups/${
      data.groupId
    }/move-property`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveMovePropertySuccess", {
            ...resp.data,
            groupId: data.groupId,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveProperty({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/sbpm/properties/${data.propertyId}`
      : `${getApiUrl(data.appId)}/module/sbpm/properties`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("savePropertySuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteProperty({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/properties/${
          data.propertyId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deletePropertySuccess", {
            propertyId: data.propertyId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveNote({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/sbpm/properties/${
      data.propertyId
    }/note`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveNoteSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  deleteForm({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/applications/${
          data.applicationId
        }`,
        method: "DELETE",
      })
        .then((resp) => {
          const group = resp.data.group;

          commit("deleteFormSuccess", {
            applicationId: data.applicationId,
            groupId: group,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadPropertiesSuccess(state, data) {
    state.properties = data.properties;
  },
  deletePropertySuccess(state, data) {
    const index = state.properties.findIndex(
      (c) => c.property_id === data.propertyId
    );
    state.properties.splice(index, 1);
  },
  sendToLandlordSuccess(state, data) {
    state.property.audits.unshift(data.audit);
  },
  sendToSubmittedApplicationsSuccess(state, data) {
    state.property.audits.unshift(data.audit);
  },
  deleteFormSuccess(state, data) {
    const groupIndex = state.groups.findIndex(
      (c) => c.id === parseInt(data.groupId)
    );

    const index = state.groups[groupIndex].applications.findIndex(
      (c) => c.id === data.applicationId
    );

    state.groups[groupIndex].applications.splice(index, 1);

    if (state.groups[groupIndex].applications.length == 0) {
      state.groups.splice(groupIndex, 1);
    }
  },

  saveNoteSuccess(state, data) {
    state.property.notes.push(data.note);
  },

  rejectGroupApplicationSuccess(state, data) {
    const groupIndex = state.groups.findIndex(
      (c) => c.id === parseInt(data.group.id)
    );
    state.groups.splice(groupIndex, 1, data.group);
  },

  saveMovePropertySuccess(state, data) {
    const groupIndex = state.groups.findIndex(
      (c) => c.id === parseInt(data.groupId)
    );

    state.groups.splice(groupIndex, 1);
  },

  rejectApplicationSuccess(state, data) {
    const groupIndex = state.groups.findIndex(
      (c) => c.id === parseInt(data.application.application_group_id)
    );

    const index = state.groups[groupIndex].applications.findIndex(
      (c) => c.id === data.application.id
    );

    state.groups[groupIndex].applications.splice(index, 1, data.application);
  },

  savePropertySuccess(state, data) {
    state.property = data.property;

    const index = state.properties.findIndex((p) => p.id === data.property.id);
    state.properties.splice(index, 1, data.property);
  },
  savePropertyVisibleSuccess(state, data) {
    const index = state.properties.findIndex((p) => p.id === data.property.id);
    state.properties.splice(index, 1, data.property);
  },
  loadPropertySuccess(state, data) {
    state.property = data.property;
    state.applications = data.applications;
    state.groups = data.groups;
  },

  rejectAllIfStatusSuccess() {},

  assignFormToGroupSuccess(state, data) {
    const index = state.applications.findIndex(
      (c) => c.application_id === data.application.application_id
    );
    state.applications.splice(index, 1);

    if (data.application_merge) {
      const indexMerge = state.applications.findIndex(
        (c) => c.application_id === data.application_merge.application_id
      );
      state.applications.splice(indexMerge, 1);
      state.applications_group.push(data.group);
    } else {
      const indexGoup = state.applications_group.findIndex(
        (c) => c.application_group_id === data.application.application_group_id
      );

      state.applications_group[indexGoup].applications.push(data.application);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
