import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  settings: [],
  minimumStays: [],
  minimumStay: {},
};

// getters
const getters = {
  all: (state) => state.settings,
  minimumStays: (state) => state.minimumStays,
};

// actions
const actions = {
  loadSettings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/settings`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadSettingsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadMinimumStay({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/minimum-stay`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadMinimumStaySuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveMinimumStay({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/arnprior/minimum-stay/${
          data.minimumStayId
        }`
      : `${getApiUrl(data.appId)}/module/arnprior/minimum-stay`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveMinimumStay", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  deleteMinimumStay({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/minimum-stay/${
          data.minimumStayId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteMinimumStaySuccess", {
            minimumStayId: data.minimumStayId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateSettings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/settings`,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("updateSettingsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadSettingsSuccess(state, data) {
    state.settings = data.settings;
  },
  loadMinimumStaySuccess(state, data) {
    state.minimumStays = data.minimumStays;
  },
  saveMinimumStay(state, data) {
    if (data.isEditing) {
      const index = state.minimumStays.findIndex(
        (p) => p.id === data.minimumStay.id
      );
      state.minimumStays.splice(index, 1, data.minimumStay);
      state.minimumStay = data.minimumStay;
    } else {
      state.minimumStays.push(data.minimumStay);
    }
  },
  deleteMinimumStaySuccess(state, data) {
    const index = state.minimumStays.findIndex(
      (c) => c.id === data.minimumStayId
    );
    state.minimumStays.splice(index, 1);
  },
  updateSettingsSuccess(state, data) {
    state.settings = data.settings;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
