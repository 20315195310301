import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  timetable: {
    core: [],
    exceptionalHours: [],
  },
};

// getters
const getters = {
  exceptionalHours: (state) => state.timetable.exceptionalHours,
  coreHours: (state) => state.timetable.core,
};

// actions
const actions = {
  getDisabledDays(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/pool/get-disabled-days`,
        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => resolve(resp.data))
        .catch((err) => reject(err));
    });
  },
  getSwimSlots(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/pool/get-swim-slots`,
        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => resolve(resp.data))
        .catch((err) => reject(err));
    });
  },
  getPoolPartySwimSlots(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(
          data.appId
        )}/module/arnprior/pool/get-pool-party-swim-slots`,
        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => resolve(resp.data))
        .catch((err) => reject(err));
    });
  },
  getSummary(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/pool/get-summary`,
        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => reject(err));
    });
  },
  getSummaryParty(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/pool/get-summary-party`,
        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => reject(err));
    });
  },
  createBooking(_, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/arnprior/pool/bookings/${
          data.bookingId
        }`
      : `${getApiUrl(data.appId)}/module/arnprior/pool/bookings`;

    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => reject(err));
    });
  },

  saveBooking({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/arnprior/pool/bookings/${
      data.bookingId
    }`;

    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          commit("saveBookingSuccess", resp.data);
          resolve(resp.data);
        })
        .catch((err) => reject(err));
    });
  },

  loadTimetable({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/pool/timetable`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadTimetableSuccess", resp.data);
          resolve(resp.data);
        })
        .catch((err) => reject(err));
    });
  },
  saveCore({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/pool/timetable/${
          data.rangeId
        }/core`,

        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          commit("saveCoreSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp.data);
        })
        .catch((err) => reject(err));
    });
  },
  saveRange({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: data.isEditing
          ? `${getApiUrl(data.appId)}/module/arnprior/pool/timetable/${
              data.rangeId
            }`
          : `${getApiUrl(data.appId)}/module/arnprior/pool/timetable`,
        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          commit("saveRangeSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp.data);
        })
        .catch((err) => reject(err));
    });
  },
  deleteRange({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/pool/timetable/${
          data.rangeId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteRangeSuccess", {
            rangeId: data.rangeId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updatePriorities({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(
          data.appId
        )}/module/arnprior/pool/timetable/priorities`,
        method: "POST",
        data,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          commit("updatePrioritiesSuccess", resp.data);
          resolve(resp.data);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadTimetableSuccess(state, data) {
    state.timetable.core = data.courHours;
    state.timetable.exceptionalHours = data.exceptionalHours;
  },

  saveBookingSuccess() {
    // const index = state.bookings.findIndex((r) => r.id === data.booking.id);
    // state.bookings.splice(index, 1, data.booking);
  },

  saveCoreSuccess(state, data) {
    if (data.isEditing) {
      const index = state.timetable.core.findIndex(
        (r) => r.id === data.range.id
      );
      state.timetable.core.splice(index, 1, data.range);
    }
  },

  saveRangeSuccess(state, data) {
    if (data.isEditing) {
      const index = state.timetable.exceptionalHours.findIndex(
        (r) => r.id === data.range.id
      );
      state.timetable.exceptionalHours.splice(index, 1, data.range);
    } else {
      state.timetable.exceptionalHours.push(data.range);
    }
  },
  deleteRangeSuccess(state, data) {
    const index = state.timetable.exceptionalHours.findIndex(
      (r) => r.id === data.rangeId
    );
    state.timetable.exceptionalHours.splice(index, 1);
  },
  updatePrioritiesSuccess(state, data) {
    state.timetable.exceptionalHours = data.exceptionalHours;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
