import store from "@/stores/";

export default [
  {
    path: "party-pod",
    component: () => import("@/modules/arnprior/views/Template"),
    beforeEnter: (to, from, next) => {
      next();
    },
    children: [
      {
        path: "calendar",
        name: "module-arnprior-partypod-arnprior-party-calendar",
        component: () =>
          import("@/modules/arnprior/views/party-pod/calendar/Calendar"),
      },
      {
        path: "bookings",
        component: () => import("@/modules/arnprior/views/Template"),
        children: [
          {
            path: "",
            name: "module-arnprior-partypod-arnprior-party-bookings",
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              store
                .dispatch("arnprior/ordersStore/loadOrders", {
                  appId: to.params.id,
                  type: "party-pods",
                })
                .then(() => {
                  store.commit("showHideLoader", false);
                  next();
                });
            },
            component: () =>
              import("@/modules/arnprior/views/party-pod/bookings/Bookings"),
          },
          {
            path: ":bookingId",
            name: "module-arnprior-partypod-arnprior-party-bookings-individual",
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("arnprior/bookingsStore/loadBooking", {
                  appId: to.params.id,
                  bookingId: to.params.bookingId,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
            component: () =>
              import("@/modules/arnprior/views/party-pod/bookings/Individual"),
          },
        ],
      },
      {
        path: "settings",
        name: "module-arnprior-partypod-arnprior-party-settings",
        component: () =>
          import("@/modules/arnprior/views/party-pod/settings/Dashboard"),
      },
    ],
  },
];
