import endoftenancyRoutes from "./endoftenancy.routes";
import propertyenquiryRoutes from "./propertyenquiry.routes";

export default [
  {
    path: "ibm",
    component: () => import("@/modules/ibm/views/Template"),
    children: [...endoftenancyRoutes, ...propertyenquiryRoutes],
  },
];
