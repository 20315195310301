import axios from "axios";
import { getApiUrl } from "@/utils";

const state = {
  tenancies: [],
  endoftenancy: {},
};

// getters
const getters = {
  all: (state) => state.tenancies,
  get: (state) => state.endoftenancy,
};

// actions
const actions = {
  loadTenancies({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/ibm/endoftenancy`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadTenanciesSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadEndoftenancy({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/ibm/endoftenancy/${
          data.tenancyId
        }`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadEndoftenancySuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveEndoftenancy({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/ibm/endoftenancy/${data.tenancyId}`
      : `${getApiUrl(data.appId)}/module/ibm/endoftenancy`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveEndoftenancyuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteEndoftenancy({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/ibm/endoftenancy/${
          data.tenancyId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteEndoftenancySuccess", {
            tenancyId: data.tenancyId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadTenanciesSuccess(state, data) {
    state.tenancies = data.tenancies;
  },

  loadEndoftenancy(state, data) {
    state.endoftenancy = data.endoftenancy;
  },

  saveEndoftenancyuccess(state, data) {
    if (data.isEditing) {
      const index = state.tenancies.findIndex(
        (p) => p.id === data.endoftenancy.id
      );
      state.tenancies.splice(index, 1, data.endoftenancy);
    } else {
      state.tenancies.push(data.endoftenancy);
    }
  },

  deleteEndoftenancySuccess(state, data) {
    const index = state.tenancies.findIndex((c) => c.id === data.tenancyId);
    state.tenancies.splice(index, 1);
  },

  saveEndoftenancyVisibleSuccess(state, data) {
    const index = state.tenancies.findIndex(
      (p) => p.id === data.endoftenancy.id
    );
    state.tenancies.splice(index, 1, data.endoftenancy);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
