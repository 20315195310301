import * as Utils from "@/utils/index.js";

import store from "@/stores/";

export default [
  {
    path: "users",
    component: () => import("@/modules/users/views/Template"),
    meta: { hasPermission: "view-users" },
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("users/loadModule", {
          appId: to.params.id,
          module: "users",
        }),
        store.dispatch("users/loadRoles", {
          appId: to.params.id,
        }),
        store.dispatch("users/loadPermissions", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "app-users",
        component: () => import("@/modules/users/views/Dashboard"),
      },
      {
        path: "roles-permissions",
        name: "app-users-roles-permissions",
        component: () => import("@/modules/users/views/RolesPermissions"),
      },
      {
        path: ":userId",
        component: () => import("@/modules/users/views/Profile"),
        props: (route) => ({
          user: Utils.getIndividualUser(parseInt(route.params.userId)),
        }),
        children: [
          {
            path: "",
            name: "app-users-profile",
            component: () =>
              import("@/modules/users/views/components/profile/BasicDetails"),
          },
          {
            path: "contact-information",
            name: "app-users-profile-contact",
            component: () =>
              import(
                "@/modules/users/views/components/profile/ContactInformation"
              ),
          },
          {
            path: "user-access",
            name: "app-users-profile-user-access",
            component: () =>
              import("@/modules/users/views/components/profile/UserAccess"),
          },
        ],
      },
    ],
  },
];
