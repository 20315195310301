import { render, staticRenderFns } from "./VLockedTextField.vue?vue&type=template&id=7f0ee002&v-if=hasPermission('view-sensitive-data'%2C%20%24route.params.id)"
import script from "./VLockedTextField.vue?vue&type=script&lang=js"
export * from "./VLockedTextField.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports