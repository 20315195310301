import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  venues: [],
  seasons: [],
  venue: {},
  user: {},
  bookings: [],
  bookingsAll: [],
  bookingsArchived: [],
  archivedBookingsVenue: [],
  prices: [],
  extras: [],
};

// getters
const getters = {
  all: (state) => state.venues,
  get: (state) => state.venue,
  seasons: (state) => state.seasons,
  user: (state) => state.user,
  bookings: (state) => state.bookings,
  bookingsAll: (state) => state.bookingsAll,
  bookingsArchived: (state) => state.bookingsArchived,
  archivedBookingsVenue: (state) => state.archivedBookingsVenue,
  prices: (state) => state.prices,
  extras: (state) => state.extras,
};

// actions
const actions = {
  loadVenues({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/venues`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadVenuesSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadSeasons({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/venues/seasons`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadSeasonsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadBookings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/venues/${
          data.venueId
        }/bookings`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadBookingsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadAllBookings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/venues/bookings`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadAllBookingsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  downloadBookingPdf(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/venues/bookings/${
          data.bookingId
        }/pdf`,
        method: "POST",
        responseType: "arraybuffer",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveBooking({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/craigtoun/venues/bookings/${
          data.bookingId
        }`
      : `${getApiUrl(data.appId)}/module/craigtoun/venues/bookings`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveBookingSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  loadExtras({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/venues/optional-extras`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadExtrasSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveSeason({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/craigtoun/venues/seasons/${
          data.seasonId
        }`
      : `${getApiUrl(data.appId)}/module/craigtoun/venues/seasons`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveSeasonSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  saveVenue({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/craigtoun/venues/${data.venueId}`
      : `${getApiUrl(data.appId)}/module/craigtoun/venues`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveVenueSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  saveExtras({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/craigtoun/venues/${
      data.venueId
    }/extras`;
    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveExtrasSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  saveSeasonPrice({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/craigtoun/venues/${
          data.venueId
        }/price/edit`
      : `${getApiUrl(data.appId)}/module/craigtoun/venues/${
          data.venueId
        }/price`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveSeasonPriceSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteSeason({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/venues/seasons/${
          data.seasonId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteSeasonSuccess", {
            seasonId: data.seasonId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadVenue({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/venues/${data.venueId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadVenueSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteVenue({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/venues/${data.venueId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteVenueSuccess", {
            venueId: data.venueId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteBooking({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/venues/bookings/${
          data.bookingId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteBookingSuccess", {
            bookingId: data.bookingId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  restoreBooking({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/venues/bookings/${
          data.bookingId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreBookingSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadVenuesSuccess(state, data) {
    state.venues = data.venues;
  },
  loadSeasonsSuccess(state, data) {
    state.seasons = data.seasons;
    state.user = data.user;
  },
  loadVenueSuccess(state, data) {
    state.venue = data.venue;
    state.prices = data.season_prices;
  },
  saveSeasonPriceSuccess(state, data) {
    const indexSeason = state.prices.findIndex(
      (p) => p.id === parseInt(data.price.season_id)
    );
    if (data.isEditing) {
      state.prices[indexSeason].prices.splice(0, 1, data.price);
    } else {
      state.prices[indexSeason].prices.push(data.price);
    }
  },
  loadBookingsSuccess(state, data) {
    state.bookings = data.bookings;
    state.archivedBookingsVenue = data.archived;
  },
  loadAllBookingsSuccess(state, data) {
    state.bookingsAll = data.bookings;
    state.bookingsArchived = data.archived;
  },
  saveBookingSuccess(state, data) {
    if (data.isEditing) {
      const index = state.bookings.findIndex((p) => p.id === data.booking.id);
      state.bookings.splice(index, 1, data.booking);

      const indexCalendar = state.bookingsAll.findIndex(
        (p) => p.id === data.booking.id
      );

      state.bookingsAll.splice(indexCalendar, 1, data.booking);
    } else {
      state.bookings.push(data.booking);
    }
  },
  loadExtrasSuccess(state, data) {
    state.extras = data.extras;
  },
  saveVenueSuccess(state, data) {
    if (data.isEditing) {
      const index = state.venues.findIndex((p) => p.id === data.venue.id);
      state.venues.splice(index, 1, data.venue);
    } else {
      state.venues.push(data.venue);
    }
  },
  saveExtrasSuccess(state, data) {
    state.venue = data.venue;
  },
  deleteSeasonSuccess(state, data) {
    const index = state.seasons.findIndex((c) => c.id === data.seasonId);
    state.seasons.splice(index, 1);
  },
  deleteVenueSuccess(state, data) {
    const index = state.venues.findIndex((c) => c.id === data.venueId);
    state.venues.splice(index, 1);
  },
  deleteBookingSuccess(state, data) {
    const index = state.bookings.findIndex((c) => c.id === data.bookingId);
    state.bookings.splice(index, 1);

    const indexAll = state.bookingsAll.findIndex(
      (c) => c.id === data.bookingId
    );
    state.bookingsAll.splice(indexAll, 1);
  },
  restoreBookingSuccess(state, data) {
    const index = state.bookingsArchived.findIndex(
      (c) => c.id === data.booking.id
    );
    state.bookingsArchived.splice(index, 1);
    state.bookingsAll.push(data.booking);

    const indexBookingVenue = state.archivedBookingsVenue.findIndex(
      (c) => c.id === data.booking.id
    );
    state.archivedBookingsVenue.splice(indexBookingVenue, 1);
    state.bookings.push(data.booking);
  },
  saveSeasonSuccess(state, data) {
    if (data.isEditing) {
      const index = state.seasons.findIndex((p) => p.id === data.season.id);
      state.seasons.splice(index, 1, data.season);
      state.season = data.season;
    } else {
      state.seasons.push(data.season);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
