import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  settings: [],
};

// getters
const getters = {
  all: (state) => state.settings,
};

// actions
const actions = {
  loadSettings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/settings`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadSettingsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateSettings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/settings`,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("updateSettingsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadSettingsSuccess(state, data) {
    state.settings = data.settings;
  },
  updateSettingsSuccess(state, data) {
    state.settings = data.settings;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
