import store from "@/stores/";

export default [
  {
    path: "processes",
    component: () => import("@/modules/sbpm/views/Template"),
    children: [
      {
        path: "",
        name: "module-sbpm-processes",
        component: () => import("@/modules/sbpm/views/processes/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("sbpm/processesStore/loadProcesses", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "archived",
        name: "module-sbpm-processes-archived",
        props: () => ({
          pageTitle: "Processes",
          parentRouteName: "module-sbpm-processes",
        }),
        component: () => import("@/modules/sbpm/views/processes/Archived"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("sbpm/processesStore/loadProcesses", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
    ],
  },
];
