import store from "@/stores/";

export default [
  {
    path: "events",
    component: () => import("@/modules/craigtoun/views/Template"),
    children: [
      {
        path: "all",
        name: "module-craigtoun-events-all",
        component: () => import("@/modules/craigtoun/views/events/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("craigtoun/eventsStore/loadEvents", {
              appId: to.params.id,
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
      },
      {
        path: ":eventId",
        name: "module-craigtoun-events-individual",
        component: () => import("@/modules/craigtoun/views/events/Individual"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("craigtoun/eventsStore/loadEvent", {
              appId: to.params.id,
              eventId: to.params.eventId,
            }),
            store.dispatch("craigtoun/customersStore/loadCustomers", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "christmas",
        name: "module-craigtoun-events-christmas-event",
        component: () => import("@/modules/craigtoun/views/events/Christmas"),
      },
      {
        path: "halloween",
        name: "module-craigtoun-events-halloween-event",
        component: () => import("@/modules/craigtoun/views/events/Halloween"),
      },
    ],
  },
];
