import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  costs: [],
};

// getters
const getters = {
  allCosts: state => state.costs,
};

// actions
const actions = {
  loadCosts({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(
          data.appId
        )}/module/ecommerce/delivery-settings/costs`,
        method: "GET",
      })
        .then(resp => {
          commit("loadCostsSuccess", resp.data);
          resolve();
        })
        .catch(err => reject(err));
    });
  },
  saveCost({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/ecommerce/delivery-settings/costs/${
          data.costId
        }`
      : `${getApiUrl(data.appId)}/module/ecommerce/delivery-settings/costs`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then(resp => {
          commit("saveCostSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve();
        })
        .catch(err => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadCostsSuccess(state, data) {
    state.costs = data.costs;
  },
  saveCostSuccess(state, data) {
    if (data.isEditing) {
      const index = state.costs.findIndex(c => c.id === data.cost.id);
      state.costs.splice(index, 1, data.cost);
    } else {
      state.costs.push(data.cost);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
