import endoftenancyStore from "./endoftenancy.store";
import enquiriesStore from "./enquiries.store";

// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

// modules
const modules = {
  endoftenancyStore,
  enquiriesStore,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
