import store from "@/stores/";

export default [
  {
    path: "communal-repairs",
    component: () => import("@/modules/sbpm/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("users/loadModule", {
          appId: to.params.id,
          module: "users",
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-sbpm-communalrepairs",

        component: () =>
          import("@/modules/sbpm/views/communal-repairs/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("sbpm/enquiriesStore/loadEnquiries", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      // {
      //   path: ":enquiryId",
      //   name: "module-sbpm-communalrepairs-individual",
      //   component: () =>
      //     import("@/modules/sbpm/views/communal-repairs/Individual"),
      //   beforeEnter: (to, from, next) => {
      //     store.commit("showHideLoader", true);
      //     Promise.all([
      //       store.dispatch("sbpm/enquiriesStore/loadEnquiry", {
      //         appId: to.params.id,
      //         enquiryId: to.params.enquiryId,
      //       }),
      //     ]).then(() => {
      //       store.commit("showHideLoader", false);
      //       next();
      //     });
      //   },
      // },
    ],
  },
];
