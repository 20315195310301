import store from "@/stores/";

export default [
  {
    path: "courses",
    component: () => import("@/modules/flightmode/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      store
        .dispatch("flightmode/courses/loadCourses", {
          appId: to.params.id,
        })
        .then(() => {
          store.commit("showHideLoader", false);
          next();
        });
    },
    children: [
      {
        path: "",
        name: "module-flightmode-courses",
        component: () => import("@/modules/flightmode/views/courses/Dashboard"),
      },
      {
        path: "archived",
        name: "module-flightmode-courses-archived",
        component: () => import("@/modules/flightmode/views/courses/Archived"),
      },
      {
        path: ":courseId",
        component: () => import("@/modules/flightmode/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("flightmode/courses/loadCourse", {
              appId: to.params.id,
              courseId: parseInt(to.params.courseId),
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
        children: [
          {
            path: "/",
            name: "module-flightmode-courses-view",
            component: () =>
              import("@/modules/flightmode/views/courses/CourseForm"),
          },
        ],
      },
    ],
  },
];
