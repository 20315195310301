import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  pageviews: [],
  visits: [],
  bounce_rate: [],
  avg_duration: [],
  archivedArticles: [],
  article: {},
  fathomCategories: [],
  archivedFathomCategories: [],
};

// getters
const getters = {};

// actions
const actions = {
  loadFathomModule({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/fathom`,
        method: "GET",
      })
        .then((resp) => {
          commit("moduleLoadFathomSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  loadArticle({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/fathom/${data.articleId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadArticleSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  saveArticle({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/fathom/${data.articleId}`
      : `${getApiUrl(data.appId)}/module/fathom`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveArticleSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteArticle({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/fathom/${data.articleId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteArticleSuccess", { articleId: data.articleId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  loadFathomCategories({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/fathom/categories`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadFathomCategoriesSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  saveFathomCategory({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/fathom/categories/${data.categoryId}`
      : `${getApiUrl(data.appId)}/module/fathom/categories`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveFathomCategorySuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteFathomCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/fathom/categories/${
          data.categoryId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteFathomCategorySuccess", {
            categoryId: data.categoryId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
};

// mutations
const mutations = {
  moduleLoadFathomSuccess(state, data) {
    state.pageviews = data.pageviews;
    state.visits = data.visits;
    state.bounce_rate = data.bounce_rate;
    state.avg_duration = data.avg_duration;
  },
  loadArticleSuccess(state, data) {
    state.article = data.article;
  },
  saveArticleSuccess(state, data) {
    if (data.isEditing) {
      const index = state.articles.findIndex((a) => a.id === data.article.id);
      state.articles.splice(index, 1, data.article);
    } else {
      state.articles.push(data.article);
    }
  },
  deleteArticleSuccess(state, data) {
    const index = state.articles.findIndex((a) => a.id === data.articleId);
    state.archivedArticles.push(state.articles[index]);
    state.articles.splice(index, 1);
  },
  loadFathomCategoriesSuccess(state, data) {
    state.fathomCategories = data.categories;
    state.archivedFathomCategories = data.archived;
  },
  saveFathomCategorySuccess(state, data) {
    if (data.isEditing) {
      const index = state.fathomCategories.findIndex(
        (a) => a.id === data.category.id
      );
      state.fathomCategories.splice(index, 1, data.category);
    } else {
      state.fathomCategories.push(data.category);
    }
  },
  deleteFathomCategorySuccess(state, data) {
    const index = state.fathomCategories.findIndex(
      (c) => c.id === data.categoryId
    );
    state.archivedFathomCategories.push(state.fathomCategories[index]);
    state.fathomCategories.splice(index, 1);
  },
};

// modules
const modules = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
