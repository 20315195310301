import store from "@/stores/";

export default [
  {
    path: "customers",
    component: () => import("@/modules/arnprior/views/Template"),
    children: [
      {
        path: "",
        name: "module-arnprior-customers",
        component: () => import("@/modules/arnprior/views/customers/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("arnprior/customersStore/loadCustomers", {
              appId: to.params.id,
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
      },
      {
        path: "archived",
        name: "module-arnprior-customers-archived",
        props: () => ({
          pageTitle: "Customers",
          parentRouteName: "module-arnprior-customers",
        }),
        component: () => import("@/modules/arnprior/views/customers/Archived"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("arnprior/customersStore/loadCustomers", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: ":customerId",
        name: "module-arnprior-customers-individual",
        component: () =>
          import("@/modules/arnprior/views/customers/Individual"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("arnprior/customersStore/loadCustomer", {
              appId: to.params.id,
              customerId: to.params.customerId,
            }),
            store.dispatch("arnprior/ordersStore/loadSwimBadges", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
    ],
  },
];
