import store from "@/stores/";

export default [
  {
    path: "page-content",
    component: () => import("@/modules/page-content/views/Template"),
    meta: { hasPermission: "view-module-pagecontent" },
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("pageContent/loadPages", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-pagecontent",
        component: () => import("@/modules/page-content/views/Dashboard"),
      },
      {
        path: ":pageId",
        name: "module-pagecontent-individual",
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("pageContent/loadPage", {
              appId: to.params.id,
              pageId: parseInt(to.params.pageId),
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        component: () => import("@/modules/page-content/views/Individual"),
      },
    ],
  },
];
