import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  litters: [],
  colors: [],
  coat_types: [],
  sizes: [],
  medications: [],
  litter: {},
};

// getters
const getters = {
  all: (state) => state.litters,
  colors: (state) => state.colors,
  get: (state) => state.litter,
  medications: (state) => state.medications,
};

// actions
const actions = {
  loadLittersStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/litters/status/${
          data.status
        }`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadLittersSuccess", { ...resp.data, status: data.status });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  changeStatus({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/litters/${
      data.litterId
    }/status-change`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("changeStatusSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadLitters({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/litters`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadLittersSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  loadLitter({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/litters/${data.litterId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadLittersuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  deleteNote({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/litters/${
          data.litterId
        }/note/${data.noteId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteNoteSuccess", { noteId: data.noteId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  saveTaskStatus({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/tasks/${
      data.taskId
    }/complete`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveTaskStatusSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteRecord({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/litters/${
          data.litterId
        }/record/${data.recordId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteRecordSuccess", { recordId: data.recordId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  deleteImage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/litters/${
          data.litterId
        }/image/${data.imageId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteImageSuccess", {
            imageId: data.imageId,
            type: data.type,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  saveRecord({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/litters/${data.litterId}/record/${
          data.recordId
        }`
      : `${getApiUrl(data.appId)}/module/lhl/litters/${data.litterId}/record`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveRecordSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveImage({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/litters/${data.litterId}/image/${
          data.imageId
        }`
      : `${getApiUrl(data.appId)}/module/lhl/litters/${data.litterId}/image`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveImageSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  savePuppy({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/litters/${data.litterId}/puppy/${
          data.puppyId
        }`
      : `${getApiUrl(data.appId)}/module/lhl/litters/${data.litterId}/puppy`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("savePuppysuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveLitter({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/litters/${data.litterId}/basic`
      : `${getApiUrl(data.appId)}/module/lhl/litters`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (
        fields[field] === null ||
        typeof fields[field] === "undefined"
      ) {
        formData.append(field, "");
      } else if (typeof fields[field] === "object") {
        if (fields[field] instanceof File) {
          formData.append(field, fields[field]);
        }
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(field + "[]", data[i]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveLittersuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  savePupdate({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/litters/${
      data.litterId
    }/pupdate`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("savePupdateSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveTesting({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/litters/${
      data.litterId
    }/testing`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveTestingSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveBasic({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/litters/${
      data.litterId
    }/basic`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (
        fields[field] === null ||
        typeof fields[field] === "undefined"
      ) {
        formData.append(field, "");
      } else if (typeof fields[field] === "object") {
        if (fields[field] instanceof File) {
          formData.append(field, fields[field]);
        }
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(field + "[]", data[i]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveBasicSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveNote({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/litters/${data.litterId}/note/${
          data.noteId
        }`
      : `${getApiUrl(data.appId)}/module/lhl/litters/${data.litterId}/note`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveNoteSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  deleteLitter({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/litters/${data.litterId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteLitterSuccess", {
            litterId: data.litterId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  downloadPdfPregnacy(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/litters/${
          data.litterId
        }/pdf-pregnacy`,
        method: "POST",
        responseType: "arraybuffer",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveWeight({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/litters/${data.litterId}/weight/${
          data.weightId
        }`
      : `${getApiUrl(data.appId)}/module/lhl/litters/${data.litterId}/weight`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveWeightSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadLittersSuccess(state, data) {
    state.litters = data.litters;
  },

  savePupdateSuccess(state, data) {
    state.litter.pupdates.push(data.pupdate);
  },

  loadLittersuccess(state, data) {
    state.litter = data.litter;
  },

  saveLittersuccess(state, data) {
    state.litters.push(data.litter);

    state.litter = data.litter;
  },

  saveTestingSuccess(state, data) {
    state.litter.testing = data.testing;
  },

  saveBasicSuccess(state, data) {
    state.litter = data.litter;
  },

  savePuppysuccess(state, data) {
    state.litter.puppies.push(data.puppy);
    state.litter = data.litter;
  },

  saveImageSuccess(state, data) {
    if (data.image.type == "image1") {
      state.litter.image1 = data.image;
    }

    if (data.image.type == "image2") {
      state.litter.image2 = data.image;
    }

    if (data.image.type == "image3") {
      state.litter.image3 = data.image;
    }

    if (data.image.type == "image4") {
      state.litter.image4 = data.image;
    }

    if (data.isEditing) {
      if (data.image.type == "intro") {
        const index = state.litter.intro_images.findIndex(
          (c) => c.id === data.image.id
        );
        state.litter.intro_images.splice(index, 1, data.image);
      }

      if (data.image.type == "six") {
        const index = state.litter.six_weeks_images.findIndex(
          (c) => c.id === data.image.id
        );
        state.litter.six_weeks_images.splice(index, 1, data.imageg);
      }

      if (data.image.type == "three") {
        const index = state.litter.three_weeks_images.findIndex(
          (c) => c.id === data.image.id
        );
        state.litter.three_weeks_images.splice(index, 1, data.image);
      }
    } else {
      if (data.image.type == "intro") {
        state.litter.intro_images.push(data.image);
      }

      if (data.image.type == "six") {
        state.litter.six_weeks_images.push(data.image);
      }

      if (data.image.type == "three") {
        state.litter.three_weeks_images.push(data.image);
      }
    }
  },

  saveWormingSuccess(state, data) {
    if (data.isEditing) {
      const index = state.litter.wormings.findIndex(
        (c) => c.id === data.worming.id
      );
      state.litter.wormings.splice(index, 1, data.worming);
    } else {
      state.litter.wormings.push(data.worming);
    }
  },

  deleteNoteSuccess(state, data) {
    const index = state.litter.health_notes.findIndex(
      (p) => p.id === data.noteId
    );
    state.litter.health_notes.splice(index, 1);
  },

  deleteRecordSuccess(state, data) {
    const index = state.litter.matings.findIndex((p) => p.id === data.recordId);
    state.litter.matings.splice(index, 1);
  },

  deleteImageSuccess(state, data) {
    if (data.type == "image1") {
      state.litter.image1 = null;
    }

    if (data.type == "image2") {
      state.litter.image2 = null;
    }

    if (data.type == "image3") {
      state.litter.image3 = null;
    }

    if (data.type == "image4") {
      state.litter.image4 = null;
    }

    if (data.type == "intro") {
      const index = state.litter.intro_images.findIndex(
        (p) => p.id === data.imageId
      );
      state.litter.intro_images.splice(index, 1);
    }

    if (data.type == "six") {
      const index = state.litter.six_weeks_images.findIndex(
        (p) => p.id === data.imageId
      );
      state.litter.six_weeks_images.splice(index, 1);
    }

    if (data.type == "three") {
      const index = state.litter.three_weeks_images.findIndex(
        (p) => p.id === data.imageId
      );
      state.litter.three_weeks_images.splice(index, 1);
    }
  },

  saveTaskStatusSuccess(state, data) {
    const index = state.litter.tasks.findIndex((c) => c.id === data.task.id);
    state.litter.tasks.splice(index, 1, data.task);
  },

  deleteLitterSuccess(state, data) {
    const index = state.litters.findIndex((p) => p.id === data.litterId);
    state.litters.splice(index, 1);
  },

  saveNoteSuccess(state, data) {
    if (data.isEditing) {
      const index = state.litter.health_notes.findIndex(
        (c) => c.id === data.note.id
      );
      state.litter.health_notes.splice(index, 1, data.note);
    } else {
      state.litter.health_notes.push(data.note);
    }
  },

  changeStatusSuccess(state, data) {
    state.litter = data.litter;
  },

  saveRecordSuccess(state, data) {
    if (data.isEditing) {
      const index = state.litter.matings.findIndex(
        (c) => c.id === data.record.id
      );
      state.litter.matings.splice(index, 1, data.record);
    } else {
      state.litter.status = data.litter.status;
      state.litter.matings.push(data.record);
    }
  },

  saveWeightSuccess(state, data) {
    if (data.isEditing) {
      const index = state.litter.weights.findIndex(
        (c) => c.id === data.weight.id
      );
      state.litter.weights.splice(index, 1, data.weight);
    } else {
      state.litter.weights.push(data.weight);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
