import axios from "axios";
import { getApiUrl } from "@/utils";

const state = {
  salesProperties: [],
  archived: [],
  property: {},
};

// getters
const getters = {
  all: (state) => state.salesProperties,
  get: (state) => state.property,
  salesProperties: (state) => state.salesProperties,
  archived: (state) => state.archived,
};

// actions
const actions = {
  loadProperties({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/easylets/sales-properties`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadPropertiesSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadProperty({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/easylets/sales-properties/${
          data.propertyId
        }`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadPropertySuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveProperty({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/easylets/sales-properties/${
          data.propertyId
        }`
      : `${getApiUrl(data.appId)}/module/easylets/sales-properties`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("savePropertySuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteProperty({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/easylets/sales-properties/${
          data.propertyId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deletePropertySuccess", {
            propertyId: data.propertyId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  savePropertyVisible({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/easylets/sales-properties/${
      data.propertyId
    }/visible`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("savePropertyVisibleSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  restoreProperty({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/easylets/sales-properties/${
          data.propertyId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restorePropertySuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadPropertiesSuccess(state, data) {
    state.salesProperties = data.salesProperties;
    state.archived = data.archived;
  },
  deletePropertySuccess(state, data) {
    const index = state.salesProperties.findIndex((c) => c.id === data.id);
    state.salesProperties.splice(index, 1);
  },
  savePropertyVisibleSuccess(state, data) {
    const index = state.salesProperties.findIndex(
      (p) => p.id === data.property.id
    );
    state.salesProperties.splice(index, 1, data.property);
  },
  savePropertySuccess(state, data) {
    if (data.isEditing) {
      const index = state.salesProperties.findIndex(
        (p) => p.id === data.property.id
      );
      state.salesProperties.splice(index, 1, data.property);
      state.property = data.property;
    } else {
      state.salesProperties.push(data.property);
    }
  },
  loadPropertySuccess(state, data) {
    state.property = data.property;
  },
  restorePropertySuccess(state, data) {
    const index = state.archived.findIndex((c) => c.id === data.property.id);
    state.archived.splice(index, 1);
    state.salesProperties.push(data.property);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
