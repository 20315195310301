// import axios from "axios";
// import { getApiUrl } from "@/utils";

// initial state
const state = {
  customers: [
    {
      id: 1,
      first_name: "Joe",
      last_name: "Bloggs",
      full_name: "Joe Bloggs",
      email: "joe@example.com",
      has_account: true,
      marketing_opt_in: true,
      phone: "01234567890",
      addresses: [
        {
          id: 1,
          first_name: "Joe",
          last_name: "Bloggs",
          full_name: "Joe Bloggs",
          line_1: "Vital Hike",
          line_2: "18 High Street",
          line_3: null,
          postcode: "DD6 8AD",
          city: "Newport-on-Tay",
          county: "Fife",
          country: "United Kingdom",
          phone: "01234567890",
          customer_id: 1,
        },
      ],
      billing_address_id: 1,
      shipping_address_id: 1,
    },
  ],
  customer: {
    id: 1,
    first_name: "Joe",
    last_name: "Bloggs",
    full_name: "Joe Bloggs",
    email: "joe@example.com",
    has_account: true,
    marketing_opt_in: true,
    phone: "01234567890",
    addresses: [
      {
        id: 1,
        first_name: "Joe",
        last_name: "Bloggs",
        full_name: "Joe Bloggs",
        line_1: "Vital Hike",
        line_2: "18 High Street",
        line_3: null,
        postcode: "DD6 8AD",
        city: "Newport-on-Tay",
        county: "Fife",
        country: "United Kingdom",
        phone: "01234567890",
        customer_id: 1,
      },
    ],
    billing_address_id: 1,
    shipping_address_id: 1,
  },
};

// getters
const getters = {};

// actions
const actions = {
  loadCustomers() {
    // return new Promise((resolve, reject) => {
    //   axios({
    //     url: `${getApiUrl(data.appId)}/module/grogreen/customers`,
    //     method: "GET",
    //   })
    //     .then((resp) => {
    //       commit("loadCustomersSuccess", { ...resp.data });
    //       resolve();
    //     })
    //     .catch((err) => reject(err));
    // });
    return Promise.resolve();
  },
  loadCustomer() {
    // return new Promise((resolve, reject) => {
    //   axios({
    //     url: `${getApiUrl(data.appId)}/module/grogreen/customers/${
    //       data.customerId
    //     }`,
    //     method: "GET",
    //   })
    //     .then((resp) => {
    //       commit("loadCustomerSuccess", { ...resp.data });
    //       resolve();
    //     })
    //     .catch((err) => reject(err));
    // });
    return Promise.resolve();
  },

  saveCustomer() {
    // const url = `${getApiUrl(data.appId)}/module/grogreen/customers/${
    //   data.customerId
    // }`;

    // return new Promise((resolve, reject) => {
    //   axios({
    //     url,
    //     data: data.fields,
    //     method: "POST",
    //   })
    //     .then((resp) => {
    //       commit("saveCustomerSuccess", {
    //         ...resp.data,
    //       });
    //       resolve(resp);
    //     })
    //     .catch((err) => reject(err));
    // });
    return Promise.resolve();
  },

  saveAddress() {
    // const url = data.isEditing
    //   ? `${getApiUrl(data.appId)}/module/grogreen/customers/${
    //       data.customerId
    //     }/addresses/${data.addressId}`
    //   : `${getApiUrl(data.appId)}/module/grogreen/customers/${
    //       data.customerId
    //     }/addresses`;

    // return new Promise((resolve, reject) => {
    //   axios({
    //     url: url,
    //     data: {
    //       ...data,
    //     },
    //     method: "POST",
    //   })
    //     .then((resp) => {
    //       commit("saveAddressSuccess", {
    //         ...resp.data,
    //         isEditing: data.isEditing,
    //         customerId: data.customerId,
    //       });
    //       resolve();
    //     })
    //     .catch((err) => reject(err));
    // });
    return Promise.resolve();
  },
  deleteAddress() {
    // return new Promise((resolve, reject) => {
    //   axios({
    //     url: `${getApiUrl(data.appId)}/module/grogreen/customers/${
    //       data.customerId
    //     }/addresses/${data.addressId}`,
    //     method: "DELETE",
    //   })
    //     .then(() => {
    //       commit("deleteAddressSuccess", {
    //         id: data.addressId,
    //       });
    //       resolve();
    //     })
    //     .catch((err) => {
    //       reject(err);
    //     });
    // });
    return Promise.resolve();
  },
  setAddress() {
    // return new Promise((resolve, reject) => {
    //   axios({
    //     url: `${getApiUrl(data.appId)}/module/grogreen/customers/${
    //       data.customerId
    //     }/set-${data.type}`,
    //     data: {
    //       address_id: data.addressId,
    //       type: data.type,
    //     },
    //     method: "POST",
    //   })
    //     .then(() => {
    //       commit("setAddressSuccess", {
    //         ...data,
    //       });
    //       resolve();
    //     })
    //     .catch((err) => {
    //       reject(err);
    //     });
    // });
    return Promise.resolve();
  },
};

// mutations
const mutations = {
  loadCustomersSuccess(state, data) {
    state.customers = data.customers;
  },
  loadCustomerSuccess(state, data) {
    state.customer = data.customer;
  },
  saveCustomerSuccess(state, data) {
    const index = state.customers.findIndex((c) => c.id === data.customer.id);
    state.customers.splice(index, 1, data.customer);
    state.customer = data.customer;
  },
  saveAddressSuccess(state, data) {
    if (data.isEditing) {
      const index = state.customer.addresses.findIndex(
        (a) => a.id === data.address.id
      );
      state.customer.addresses[index] = data.address;
    } else {
      state.customer.addresses.push(data.address);
    }

    if (data.customer.shipping_address_id === data.address.id) {
      state.customer.shipping_address_id = data.address.id;
    }

    if (data.customer.billing_address_id === data.address.id) {
      state.customer.billing_address_id = data.address.id;
    }
  },
  deleteAddressSuccess(state, data) {
    state.customer.addresses = state.customer.addresses.filter((a) => {
      return a.id !== data.id;
    });
  },
  setAddressSuccess(state, data) {
    if (data.type === "shipping-address") {
      state.customer.shipping_address_id = data.addressId;
    } else {
      state.customer.billing_address_id = data.addressId;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
