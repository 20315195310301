import store from "@/stores/";

export default [
  {
    path: "glamping",
    component: () => import("@/modules/arnprior/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      store
        .dispatch("arnprior/ordersStore/loadOrders", {
          appId: to.params.id,
          type: "pods",
        })
        .then(() => {
          store.commit("showHideLoader", false);
          next();
        });
    },
    children: [
      {
        path: "calendar",
        name: "module-arnprior-pods-arnprior-pod-calendar",
        component: () =>
          import("@/modules/arnprior/views/glamping/calendar/Calendar"),
      },
      {
        path: "bookings",
        component: () => import("@/modules/arnprior/views/Template"),
        children: [
          {
            path: "",
            name: "module-arnprior-pods-arnprior-pod-bookings",
            component: () =>
              import("@/modules/arnprior/views/glamping/bookings/Bookings"),
          },
          {
            path: ":bookingId",
            name: "module-arnprior-pods-arnprior-pod-bookings-individual",
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("arnprior/bookingsStore/loadBooking", {
                  appId: to.params.id,
                  bookingId: to.params.bookingId,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
            component: () =>
              import("@/modules/arnprior/views/glamping/bookings/Individual"),
          },
        ],
      },
      {
        path: "settings",
        name: "module-arnprior-pods-arnprior-pod-settings",
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("arnprior/settingsStore/loadMinimumStay", {
              appId: to.params.id,
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
        component: () =>
          import("@/modules/arnprior/views/glamping/settings/Dashboard"),
      },
      {
        path: "extras",
        name: "module-arnprior-pods-arnprior-pod-extras",
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("arnprior/bookingsStore/loadExtras", {
              appId: to.params.id,
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
        component: () =>
          import("@/modules/arnprior/views/glamping/extras/Dashboard"),
      },
      {
        path: "pods",
        name: "module-arnprior-pods-arnprior-all-pods",
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("arnprior/podsStore/loadPods", {
              appId: to.params.id,
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
        component: () =>
          import("@/modules/arnprior/views/glamping/pods/Dashboard"),
      },
    ],
  },
];
