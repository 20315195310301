import axios from "axios";

// initial state
const state = {
  invoices: {},
  targets: []
};

// getters
const getters = {};

// actions
const actions = {
  loadModule({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("moduleLoadRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/pipeline`,
        method: "GET"
      })
        .then(resp => {
          const module = resp.data;
          commit("moduleLoadSuccess", { module });
          resolve(resp);
        })
        .catch(err => {
          commit("moduleLoadError");
          reject(err);
        });
    });
  },
  xeroCallback({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("xeroCallbackRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/pipeline/authorize`,
        data: {
          code: data.code
        },
        method: "POST"
      })
        .then(resp => {
          commit("xeroCallbackSuccess");
          resolve(resp.data);
        })
        .catch(err => {
          commit("xeroCallbackError");
          reject(err);
        });
    });
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("updatePipelineRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/pipeline/update`,
        data: {
          date: data.date,
          months: data.months
        },
        method: "POST"
      })
        .then(resp => {
          const data = resp.data;
          commit("updatePipelineSuccess", data);
          resolve();
        })
        .catch(err => {
          commit("updatePipelineError");
          reject(err);
        });
    });
  },
  addTarget({ commit }, data) {
    commit("addTargetRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/pipeline/targets`,
        data: {
          ...data
        },
        method: "POST"
      })
        .then(resp => {
          const target = resp.data.target;
          commit("addTargetSuccess", { target });
          resolve();
        })
        .catch(err => {
          commit("addTargetError");
          reject(err);
        });
    });
  },
  removeTarget({ commit }, data) {
    commit("removeTargetRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/pipeline/targets/${data.id}/remove`,
        method: "POST"
      })
        .then(() => {
          commit("removeTargetSuccess", { id: data.id });
          resolve();
        })
        .catch(err => {
          commit("removeTargetError");
          reject(err);
        });
    });
  },
  updateTarget({ commit }, data) {
    commit("updateTargetRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/pipeline/targets/${data.id}/update`,
        data: {
          date: data.date,
          target: data.target
        },
        method: "POST"
      })
        .then(resp => {
          const data = resp.data;
          commit("updateTargetSuccess", data);
          resolve();
        })
        .catch(err => {
          commit("updateTargetError");
          reject(err);
        });
    });
  }
};

// mutations
const mutations = {
  moduleLoadRequest() {},
  moduleLoadSuccess(state, data) {
    state.invoices = data.module.invoices;
    state.targets = data.module.targets;
  },
  moduleLoadError() {},
  xeroCallbackRequest() {},
  xeroCallbackSuccess() {},
  xeroCallbackError() {},
  updatePipelineRequest() {},
  updatePipelineSuccess(state, data) {
    state.invoices = data.invoices;
  },
  updatePipelineError() {},
  addTargetRequest() {},
  addTargetSuccess(state, data) {
    state.targets.push(data.target);

    let targets = state.targets.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });
    state.targets = targets;
  },
  addTargetError() {},
  removeTargetRequest() {},
  removeTargetSuccess(state, data) {
    state.targets = state.targets.filter(function(t) {
      return t.id !== data.id;
    });
  },
  removeTargetError() {},
  updateTargetRequest() {},
  updateTargetSuccess(state, data) {
    const index = state.targets.findIndex(t => t.id === data.target.id);
    state.targets[index].date = data.target.date;
    state.targets[index].target = data.target.target;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
