import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  species: [],
  categories: [],
};

// getters
const getters = {};

// actions
const actions = {
  loadSpecies({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/watson/species`,
        method: "GET",
      })
        .then(resp => {
          commit("loadSpeciesSuccess", { ...resp.data });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  saveSpecies({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/watson/species/${data.speciesId}`
      : `${getApiUrl(data.appId)}/module/watson/species`;

    let fields = data.fields;

    if (fields.seed_illustration_has_changed === false) {
      fields.seed_illustration = null;
    }

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then(resp => {
          commit("saveSpeciesSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch(err => reject(err));
    });
  },
  deleteSpecies({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/watson/species/${data.speciesId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteSpeciesSuccess", { speciesId: data.speciesId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  loadCategories({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/watson/species/categories`,
        method: "GET",
      })
        .then(resp => {
          commit("loadCategoriesSuccess", { ...resp.data });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  saveCategory({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/watson/species/categories/${
          data.categoryId
        }`
      : `${getApiUrl(data.appId)}/module/watson/species/categories`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then(resp => {
          commit("saveCategorySuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch(err => reject(err));
    });
  },
  deleteCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/watson/species/categories/${
          data.categoryId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteCategorySuccess", { categoryId: data.categoryId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
};

// mutations
const mutations = {
  loadSpeciesSuccess(state, data) {
    state.species = data.species;
  },
  saveSpeciesSuccess(state, data) {
    if (data.isEditing) {
      const index = state.species.findIndex(s => s.id === data.species.id);
      state.species.splice(index, 1, data.species);
    } else {
      state.species.push(data.species);
    }
  },
  deleteSpeciesSuccess(state, data) {
    const index = state.species.findIndex(s => s.id === data.speciesId);
    state.species.splice(index, 1);
  },

  loadCategoriesSuccess(state, data) {
    state.categories = data.categories;
  },
  saveCategorySuccess(state, data) {
    if (data.isEditing) {
      const index = state.categories.findIndex(c => c.id === data.category.id);
      state.categories.splice(index, 1, data.category);
    } else {
      state.categories.push(data.category);
    }
  },
  deleteCategorySuccess(state, data) {
    const index = state.categories.findIndex(c => c.id === data.categoryId);
    state.categories.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
