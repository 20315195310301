import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  vouchers: [],
  voucher: {},
  discountCodes: [],
};

// getters
const getters = {
  vouchers: (state) => state.vouchers,
  discountCodes: (state) => state.discountCodes,
};

// actions
const actions = {
  loadVouchers({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/gift-vouchers`,
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          commit("loadVouchersSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveGiftVoucher({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/arnprior/gift-vouchers/${
          data.giftVoucherId
        }`
      : `${getApiUrl(data.appId)}/module/arnprior/gift-vouchers`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          commit("saveVouchersSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteGiftVoucher({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/gift-vouchers/${
          data.giftVoucherId
        }`,
        method: "DELETE",
      })
        .then((resp) => {
          commit("deleteGiftVoucherSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadDiscountCodes({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/discount-codes`,
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          commit("loadDiscountCodesSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveDiscountCode({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/arnprior/discount-codes/${
          data.discountCodeId
        }`
      : `${getApiUrl(data.appId)}/module/arnprior/discount-codes`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          commit("saveDiscountCodesSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteDiscountCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/discount-codes/${
          data.discountCodeId
        }`,
        method: "DELETE",
      })
        .then((resp) => {
          commit("deleteDiscountCodeSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadVouchersSuccess(state, data) {
    state.vouchers = data.giftVouchers;
  },
  saveVouchersSuccess(state, data) {
    if (data.isEditing) {
      const index = state.vouchers.findIndex(
        (v) => v.id === data.giftVoucher.id
      );
      state.vouchers.splice(index, 1, data.giftVoucher);
    } else {
      state.vouchers.push(data.giftVoucher);
    }
  },
  deleteGiftVoucherSuccess(state, data) {
    const index = state.vouchers.findIndex((v) => v.id === data.giftVoucher.id);
    state.vouchers.splice(index, 1);
  },
  loadDiscountCodesSuccess(state, data) {
    state.discountCodes = data.discountCodes;
  },
  saveDiscountCodesSuccess(state, data) {
    if (data.isEditing) {
      const index = state.discountCodes.findIndex(
        (c) => c.id === data.discountCode.id
      );
      state.discountCodes.splice(index, 1, data.discountCode);
    } else {
      state.discountCodes.push(data.discountCode);
    }
  },
  deleteDiscountCodeSuccess(state, data) {
    const index = state.discountCodes.findIndex(
      (c) => c.id === data.discountCode.id
    );
    state.discountCodes.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
