import store from "@/stores/";

export default [
  {
    path: "payment-schedule",
    component: () => import("@/modules/drum/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      store
        .dispatch("drum/paymentSchedule/loadPaymentSchedule", {
          appId: to.params.id,
        })
        .then(() => {
          store.commit("showHideLoader", false);
          next();
        });
    },
    children: [
      {
        path: "",
        name: "module-drum-payment-schedule",
        component: () =>
          import("@/modules/drum/views/payment-schedule/Dashboard"),
      },
    ],
  },
];
