import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  tickets: [],
  types: [],
  archived: [],
  ticket: {},
};

// getters
const getters = {
  all: (state) => state.tickets,
  get: (state) => state.ticket,
  types: (state) => state.types,
};

// actions
const actions = {
  loadTickets({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/season-tickets`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadTicketsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadTicket({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/season-tickets/${
          data.ticketId
        }`,
        method: "GET",
      })
        .then((resp) => {
          const ticket = resp.data.ticket;
          commit("loadTicketSuccess", { ticket });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadTypes({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/craigtoun/season-tickets/types`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadTypesSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveTicket({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/craigtoun/season-tickets/${
          data.ticketId
        }`
      : `${getApiUrl(data.appId)}/module/craigtoun/season-tickets`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (
        fields[field] === null ||
        typeof fields[field] === "undefined"
      ) {
        formData.append(field, "");
      } else if (field === "holders") {
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(`holders[${i}][full_name]`, data[i]["full_name"]);
          formData.append(
            `holders[${i}][is_guest]`,
            data[i]["is_guest"] ? 1 : 0
          );
          formData.append(
            `holders[${i}][is_additional]`,
            data[i]["is_additional"] ? 1 : 0
          );
          formData.append(
            `holders[${i}][is_principal]`,
            data[i]["is_principal"] ? 1 : 0
          );
        }
      } else if (typeof fields[field] === "object") {
        if (fields[field] instanceof File) {
          formData.append(field, fields[field]);
        }
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(field + "[]", data[i]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveTicketSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  saveHolder({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/craigtoun/season-tickets/${
          data.ticketId
        }/holder/${data.holderId}`
      : `${getApiUrl(data.appId)}/module/craigtoun/season-tickets/${
          data.ticketId
        }/holder`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveHolderSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadTicketsSuccess(state, data) {
    state.tickets = data.tickets;
    state.archived = data.archived;
  },
  loadTicketSuccess(state, data) {
    state.ticket = data.ticket;
  },
  loadTypesSuccess(state, data) {
    state.types = data.types;
  },
  saveTicketSuccess(state, data) {
    if (data.isEditing) {
      const index = state.tickets.findIndex((p) => p.id === data.ticket.id);
      state.tickets.splice(index, 1, data.ticket);
      state.ticket = data.ticket;
    } else {
      state.tickets.push(data.ticket);
    }
  },
  saveHolderSuccess(state, data) {
    if (data.isEditing) {
      const index = state.ticket.holders.findIndex(
        (p) => p.id === data.holder.id
      );
      state.ticket.holders.splice(index, 1, data.holder);
    } else {
      state.ticket.holders.push(data.holder);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
