import axios from "axios";
import { getApiUrl } from "@/utils";
import settingsStore from "./settings.store";

// initial state
const state = {
  customers: [],
  breeders: [],
  rewardsHistory: [],
  codes: [],
  breeds: [],
  products: [],
  subscriptions: [],
  deliveries: [],
  orders: [],
  order: {},
  customer: {},
  subscription: {},
  delivery: {},
  product: {},
  faqs: [],
  faqCategories: [],
  articles: [],
  archivedArticles: [],
  article: {},
  newsCategories: [],
  archivedNewsCategories: [],
};

// getters
const getters = {};

// actions
const actions = {
  loadCustomerModule({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("moduleLoadCustomerRequest");
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/customers`,
        method: "GET",
      })
        .then((resp) => {
          const module = resp.data;
          commit("moduleLoadCustomerSuccess", { module });
          resolve(resp);
        })
        .catch((err) => {
          commit("moduleLoadCustomerError");
          reject(err);
        });
    });
  },
  loadBreederModule({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/breeders`,
        method: "GET",
      })
        .then((resp) => {
          const module = resp.data;
          commit("moduleLoadBreedersSuccess", { module });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadCodesModule({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/breeders/codes`,
        method: "GET",
      })
        .then((resp) => {
          const module = resp.data;
          commit("moduleLoadCodesSuccess", { module });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  resendCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/breeders/codes/${
          data.codeId
        }/resend-code`,
        method: "GET",
      })
        .then(() => {
          commit("resendCodeSuccess", {
            id: data.codeId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  deleteCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/breeders/codes/${
          data.codeId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteCodeSuccess", {
            id: data.codeId,
            breederPage: data.isOnBreederPage,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  archiveBreeder({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/breeders/${data.customerId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("archiveBreederSuccess", {
            id: data.customerId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  loadBreeder({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/breeders/${data.customerId}`,
        method: "GET",
      })
        .then((resp) => {
          const module = resp.data;
          commit("loadBreederSuccess", { module });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  saveBreeder({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/paw/breeders/${data.customerId}`
      : `${getApiUrl(data.appId)}/module/paw/breeders`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveBreederSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  loadProductModule({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("moduleLoadProductRequest");
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/products`,
        method: "GET",
      })
        .then((resp) => {
          const module = resp.data;
          commit("moduleLoadProductSuccess", { module });
          resolve(resp);
        })
        .catch((err) => {
          commit("moduleLoadProductError");
          reject(err);
        });
    });
  },
  saveProduct({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/paw/products/${data.productId}`
      : `${getApiUrl(data.appId)}/module/paw/products`;

    return new Promise((resolve, reject) => {
      commit("saveProductRequest");
      let formData = new FormData();
      formData.append("name", data.name);
      formData.append("permalink", data.permalink);
      formData.append("description", data.description);
      formData.append("type", data.type);
      formData.append("colour", data.colour);
      formData.append(
        "product_background_colour",
        data.product_background_colour
      );
      formData.append("ga_code", data.ga_code);
      formData.append("normal12", data.normal12);
      formData.append("normal24", data.normal24);
      formData.append("subscription12", data.subscription12);
      formData.append("subscription24", data.subscription24);
      formData.append("composition", data.composition);
      formData.append("additives", data.additives);
      formData.append("constituents", data.constituents);
      formData.append("breakdown", data.breakdown);

      if (data.isEditing) {
        formData.append(
          "mainThumbnailChange",
          data.mainThumbnailChange ? 1 : 0
        );
        if (data.mainThumbnailChange)
          formData.append("mainThumbnail", data.mainThumbnail);

        formData.append(
          "secondaryThumbnailChange",
          data.secondaryThumbnailChange ? 1 : 0
        );
        if (data.secondaryThumbnailChange)
          formData.append("secondaryThumbnail", data.secondaryThumbnail);

        formData.append("productLabelChange", data.productLabelChange ? 1 : 0);
        if (data.productLabelChange)
          formData.append("productLabel", data.productLabel);

        formData.append("productBagChange", data.productBagChange ? 1 : 0);
        if (data.productBagChange)
          formData.append("productBag", data.productBag);

        formData.append(
          "percentageImageChange",
          data.percentageImageChange ? 1 : 0
        );
        if (data.percentageImageChange)
          formData.append("percentageImage", data.percentageImage);

        formData.append(
          "breakdownImageChange",
          data.breakdownImageChange ? 1 : 0
        );
        if (data.breakdownImageChange)
          formData.append("breakdownImage", data.breakdownImage);
      } else {
        formData.append("mainThumbnail", data.mainThumbnail);
        formData.append("secondaryThumbnail", data.secondaryThumbnail);
        formData.append("productLabel", data.productLabel);
        formData.append("productBag", data.productBag);
        formData.append("percentageImage", data.percentageImage);
        formData.append("breakdownImage", data.breakdownImage);
      }

      axios
        .post(url, formData)
        .then((resp) => {
          const product = resp.data.product;
          commit("saveProductSuccess", { product, isEditing: data.isEditing });
          resolve();
        })
        .catch((err) => {
          commit("saveProductError");
          reject(err);
        });
    });
  },
  loadProduct({ commit }, data) {
    return new Promise((resolve) => {
      const index = state.products.findIndex((p) => p.id === data.productId);
      commit("loadProductSuccess", { product: state.products[index] });
      resolve();
    });
  },
  deleteProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("deleteProductRequest");
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/products/${data.productId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteProductSuccess", {
            id: data.productId,
          });
          resolve();
        })
        .catch(() => {
          commit("deleteProductError");
          reject();
        });
    });
  },
  updateProductsDisplayOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/products/display-order`,
        method: "POST",
        data: {
          displayOrder: data.displayOrder,
        },
      })
        .then((resp) => {
          commit("updateProductsDisplayOrderSuccess", {
            products: resp.data.products,
          });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },
  loadBreedModule({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("moduleLoadBreedRequest");
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/breeds`,
        method: "GET",
      })
        .then((resp) => {
          const module = resp.data;
          commit("moduleLoadBreedSuccess", { module });
          resolve(resp);
        })
        .catch((err) => {
          commit("moduleLoadBreedError");
          reject(err);
        });
    });
  },
  saveBreed({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/paw/breeds/${data.breedId}`
      : `${getApiUrl(data.appId)}/module/paw/breeds`;

    return new Promise((resolve, reject) => {
      commit("saveBreedRequest");
      axios({
        url,
        data: {
          name: data.name,
          size: data.size,
          min_weight: data.min_weight,
          max_weight: data.max_weight,
        },
        method: "POST",
      })
        .then((resp) => {
          const breed = resp.data.breed;
          commit("saveBreedSuccess", { breed, isEditing: data.isEditing });
          resolve();
        })
        .catch((err) => {
          commit("saveBreedError");
          reject(err);
        });
    });
  },
  deleteBreed({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("deleteBreedRequest");
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/breeds/${data.breedId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteBreedSuccess", {
            id: data.breedId,
          });
          resolve();
        })
        .catch(() => {
          commit("deleteBreedError");
          reject();
        });
    });
  },
  loadSubscriptionModule({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("moduleLoadSubscriptionRequest");
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/subscriptions`,
        method: "GET",
      })
        .then((resp) => {
          const module = resp.data;
          commit("moduleLoadSubscriptionSuccess", { module });
          resolve(resp);
        })
        .catch((err) => {
          commit("moduleLoadSubscriptionError");
          reject(err);
        });
    });
  },
  loadDeliveriesModule({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("moduleLoadDeliveriesRequest");
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/deliveries`,
        method: "GET",
      })
        .then((resp) => {
          const module = resp.data;
          commit("moduleLoadDeliveriesSuccess", { module });
          resolve(resp);
        })
        .catch((err) => {
          commit("moduleLoadDeliveriesError");
          reject(err);
        });
    });
  },
  loadCustomer({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("loadCustomerRequest");
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/customers/${data.customerId}`,
        method: "GET",
      })
        .then((resp) => {
          const customer = resp.data.customer;
          commit("loadCustomerSuccess", { customer });
          resolve();
        })
        .catch(() => {
          commit("loadCustomerError");
          reject();
        });
    });
  },
  loadSubscription({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("loadSubscriptionRequest");
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/subscriptions/${
          data.subscriptionId
        }`,
        method: "GET",
      })
        .then((resp) => {
          const subscription = resp.data.subscription;
          commit("loadSubscriptionSuccess", { subscription });
          resolve();
        })
        .catch(() => {
          commit("loadSubscriptionError");
          reject();
        });
    });
  },
  loadDelivery({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("loadDeliveryRequest");
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/deliveries/${
          data.deliveryId
        }`,
        method: "GET",
      })
        .then((resp) => {
          const delivery = resp.data.delivery;
          commit("loadDeliverySuccess", { delivery });
          resolve();
        })
        .catch(() => {
          commit("loadDeliveryError");
          reject();
        });
    });
  },
  deleteAddress({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("deleteAddressRequest");
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/customers/addresses/${
          data.addressId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteAddressSuccess", {
            id: data.addressId,
            customerId: data.customerId,
          });
          resolve();
        })
        .catch(() => {
          commit("deleteAddressError");
          reject();
        });
    });
  },
  setAddress({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("setAddressRequest");
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/customers/${
          data.customerId
        }/set-${data.type}`,
        data: {
          addressId: data.addressId,
          type: data.type,
        },
        method: "POST",
      })
        .then(() => {
          commit("setAddressSuccess", {
            ...data,
          });
          resolve();
        })
        .catch(() => {
          commit("setAddressError");
          reject();
        });
    });
  },
  saveAddress({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/paw/customers/addresses/${
          data.addressId
        }/edit`
      : `${getApiUrl(data.appId)}/module/paw/customers/${
          data.customerId
        }/create-address`;

    return new Promise((resolve, reject) => {
      commit("saveAddressRequest");
      axios({
        url: url,
        data: {
          ...data,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("saveAddressSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve();
        })
        .catch((err) => {
          commit("saveAddressError");
          reject(err);
        });
    });
  },
  editSubscription({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("editSubscriptionRequest");
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/subscriptions/${
          data.subscriptionId
        }`,
        data: {
          fields: data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("editSubscriptionSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          commit("editSubscriptionError");
          reject(err);
        });
    });
  },
  updateSubscriptionStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("updateSubscriptionStatusRequest");
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/subscriptions/${
          data.subscriptionId
        }/status`,
        data: {
          status: data.status,
          next_delivery_date: data.next_delivery_date,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("updateSubscriptionStatusSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          commit("updateSubscriptionStatusError");
          reject(err);
        });
    });
  },
  editCustomer({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("editCustomerRequest");
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/customers/${
          data.customerId
        }/edit`,
        data: {
          ...data,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("editCustomerSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          commit("editCustomerError");
          reject(err);
        });
    });
  },
  editDeliveryAddress({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("editDeliveryAddressRequest");
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/deliveries/${
          data.deliveryId
        }/address`,
        data: {
          address_id: data.address_id,
          type: data.type,
          update_customer: data.update_customer,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("editDeliveryAddressSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          commit("editDeliveryAddressError");
          reject(err);
        });
    });
  },
  fetchPrice({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("fetchPriceRequest");
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/subscriptions/fetch-price`,
        data: {
          volume: data.volume,
          product_1: data.product_1,
          product_2: data.product_2,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("fetchPriceSuccess");
          resolve(resp.data.price);
        })
        .catch(() => reject());
    });
  },
  editDelivery({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("editDeliveryRequest");
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/deliveries/${
          data.deliveryId
        }`,
        data: {
          fields: data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("editDeliverySuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          commit("editDeliveryError");
          reject(err);
        });
    });
  },
  loadOrdersModule({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/orders`,
        method: "GET",
      })
        .then((resp) => {
          const module = resp.data;
          commit("moduleLoadOrdersSuccess", { module });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/orders/${data.orderId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadOrderSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },
  saveOrder({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/paw/orders/${data.orderId}`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveOrderSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  loadFaqsModule({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/faqs`,
        method: "GET",
      })
        .then((resp) => {
          const module = resp.data;
          commit("moduleLoadFaqsSuccess", { module });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveFaq({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/paw/faqs/${data.faqId}`
      : `${getApiUrl(data.appId)}/module/paw/faqs`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveFaqSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteFaq({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/faqs/${data.faqId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteFaqSuccess", { faqId: data.faqId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  loadNewsModule({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/news`,
        method: "GET",
      })
        .then((resp) => {
          commit("moduleLoadNewSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  loadArticle({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/news/${data.articleId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadArticleSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  saveArticle({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/paw/news/${data.articleId}`
      : `${getApiUrl(data.appId)}/module/paw/news`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveArticleSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteArticle({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/news/${data.articleId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteArticleSuccess", { articleId: data.articleId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  loadNewsCategories({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/news/categories`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadNewsCategoriesSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveNewsCategory({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/paw/news/categories/${data.categoryId}`
      : `${getApiUrl(data.appId)}/module/paw/news/categories`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveNewsCategorySuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  saveFaqCategory({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/paw/faqs/categories/${data.categoryId}`
      : `${getApiUrl(data.appId)}/module/paw/faqs/categories`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveFaqCategorySuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteNewsCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/news/categories/${
          data.categoryId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteNewsCategorySuccess", { categoryId: data.categoryId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  deleteFaqCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/paw/faqs/categories/${
          data.categoryId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deletefaqCategorySuccess", { categoryId: data.categoryId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
};

// mutations
const mutations = {
  saveProductRequest() {},
  saveProductSuccess(state, data) {
    if (data.isEditing) {
      const index = state.products.findIndex((p) => p.id === data.product.id);
      state.products.splice(index, 1, data.product);
    } else {
      state.products.push(data.product);
    }
  },
  saveProductError() {},
  loadProductSuccess(state, data) {
    state.product = data.product;
  },
  deleteProductRequest() {},
  deleteProductSuccess(state, data) {
    state.products = state.products.filter((p) => {
      return p.id !== data.id;
    });
  },
  archiveBreederSuccess(state) {
    state.customer.breeder = {};
  },
  deleteProductError() {},
  updateProductsDisplayOrderSuccess(state, data) {
    state.products = data.products;
  },
  saveBreedRequest() {},
  saveBreedSuccess(state, data) {
    if (data.isEditing) {
      const index = state.breeds.findIndex((b) => b.id === data.breed.id);
      state.breeds.splice(index, 1, data.breed);
    } else {
      state.breeds.push(data.breed);
    }
  },
  saveBreedError() {},
  deleteBreedRequest() {},
  deleteBreedSuccess(state, data) {
    state.breeds = state.breeds.filter((b) => {
      return b.id !== data.id;
    });
  },
  deleteBreedError() {},
  moduleLoadCustomerRequest() {},
  moduleLoadCustomerSuccess(state, data) {
    state.customers = data.module.customers;
  },
  moduleLoadBreedersSuccess(state, data) {
    state.breeders = data.module.breeders;
  },
  saveBreederSuccess(state, data) {
    if (data.isEditing) {
      state.customer.breeder = data.breeder;
    } else {
      state.customer.breeder = data.breeder;
    }
  },
  deleteCodeSuccess(state, data) {
    if (data.breederPage) {
      state.customer.breeder.codes = state.customer.breeder.codes.filter(
        function (a) {
          return a.id !== data.id;
        }
      );
    } else {
      const index = state.codes.findIndex((a) => a.id === data.codeId);
      state.codes.splice(index, 1);
    }
  },
  resendCodeSuccess() {},
  moduleLoadCodesSuccess(state, data) {
    state.codes = data.module.codes;
  },
  moduleLoadCustomerError() {},
  moduleLoadProductRequest() {},
  moduleLoadProductSuccess(state, data) {
    state.products = data.module.products;
  },
  moduleLoadProductError() {},
  moduleLoadBreedRequest() {},
  moduleLoadBreedSuccess(state, data) {
    state.breeds = data.module.breeds;
  },
  moduleLoadBreedError() {},
  moduleLoadSubscriptionRequest() {},
  moduleLoadSubscriptionSuccess(state, data) {
    state.subscriptions = data.module.subscriptions;
  },
  moduleLoadSubscriptionError() {},
  moduleLoadDeliveriesRequest() {},
  moduleLoadDeliveriesSuccess(state, data) {
    state.deliveries = data.module.deliveries;
  },
  moduleLoadDeliveriesError() {},
  loadCustomerRequest() {},
  loadCustomerSuccess(state, data) {
    state.customer = data.customer;
  },
  loadBreederSuccess(state, data) {
    state.customer = data.module.customer;
    state.rewardsHistory = data.module.rewards_history;
  },
  loadCustomerError() {},
  loadSubscriptionRequest() {},
  loadSubscriptionSuccess(state, data) {
    state.subscription = data.subscription;
  },
  loadSubscriptionError() {},
  loadDeliveryRequest() {},
  loadDeliverySuccess(state, data) {
    state.delivery = data.delivery;
  },
  loadDeliveryError() {},
  deleteAddressRequest() {},
  deleteAddressSuccess(state, data) {
    state.customer.addresses = state.customer.addresses.filter(function (a) {
      return a.id !== data.id;
    });
  },
  deleteAddressError() {},
  setAddressRequest() {},
  setAddressSuccess(state, data) {
    if (data.type === "delivery") {
      state.customer.shipping_address = data.addressId;
    } else {
      state.customer.billing_address = data.addressId;
    }
  },
  setAddressError() {},
  saveAddressRequest() {},
  saveAddressSuccess(state, data) {
    if (data.isEditing) {
      const index = state.customer.addresses.findIndex(
        (a) => a.id === data.address.id
      );
      state.customer.addresses[index] = data.address;
    } else {
      state.customer.addresses.push(data.address);
    }
    state.customer.shipping_address = data.customer.shipping_address;
    state.customer.billing_address = data.customer.billing_address;
  },
  saveAddressError() {},
  editSubscriptionRequest() {},
  editSubscriptionSuccess(state, data) {
    const index = state.subscriptions.findIndex(
      (s) => s.id === data.subscription.id
    );
    state.subscriptions[index] = data.subscription;
    state.subscription = data.subscription;
  },
  editSubscriptionErrors() {},
  updateSubscriptionStatusRequest() {},
  updateSubscriptionStatusSuccess(state, data) {
    const index = state.subscriptions.findIndex(
      (s) => s.id === data.subscription.id
    );
    state.subscriptions[index] = data.subscription;
    state.subscription = data.subscription;
  },
  updateSubscriptionStatusError() {},
  editCustomerRequest() {},
  editCustomerSuccess(state, data) {
    const index = state.customers.findIndex((c) => c.id === data.customer.id);
    state.customers[index] = data.customer;
    state.customer = data.customer;
  },
  editCustomerError() {},
  editDeliveryAddressRequest() {},
  editDeliveryAddressSuccess(state, data) {
    const index = state.deliveries.findIndex((d) => d.id === data.delivery.id);
    state.deliveries[index] = data.delivery;
    state.delivery = data.delivery;
  },
  editDeliveryAddressError() {},
  fetchPriceRequest() {},
  fetchPriceSuccess() {},
  fetchPriceError() {},
  editDeliveryRequest() {},
  editDeliverySuccess(state, data) {
    const index = state.deliveries.findIndex((d) => d.id === data.delivery.id);
    state.deliveries[index] = data.delivery;
    state.delivery = data.delivery;
  },
  editDeliveryError() {},
  moduleLoadOrdersSuccess(state, data) {
    state.orders = data.module.orders;
  },
  loadOrderSuccess(state, data) {
    state.order = data.order;
  },
  saveOrderSuccess(state, data) {
    const index = state.orders.findIndex((o) => o.id === data.order.id);
    state.orders.splice(index, 1, data.order);
    state.order = data.order;
  },
  moduleLoadFaqsSuccess(state, data) {
    state.faqs = data.module.faqs;
    state.faqCategories = data.module.categories;
  },
  saveFaqSuccess(state, data) {
    if (data.isEditing) {
      const index = state.faqs.findIndex((f) => f.id === data.faq.id);
      state.faqs.splice(index, 1, data.faq);
    } else {
      state.faqs.push(data.faq);
    }
  },
  deleteFaqSuccess(state, data) {
    const index = state.faqs.findIndex((f) => f.id === data.faqId);
    state.faqs.splice(index, 1);
  },
  moduleLoadNewSuccess(state, data) {
    state.articles = data.articles;
    state.archivedArticles = data.archived;
  },
  loadArticleSuccess(state, data) {
    state.article = data.article;
  },
  saveArticleSuccess(state, data) {
    if (data.isEditing) {
      const index = state.articles.findIndex((a) => a.id === data.article.id);
      state.articles.splice(index, 1, data.article);
    } else {
      state.articles.push(data.article);
    }
  },
  deleteArticleSuccess(state, data) {
    const index = state.articles.findIndex((a) => a.id === data.articleId);
    state.archivedArticles.push(state.articles[index]);
    state.articles.splice(index, 1);
  },
  loadNewsCategoriesSuccess(state, data) {
    state.newsCategories = data.categories;
    state.archivedNewsCategories = data.archived;
  },
  saveNewsCategorySuccess(state, data) {
    if (data.isEditing) {
      const index = state.newsCategories.findIndex(
        (a) => a.id === data.category.id
      );
      state.newsCategories.splice(index, 1, data.category);
    } else {
      state.newsCategories.push(data.category);
    }
  },
  saveFaqCategorySuccess(state, data) {
    if (data.isEditing) {
      const index = state.faqCategories.findIndex(
        (a) => a.id === data.category.id
      );
      state.faqCategories.splice(index, 1, data.category);
    } else {
      state.faqCategories.push(data.category);
    }
  },
  deleteNewsCategorySuccess(state, data) {
    const index = state.newsCategories.findIndex(
      (c) => c.id === data.categoryId
    );
    state.archivedNewsCategories.push(state.newsCategories[index]);
    state.newsCategories.splice(index, 1);
  },
  deletefaqCategorySuccess(state, data) {
    const index = state.faqCategories.findIndex(
      (c) => c.id === data.categoryId
    );
    state.faqCategories.splice(index, 1);
  },
};

// modules
const modules = {
  settingsStore,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
