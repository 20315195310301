import store from "@/stores/";

export default [
  {
    path: "products",
    component: () => import("@/modules/watson/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("watson/products/loadProducts", {
          appId: to.params.id,
        }),
        store.dispatch("watson/products/loadCategories", {
          appId: to.params.id,
        }),
        store.dispatch("watson/products/loadAddons", {
          appId: to.params.id,
        }),
        store.dispatch("watson/species/loadSpecies", {
          appId: to.params.id,
        }),
        store.dispatch("watson/testimonials/loadTestimonials", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-watson-products",
        component: () => import("@/modules/watson/views/products/Dashboard"),
      },
      {
        path: "categories",
        name: "module-watson-product-categories",
        component: () =>
          import("@/modules/watson/views/products/categories/Dashboard"),
      },
      {
        path: "addons",
        component: () => import("@/modules/watson/views/Template"),
        children: [
          {
            path: "",
            name: "module-watson-product-addons",
            component: () =>
              import("@/modules/watson/views/products/addons/Dashboard"),
          },
          {
            path: "archived",
            name: "module-watson-product-addons-archived",
            component: () =>
              import("@/modules/watson/views/products/addons/Archived"),
          },
        ],
      },
      {
        path: "archived",
        name: "module-watson-product-archived",
        component: () => import("@/modules/watson/views/products/Archived"),
      },
    ],
  },
];
