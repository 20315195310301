export default [
  {
    path: "",
    name: "module-paw-faqs",
    component: () => import("@/modules/paw/views/faqs/Dashboard"),
  },
  {
    path: "categories",
    component: () => import("@/modules/paw/views/faqs/Template"),
    children: [
      {
        path: "",
        name: "module-paw-faqs-categories",
        props: () => ({
          parentRouteName: "module-paw-faqs",
        }),
        component: () => import("@/modules/paw/views/faqs/Categories"),
      },
    ],
  },
];
