import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  orders: [],
  archived: [],
  order: {},
  swimBadges: [],
  swimBadgeOrders: [],
};

// getters
const getters = {
  all: (state) => state.orders,
  get: (state) => state.order,
  allOrderProducts: (state) =>
    state.orders.filter((b) => b.order_type === "product"),
  allOrderVouchers: (state) =>
    state.orders.filter((b) => b.order_type === "voucher"),
  allSwimBadges: (state) => state.swimBadges,
  allSwimBadgeOrders: (state) => state.swimBadgeOrders,
};

// actions
const actions = {
  loadOrders({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: data.hasOwnProperty("type")
          ? `${getApiUrl(data.appId)}/module/arnprior/orders/${data.type}`
          : `${getApiUrl(data.appId)}/module/arnprior/orders`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadOrdersSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/orders/${data.orderId}`,
        method: "GET",
      })
        .then((resp) => {
          const order = resp.data.order;
          commit("loadOrderSuccess", { order });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadSwimBadges({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/swim-badges`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadSwimBadgesSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadSwimBadgeOrders({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/orders/swim-badges`,
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          commit("loadSwimBadgeOrdersSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveSwimBadgeOrders({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: data.isEditing
          ? `${getApiUrl(data.appId)}/module/arnprior/orders/swim-badges/${
              data.swimBadgeOrderId
            }`
          : `${getApiUrl(data.appId)}/module/arnprior/orders/swim-badges`,
        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          commit("saveSwimBadgeOrdersSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  savePayment({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/orders/${
          data.orderId
        }/payments`,
        method: "POST",
        data: data.fields,
      })
        .then((resp) => {
          commit("savePaymentSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveSwimmBooking({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/arnprior/pool/bookings/${
          data.bookingId
        }`
      : `${getApiUrl(data.appId)}/module/arnprior/pool/bookings`;

    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          if (resp.data.audit) {
            commit("saveSwimmBookingSuccess", {
              ...resp.data,
              isEditing: data.isEditing,
            });
            resolve(resp);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => reject(err));
    });
  },

  savePoolPartyBooking({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/arnprior/pool/bookings/${
          data.bookingId
        }/party`
      : `${getApiUrl(data.appId)}/module/arnprior/pool/bookings/party`;

    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          if (resp.data.audit) {
            commit("savePoolPartyBookingSuccess", {
              ...resp.data,
              isEditing: data.isEditing,
            });
            resolve(resp);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => reject(err));
    });
  },

  savePartyPodBooking({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/arnprior/pods/bookings/${
          data.bookingId
        }/party`
      : `${getApiUrl(data.appId)}/module/arnprior/pods/bookings/party`;

    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          if (resp.data.audit) {
            commit("savePartyPodBookingSuccess", {
              ...resp.data,
              isEditing: data.isEditing,
            });
            resolve(resp);
          } else {
            resolve(resp);
          }
        })
        .catch((err) => reject(err));
    });
  },

  savePodBooking({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: data.isEditing
          ? `${getApiUrl(data.appId)}/module/arnprior/orders/${
              data.orderId
            }/pod-bookings/${data.bookingId}`
          : `${getApiUrl(data.appId)}/module/arnprior/orders/${
              data.orderId
            }/pod-bookings`,
        method: "POST",
        data: data.fields,
      })
        .then((resp) => {
          commit("savePodBookingSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  sendEmail({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/orders/${
          data.orderId
        }/mail`,
        method: "GET",
      })
        .then((resp) => {
          commit("sendMailSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveExtra({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/orders/${
          data.orderId
        }/extras`,
        method: "POST",
        data: data.fields,
      })
        .then((resp) => {
          commit("saveExtraSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteExtra({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/orders/${
          data.orderId
        }/bookings/${data.bookingId}/extras/${data.extraId}`,
        method: "DELETE",
      })
        .then((resp) => {
          commit("deleteExtraSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteBooking({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/orders/${
          data.orderId
        }/bookings/${data.bookingId}`,
        method: "DELETE",
      })
        .then((resp) => {
          commit("deleteBookingSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  sendEmailBalance({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/orders/${
          data.orderId
        }/send-email-balance`,
        method: "GET",
      })
        .then(() => {
          commit("sendEmailBalanceSuccess", {
            id: data.orderId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  deleteOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/orders/${data.orderId}`,
        method: "DELETE",
      })
        .then((resp) => {
          commit("deleteOrderSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadOrdersSuccess(state, data) {
    state.orders = data.orders;
  },
  loadOrderSuccess(state, data) {
    state.order = data.order;
  },
  loadSwimBadgesSuccess(state, data) {
    state.swimBadges = data.swimBadges;
  },
  loadSwimBadgeOrdersSuccess(state, data) {
    state.swimBadgeOrders = data.swimBadgeOrders;
  },
  saveSwimBadgeOrdersSuccess(state, data) {
    if (data.isEditing) {
      const index = state.swimBadgeOrders.findIndex(
        (o) => o.id === data.swimBadgeOrder.id
      );
      state.swimBadgeOrders.splice(index, 1, data.swimBadgeOrder);
    } else {
      state.swimBadgeOrders.push(data.swimBadgeOrder);
    }
  },
  savePaymentSuccess(state, data) {
    state.order.payments_total = data.payments_total;
    state.order.outstanding_total = data.outstanding_total;
    state.order.payments.push(data.payment);
    state.order.audits.push(data.audit);
  },
  savePodBookingSuccess(state, data) {
    state.order.sub_total = data.order.sub_total;
    state.order.vat_total = data.order.vat_total;
    state.order.total = data.order.total;
    state.order.outstanding_total = data.order.outstanding_total;

    if (data.isEditing) {
      const index = state.order.pod_bookings.findIndex(
        (b) => b.id === data.booking.id
      );
      state.order.pod_bookings[index] = data.booking;
      state.order.audits.push(data.audit);
    } else {
      state.order.pod_bookings.push(data.booking);
      state.order.audits.push(data.audit);
    }
  },

  savePartyPodBookingSuccess(state, data) {
    state.order.sub_total = data.order.sub_total;
    state.order.vat_total = data.order.vat_total;
    state.order.total = data.order.total;
    state.order.outstanding_total = data.order.outstanding_total;

    if (data.isEditing) {
      const index = state.order.party_pod_bookings.findIndex(
        (b) => b.id === data.booking.id
      );
      state.order.party_pod_bookings[index] = data.booking;
      state.order.audits.push(data.audit);
    } else {
      state.order.party_pod_bookings.push(data.booking);
      state.order.audits.push(data.audit);
    }
  },

  saveSwimmBookingSuccess(state, data) {
    state.order.sub_total = data.order.sub_total;
    state.order.vat_total = data.order.vat_total;
    state.order.total = data.order.total;
    state.order.outstanding_total = data.order.outstanding_total;

    if (data.isEditing) {
      const index = state.order.swim_bookings.findIndex(
        (b) => b.id === data.booking.id
      );
      state.order.swim_bookings[index] = data.booking;
      state.order.audits.push(data.audit);
    } else {
      state.order.swim_bookings.push(data.booking);
      state.order.audits.push(data.audit);
    }
  },

  savePoolPartyBookingSuccess(state, data) {
    state.order.sub_total = data.order.sub_total;
    state.order.vat_total = data.order.vat_total;
    state.order.total = data.order.total;
    state.order.outstanding_total = data.order.outstanding_total;

    if (data.isEditing) {
      const index = state.order.pool_party_bookings.findIndex(
        (b) => b.id === data.booking.id
      );
      state.order.pool_party_bookings[index] = data.booking;
      state.order.audits.push(data.audit);
    } else {
      state.order.pool_party_bookings.push(data.booking);
      state.order.audits.push(data.audit);
    }
  },

  saveExtraSuccess(state, data) {
    state.order = data.order;
  },
  deleteExtraSuccess(state, data) {
    const index = state.order.pod_booking_extras.findIndex(
      (c) => c.id === data.extraId
    );
    state.order.pod_booking_extras.splice(index, 1);
    state.order = data.order;
  },
  deleteBookingSuccess(state, data) {
    if (data.orderDeleted) {
      const index = state.orders.findIndex((o) => o.id === data.order.id);
      state.orders.splice(index, 1);
    } else {
      state.order = data.order;
    }
  },
  sendEmailBalanceSuccess() {},

  sendMailSuccess() {},

  deleteOrderSuccess(state, data) {
    const index = state.orders.findIndex((o) => o.id === data.orderId);
    state.orders.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
