import BreedRoutes from "./breeds.routes.js";
import BreedersRoutes from "./breeders.routes.js";
import CustomerRoutes from "./customers.routes.js";
import DeliveryRoutes from "./deliveries.routes.js";
import FaqRoutes from "./faqs.routes";
import NewsRoutes from "./news.routes.js";
import OrderRoutes from "./orders.routes";
import ProductRoutes from "./products.routes.js";
import SettingsRoutes from "./settings.routes.js";
import SubscriptionRoutes from "./subscriptions.routes.js";
import store from "@/stores/";

export default [
  {
    path: "paw",
    component: () => import("@/modules/paw/views/Template"),
    children: [
      {
        path: "customers",
        component: () => import("@/modules/paw/views/customers/Template"),
        meta: { hasPermission: "view-module-paw-customers" },
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("paw/loadCustomerModule", {
              appId: to.params.id,
              module: "paw-customers",
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
        children: CustomerRoutes,
      },
      {
        path: "breeders",
        component: () => import("@/modules/paw/views/breeders/Template"),
        meta: { hasPermission: "view-module-paw-breeders" },
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("paw/loadBreederModule", {
              appId: to.params.id,
              module: "paw-breeders",
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
        children: BreedersRoutes,
      },
      {
        path: "breeds",
        component: () => import("@/modules/paw/views/breeds/Template"),
        meta: { hasPermission: "view-module-paw-breeds" },
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("paw/loadBreedModule", {
              appId: to.params.id,
              module: "paw-breeds",
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
        children: BreedRoutes,
      },
      {
        path: "products",
        component: () => import("@/modules/paw/views/products/Template"),
        meta: { hasPermission: "view-module-paw-products" },
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("paw/loadProductModule", {
              appId: to.params.id,
              module: "paw-products",
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
        children: ProductRoutes,
      },
      {
        path: "subscriptions",
        component: () => import("@/modules/paw/views/subscriptions/Template"),
        meta: { hasPermission: "view-module-paw-subscriptions" },
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("paw/loadSubscriptionModule", {
              appId: to.params.id,
              module: "paw-subscriptions",
            }),
            store.dispatch("paw/loadBreedModule", {
              appId: to.params.id,
              module: "paw-breeds",
            }),
            store.dispatch("paw/loadProductModule", {
              appId: to.params.id,
              module: "paw-products",
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: SubscriptionRoutes,
      },
      {
        path: "deliveries",
        component: () => import("@/modules/paw/views/deliveries/Template"),
        meta: { hasPermission: "view-module-paw-deliveries" },
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("paw/loadDeliveriesModule", {
              appId: to.params.id,
              module: "paw-deliveries",
            }),
            store.dispatch("paw/loadBreedModule", {
              appId: to.params.id,
              module: "paw-breeds",
            }),
            store.dispatch("paw/loadProductModule", {
              appId: to.params.id,
              module: "paw-products",
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: DeliveryRoutes,
      },
      {
        path: "orders",
        component: () => import("@/modules/paw/views/orders/Template"),
        meta: { hasPermission: "view-module-paw-orders" },
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("paw/loadOrdersModule", {
              appId: to.params.id,
              module: "paw-orders",
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: OrderRoutes,
      },
      {
        path: "faqs",
        component: () => import("@/modules/paw/views/faqs/Template"),
        meta: { hasPermission: "view-module-paw-faqs" },
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("paw/loadFaqsModule", {
              appId: to.params.id,
              module: "paw-faqs",
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: FaqRoutes,
      },
      {
        path: "news",
        component: () => import("@/modules/paw/views/news/Template"),
        meta: { hasPermission: "view-module-paw-news" },
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("paw/loadNewsModule", {
              appId: to.params.id,
            }),
            store.dispatch("paw/loadNewsCategories", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: NewsRoutes,
      },
      ...SettingsRoutes,
    ],
  },
];
