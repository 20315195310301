import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  submissions: [],
  formNames: [],
  archivedSubmissions: [],
  recipients: [],
  archivedRecipients: [],
};

// getters
const getters = {};

// actions
const actions = {
  loadSubmissions({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/contact-forms`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadSubmissionsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteSubmission({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/contact-forms/${
          data.submissionId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteSubmissionSuccess", {
            submissionId: data.submissionId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  restoreSubmission({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/contact-forms/${
          data.submissionId
        }/restore`,
        method: "POST",
      })
        .then(() => {
          commit("restoreSubmissionSuccess", data);
          resolve();
        })
        .catch(() => reject());
    });
  },

  saveRecipient({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/contact-forms/recipients/${
          data.recipientId
        }`
      : `${getApiUrl(data.appId)}/module/contact-forms/recipients`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveRecipientSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  deleteRecipient({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/contact-forms/recipients/${
          data.recipientId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteRecipientSuccess", { recipientId: data.recipientId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  restoreRecipient({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/contact-forms/recipients/${
          data.recipientId
        }/restore`,
        method: "POST",
      })
        .then(() => {
          commit("restoreRecipientSuccess", data);
          resolve();
        })
        .catch(() => reject());
    });
  },

  saveMatching({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/melrose/properties/matching`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveMatchingSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadSubmissionsSuccess(state, data) {
    state.submissions = data.submissions;
    state.formNames = data.formNames;
    state.archivedSubmissions = data.archivedSubmissions;
    state.recipients = data.recipients;
    state.archivedRecipients = data.archivedRecipients;
  },
  deleteSubmissionSuccess(state, data) {
    const index = state.submissions.findIndex(
      (s) => s.id === data.submissionId
    );
    state.archivedSubmissions.push(state.submissions[index]);
    state.submissions.splice(index, 1);
  },
  restoreSubmissionSuccess(state, data) {
    const index = state.archivedSubmissions.findIndex(
      (s) => s.id === data.submissionId
    );
    state.submissions.push(state.archivedSubmissions[index]);
    state.archivedSubmissions.splice(index, 1);
  },

  saveMatchingSuccess(state, data) {
    state.submissions.push(data.submission);
  },

  saveRecipientSuccess(state, data) {
    if (data.isEditing) {
      const index = state.recipients.findIndex(
        (r) => r.id === data.recipient.id
      );
      state.recipients.splice(index, 1, data.recipient);
    } else {
      state.recipients.push(data.recipient);
    }
  },

  deleteRecipientSuccess(state, data) {
    const index = state.recipients.findIndex((r) => r.id === data.recipientId);
    state.archivedRecipients.push(state.recipients[index]);
    state.recipients.splice(index, 1);
  },
  restoreRecipientSuccess(state, data) {
    const index = state.archivedRecipients.findIndex(
      (r) => r.id === data.recipientId
    );
    state.recipients.push(state.archivedRecipients[index]);
    state.archivedRecipients.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
