// import axios from "axios";
// import { getApiUrl } from "@/utils";

// initial state
const state = {
  faqs: [
    {
      id: 1,
      title: "What facilities do all the pods have?",
      content:
        "Each pod has a private Hot Tub , use of our indoor swimming pool in a pre booked session, complimentary wifi & TV & DVD",
      category: "Glamping Pods",
    },
    {
      id: 2,
      title: "Do your pods welcome Pets?",
      content:
        "No they don’t due to being located on a working farm, next to a sheep field.",
      category: "Glamping Pods",
    },
    {
      id: 3,
      title: "What time can we check in? And where do we get our key?",
      content:
        "You can check in from 3pm. You will find your key in ther lockbox on the side of your pod. Your pre arrival email will have the lockbox code.",
      category: "Glamping Pods",
    },
  ],
  categories: ["Swimming Pool", "Glamping Pods"],
};

// getters
const getters = {
  all: (state) => state.faqs,
  categories: (state) => state.categories,
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
