// import axios from "axios";
// import { getApiUrl } from "@/utils";

// initial state
const state = {
  placesToEat: [
    {
      name: "Cross Keys in Kippen",
      website_url: "https://www.kippencrosskeys.com/",
    },
    {
      name: "Inn at Kippen",
      website_url: "https://www.theinnatkippen.co.uk/",
    },
    {
      name: "Gargunnock Inn",
      website_url: "https://www.gargunnockinn.co.uk/",
    },
    {
      name: "Lion & the Unicorn, Thornhill",
      website_url: "https://www.lion-unicorn.co.uk/",
    },
  ],
  placesToVisit: [
    {
      name: "Blairdrummond Safari Park",
      website_url: "https://www.blairdrummond.com/",
    },
    {
      name: "Briarlands Farm",
      website_url: "https://briarlandsfarm.co.uk/",
    },
    {
      name: "Stirling Castle",
      website_url: "https://www.stirlingcastle.scot/",
    },
    {
      name: "Stirling Old Town Jail",
      website_url: "https://oldtownjail.co.uk/",
    },
  ],
};

// getters
const getters = {
  placesToEat: (state) => state.placesToEat,
  placesToVisit: (state) => state.placesToVisit,
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
