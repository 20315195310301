import store from "@/stores/";

export default [
  {
    path: "enquiries",
    component: () => import("@/modules/sbpm/views/Template"),
    children: [
      {
        path: "",
        props: () => ({
          status: "New",
        }),
        name: "module-sbpm-enquiries",
        component: () => import("@/modules/sbpm/views/enquiries/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("sbpm/enquiriesStore/loadEnquiries", {
              appId: to.params.id,
            }),
            store.dispatch("users/loadModule", {
              appId: to.params.id,
              module: "users",
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "archived",
        name: "module-sbpm-enquiries-archived",
        props: () => ({
          pageTitle: "Enquiries",
          parentRouteName: "module-sbpm-enquiries",
        }),
        component: () => import("@/modules/sbpm/views/enquiries/Archived"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("sbpm/enquiriesStore/loadEnquiries", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: ":enquiryId",
        name: "module-sbpm-enquiries-individual",
        component: () => import("@/modules/sbpm/views/enquiries/Individual"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("sbpm/enquiriesStore/loadEnquiry", {
              appId: to.params.id,
              enquiryId: to.params.enquiryId,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
    ],
  },

  {
    path: "enquiries-managed",
    component: () => import("@/modules/sbpm/views/Template"),
    children: [
      {
        path: "",
        props: () => ({
          status: "Managed Let",
        }),
        name: "module-sbpm-enquiriesmanagedlet",
        component: () => import("@/modules/sbpm/views/enquiries/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("sbpm/enquiriesStore/loadEnquiries", {
              appId: to.params.id,
            }),
            store.dispatch("users/loadModule", {
              appId: to.params.id,
              module: "users",
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
    ],
  },

  {
    path: "enquiries-enquiries-let-only",
    component: () => import("@/modules/sbpm/views/Template"),
    children: [
      {
        path: "",
        props: () => ({
          status: "Let Only",
        }),
        name: "module-sbpm-enquiriesletonly",
        component: () => import("@/modules/sbpm/views/enquiries/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("sbpm/enquiriesStore/loadEnquiries", {
              appId: to.params.id,
            }),
            store.dispatch("users/loadModule", {
              appId: to.params.id,
              module: "users",
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
    ],
  },
];
