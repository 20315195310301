import store from "@/stores/";

export default [
  {
    path: "solicitors",
    component: () => import("@/modules/melrose/views/Template"),
    children: [
      {
        path: "",
        name: "module-melrose-solicitors",
        component: () => import("@/modules/melrose/views/solicitors/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("melrose/solicitorsStore/loadSolicitors", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "archived",
        name: "module-melrose-solicitors-archived",
        props: () => ({
          pageTitle: "Solicitors",
          parentRouteName: "module-melrose-solicitors",
        }),
        component: () => import("@/modules/melrose/views/solicitors/Archived"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("melrose/solicitorsStore/loadSolicitors", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
    ],
  },
];
