import _ from "lodash";
import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  products: [],
  categories: [],
  addons: [],
  archived: [],
  archivedAddons: [],
};

// getters
const getters = {};

// actions
const actions = {
  loadProducts({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/watson/products`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadProductsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadProduct(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/watson/products/${
          data.productId
        }`,
        method: "GET",
      })
        .then((resp) => {
          resolve(resp.data.product);
        })
        .catch((err) => reject(err));
    });
  },
  saveProduct({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/watson/products/${data.productId}`
      : `${getApiUrl(data.appId)}/module/watson/products`;

    let fields = data.fields;

    if (fields.thumbnail_has_changed === false) {
      fields.thumbnail = null;
    }

    if (fields.seed_illustration_has_changed === false) {
      fields.seed_illustration = null;
    }

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (
        fields[field] === null ||
        typeof fields[field] === "undefined"
      ) {
        formData.append(field, "");
      } else if (field === "varieties") {
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(`varieties[${i}][title]`, data[i]["title"]);
          formData.append(`varieties[${i}][type]`, data[i]["type"]);
          formData.append(`varieties[${i}][percent]`, data[i]["percent"]);
        }
      } else if (field === "details") {
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(`details[${i}][table]`, data[i]["table"]);
          formData.append(`details[${i}][title]`, data[i]["title"]);
          formData.append(`details[${i}][content]`, data[i]["content"]);
        }
      } else if (field === "details2") {
        const data = fields[field];

        // if (data.length > 0) {
        if (data["type"]) {
          if (data["tables"][0]["rows"].length > 0) {
            formData.append(`details[type]`, data["type"]);
          }

          for (let p = 0; p < data["tables"].length; p++) {
            if (data["tables"][p]["rows"].length > 0) {
              for (let r = 0; r < data["tables"][p]["headings"].length; r++) {
                formData.append(
                  `details[tables][${p}][headings][${r}]`,
                  data["tables"][p]["headings"][r]
                );

                for (let e = 0; e < data["tables"][p]["rows"].length; e++) {
                  formData.append(
                    `details[tables][${p}][rows][${e}][${r}]`,
                    data["tables"][p]["rows"][e][r]
                  );
                }
              }
            }
          }
        }
      } else if (field === "addons") {
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(`addons[${i}][id]`, data[i]["id"]);
          formData.append(
            `addons[${i}][sowing_ratio]`,
            data[i]["sowing_ratio"] ? data[i]["sowing_ratio"] : ""
          );
        }
      } else if (typeof fields[field] === "object") {
        if (fields[field] instanceof File) {
          formData.append(field, fields[field]);
        }
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(field + "[]", data[i]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveProductSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/watson/products/${
          data.productId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteProductSuccess", { productId: data.productId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  loadCategories({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/watson/products/categories`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadCategoriesSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveCategory({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/watson/products/categories/${
          data.categoryId
        }`
      : `${getApiUrl(data.appId)}/module/watson/products/categories`;

    let fields = data.fields;

    if (fields.thumbnail_has_changed === false) {
      fields.thumbnail = null;
    }

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (
        fields[field] === null ||
        typeof fields[field] === "undefined"
      ) {
        formData.append(field, "");
      } else if (typeof fields[field] === "object") {
        if (fields[field] instanceof File) {
          formData.append(field, fields[field]);
        }
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(field + "[]", data[i]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveCategorySuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/watson/products/categories/${
          data.categoryId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteCategorySuccess", { categoryId: data.categoryId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  loadAddons({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/watson/products/addons`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadAddonsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveAddon({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/watson/products/addons/${data.addonId}`
      : `${getApiUrl(data.appId)}/module/watson/products/addons`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveAddonSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  deleteAddon({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/watson/products/addons/${
          data.addonId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteAddonSuccess", { addonId: data.addonId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
};

// mutations
const mutations = {
  loadProductsSuccess(state, data) {
    state.products = data.products;
    state.archived = data.archived;
  },
  saveProductSuccess(state, data) {
    if (data.isEditing) {
      const index = state.products.findIndex((p) => p.id === data.product.id);
      state.products.splice(index, 1, data.product);
    } else {
      state.products.push(data.product);
    }
  },
  deleteProductSuccess(state, data) {
    const index = state.products.findIndex((p) => p.id === data.productId);
    state.archived.push(state.products[index]);
    state.products.splice(index, 1);
  },

  loadCategoriesSuccess(state, data) {
    state.categories = data.categories;
  },
  saveCategorySuccess(state, data) {
    if (data.isEditing) {
      const index = state.categories.findIndex(
        (c) => c.id === data.category.id
      );
      state.categories.splice(index, 1, data.category);
    } else {
      state.categories.push(data.category);
    }

    let categories = _.cloneDeep(state.categories);
    state.categories = _.sortBy(categories, "name");
  },
  deleteCategorySuccess(state, data) {
    const index = state.categories.findIndex((c) => c.id === data.categoryId);
    state.categories.splice(index, 1);
  },

  loadAddonsSuccess(state, data) {
    state.addons = data.addons;
    state.archivedAddons = data.archived;
  },
  saveAddonSuccess(state, data) {
    if (data.isEditing) {
      const index = state.addons.findIndex((a) => a.id === data.addon.id);
      state.addons.splice(index, 1, data.addon);
    } else {
      state.addons.push(data.addon);
    }
  },
  deleteAddonSuccess(state, data) {
    const index = state.addons.findIndex((a) => a.id === data.addonId);
    state.archivedAddons.push(state.addons[index]);
    state.addons.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
