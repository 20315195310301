import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  testimonials: [],
  images: [],
  testimonial: {},
};

// getters
const getters = {
  all: (state) => state.testimonials,
  allImages: (state) => state.images,
  get: (state) => state.testimonial,
};

// actions
const actions = {
  loadTestimonials({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/includem/testimonials`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadTestimonialsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveTestimonial({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/includem/testimonials/${
          data.testimonialId
        }`
      : `${getApiUrl(data.appId)}/module/includem/testimonials`;

    let fields = data.fields;

    // if (fields.image_has_changed === false) {
    //  fields.image = null;
    // }

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveTestimonialSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteTestimonial({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/includem/testimonials/${
          data.testimonialId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteTestimonialSuccess", {
            testimonialId: data.testimonialId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
};

// mutations
const mutations = {
  loadTestimonialsSuccess(state, data) {
    state.testimonials = data.testimonials;
    state.images = data.images;
  },
  saveTestimonialSuccess(state, data) {
    if (data.isEditing) {
      const index = state.testimonials.findIndex(
        (t) => t.id === data.testimonial.id
      );
      state.testimonials.splice(index, 1, data.testimonial);
    } else {
      state.testimonials.push(data.testimonial);
    }
  },
  deleteTestimonialSuccess(state, data) {
    const index = state.testimonials.findIndex(
      (t) => t.id === data.testimonialId
    );
    state.testimonials.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
