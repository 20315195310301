import biosStore from "./bios.store";
import teamsStore from "./teams.store";
import testimonialsStore from "./testimonials.store";

// default state
const getDefaultState = () => {
  return {};
};

// initial state
const state = getDefaultState();

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

// modules
const modules = {
  biosStore,
  testimonialsStore,
  teamsStore,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
