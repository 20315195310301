// import axios from "axios";

import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  customers: [],
  archived: [],
  customer: {},
};

// getters
const getters = {
  all: (state) => state.customers,
  get: (state) => state.customer,
  archived: (state) => state.archived,
  customers: (state) => state.customers,
};

// actions
const actions = {
  loadCustomers({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/customers`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadCustomersSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadCustomer({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/customers/${
          data.customerId
        }`,
        method: "GET",
      })
        .then((resp) => {
          const customer = resp.data.customer;
          commit("loadCustomerSuccess", { customer });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteCustomer({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/customers/${
          data.customerId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteCustomerSuccess", {
            customerId: data.customerId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  restoreCustomer({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/customers/${
          data.customerId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreCustomerSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveCustomer({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/melrose/customers/${data.customerId}`
      : `${getApiUrl(data.appId)}/module/melrose/customers`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveCustomerSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
            fromCustomer: data.fromCustomer,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadCustomersSuccess(state, data) {
    state.customers = data.customers;
    state.archived = data.archived;
  },
  loadCustomerSuccess(state, data) {
    state.customer = data.customer;
  },
  deleteCustomerSuccess(state, data) {
    const index = state.customers.findIndex((c) => c.id === data.customerId);
    state.customers.splice(index, 1);
  },
  restoreCustomerSuccess(state, data) {
    const index = state.archived.findIndex((c) => c.id === data.customer.id);
    state.archived.splice(index, 1);
    state.customers.push(data.customer);
  },
  saveCustomerSuccess(state, data) {
    if (data.isEditing) {
      const index = state.customers.findIndex((p) => p.id === data.customer.id);
      state.customers.splice(index, 1, data.customer);
      state.customer = data.customer;
    } else {
      state.customers.push(data.customer);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
