import axios from "axios";

// initial state
const state = {
  opportunities: [],
  archived: []
};

// getters
const getters = {};

// actions
const actions = {
  loadModule({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("moduleLoadRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/opportunities`,
        method: "GET"
      })
        .then(resp => {
          const module = resp.data;
          commit("moduleLoadSuccess", { module });
          resolve(resp);
        })
        .catch(err => {
          commit("moduleLoadError");
          reject(err);
        });
    });
  },
  addOpportunity({ commit }, data) {
    commit("addOpportunityRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/opportunities`,
        data: {
          ...data
        },
        method: "POST"
      })
        .then(resp => {
          const opportunity = resp.data.opportunity;
          commit("addOpportunitySuccess", { opportunity });
          resolve();
        })
        .catch(err => {
          commit("addOpportunityError");
          reject(err);
        });
    });
  },
  editOpportunity({ commit }, data) {
    commit("editOpportunityRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/opportunities/${data.opportunityId}/update`,
        data: {
          ...data
        },
        method: "POST"
      })
        .then(resp => {
          const opportunity = resp.data.opportunity;
          commit("editOpportunitySuccess", { opportunity });
          resolve();
        })
        .catch(err => {
          commit("editOpportunityError");
          reject(err);
        });
    });
  },
  updateStatus({ commit }, data) {
    commit("updateStatusRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/opportunities/${data.id}/status`,
        data: {
          ...data
        },
        method: "POST"
      })
        .then(resp => {
          const opportunity = resp.data.opportunity;
          commit("updateStatusSuccess", { opportunity });
          resolve();
        })
        .catch(err => {
          commit("updateStatusError");
          reject(err);
        });
    });
  },
  updateStartDate({ commit }, data) {
    commit("updateStartDateRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/opportunities/${data.id}/start-date`,
        data: {
          ...data
        },
        method: "POST"
      })
        .then(resp => {
          const opportunity = resp.data.opportunity;
          commit("updateStartDateSuccess", { opportunity });
          resolve();
        })
        .catch(err => {
          commit("updateStartDateError");
          reject(err);
        });
    });
  },
  archiveOpportunity({ commit }, data) {
    commit("archiveOpportunityRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/opportunities/${data.id}/archive`,
        data: {
          id: data.id
        },
        method: "POST"
      })
        .then(resp => {
          const archived = resp.data.archived;
          commit("archiveOpportunitySuccess", { archived, id: data.id });
          resolve();
        })
        .catch(err => {
          commit("archiveOpportunityError");
          reject(err);
        });
    });
  },
  reactivateOpportunity({ commit }, data) {
    commit("reactivateOpportunityRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/opportunities/${data.id}/reactivate`,
        data: {
          id: data.id
        },
        method: "POST"
      })
        .then(resp => {
          const opportunity = resp.data.opportunity;
          commit("reactivateOpportunitySuccess", { opportunity, id: data.id });
          resolve();
        })
        .catch(err => {
          commit("reactivateOpportunityError");
          reject(err);
        });
    });
  },
  markInXero({ commit }, data) {
    commit("markInXeroRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/opportunities/${data.id}/in-xero`,
        method: "POST"
      })
        .then(resp => {
          const opportunity = resp.data.opportunity;
          commit("markInXeroSuccess", { opportunity, id: data.id });
          resolve();
        })
        .catch(err => {
          commit("markInXeroError");
          reject(err);
        });
    });
  },
  outOfXero({ commit }, data) {
    commit("outOfXeroRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/opportunities/${data.id}/not-in-xero`,
        method: "POST"
      })
        .then(resp => {
          const opportunity = resp.data.opportunity;
          commit("outOfXeroSuccess", { opportunity, id: data.id });
          resolve();
        })
        .catch(err => {
          commit("outOfXeroError");
          reject(err);
        });
    });
  },
  duplicate({ commit }, data) {
    commit("duplicateRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/opportunities/${data.id}/duplicate`,
        method: "POST"
      })
        .then(resp => {
          const opportunity = resp.data.opportunity;
          commit("duplicateSuccess", { opportunity });
          resolve();
        })
        .catch(err => {
          commit("duplicateError");
          reject(err);
        });
    });
  }
};

// mutations
const mutations = {
  moduleLoadRequest() {},
  moduleLoadSuccess(state, data) {
    state.opportunities = data.module.opportunities;
    state.archived = data.module.archived;
  },
  moduleLoadError() {},
  addOpportunityRequest() {},
  addOpportunitySuccess(state, data) {
    state.opportunities.push(data.opportunity);
  },
  addOpportunityError() {},
  editOpportunityRequest() {},
  editOpportunitySuccess(state, data) {
    const index = state.opportunities.findIndex(
      o => o.id === data.opportunity.id
    );
    const op = data.opportunity;
    state.opportunities[index].description = op.description;
    state.opportunities[index].status = op.status;
    state.opportunities[index].project_amount = op.project_amount;
    state.opportunities[index].monthly_amount = op.monthly_amount;
    state.opportunities[index].yearly_amount = op.yearly_amount;
    state.opportunities[index].start_date = op.start_date;
    state.opportunities[index].notes = op.notes;
    state.opportunities[index].customer_id = op.customer_id;
    state.opportunities[index].owner_id = op.owner_id;
  },
  editOpportunityError() {},
  updateStatusRequest() {},
  updateStatusSuccess(state, data) {
    const index = state.opportunities.findIndex(
      o => o.id === data.opportunity.id
    );
    state.opportunities[index].status = data.opportunity.status;
  },
  updateStatusError() {},
  updateStartDateRequest() {},
  updateStartDateSuccess(state, data) {
    const index = state.opportunities.findIndex(
      o => o.id === data.opportunity.id
    );
    state.opportunities[index].start_date = data.opportunity.start_date;
  },
  updateStartDateError() {},
  archiveOpportunityRequest() {},
  archiveOpportunitySuccess(state, data) {
    state.archived = data.archived;
    state.opportunities = state.opportunities.filter(function(o) {
      return o.id !== data.id;
    });
  },
  archiveOpportunityError() {},
  reactivateOpportunityRequest() {},
  reactivateOpportunitySuccess(state, data) {
    state.opportunities.push(data.opportunity);
    state.archived = state.archived.filter(function(o) {
      return o.id !== data.id;
    });
  },
  markInXeroRequest() {},
  markInXeroSuccess(state, data) {
    const opportunities = state.opportunities;
    state.opportunities = opportunities.map(o => {
      if (o.id === data.opportunity.id) {
        return data.opportunity;
      }

      return o;
    });
  },
  markInXeroError() {},
  outOfXeroRequest() {},
  outOfXeroSuccess(state, data) {
    const opportunities = state.opportunities;
    state.opportunities = opportunities.map(o => {
      if (o.id === data.opportunity.id) {
        return data.opportunity;
      }

      return o;
    });
  },
  outOfXeroError() {},
  duplicateRequest() {},
  duplicateSuccess(state, data) {
    state.opportunities.push(data.opportunity);
  },
  duplicateError() {}
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
