export default [
  {
    path: "things-to-do",
    component: () => import("@/modules/arnprior/views/Template"),
    children: [
      {
        path: "place-to-eat",
        name: "module-arnprior-activities-arnprior-places-to-eat",
        component: () =>
          import("@/modules/arnprior/views/attractions/PlacesToEat"),
      },
      {
        path: "place-to-visit",
        name: "module-arnprior-activities-arnprior-places-to-visit",
        component: () =>
          import("@/modules/arnprior/views/attractions/PlacesToVisit"),
      },
    ],
  },
];
