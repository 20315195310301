import store from "@/stores/";

export default [
  {
    path: "proposals",
    component: () => import("@/modules/drum/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("drum/tours/loadProposals", {
          appId: to.params.id,
        }),
        store.dispatch("drum/customers/loadCustomers", {
          appId: to.params.id,
        }),
        store.dispatch("drum/admin/loadAgents", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-drum-proposals",
        component: () => import("@/modules/drum/views/proposals/Dashboard"),
      },
      {
        path: "archived",
        name: "module-drum-proposals-archived",
        props: () => ({
          isProposal: true,
        }),
        component: () => import("@/modules/drum/views/proposals/Archived"),
      },
      {
        path: ":tourId",
        component: () => import("@/modules/drum/views/Template"),
        props: () => ({
          isProposal: false,
        }),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("drum/tours/loadTour", {
              appId: to.params.id,
              tourId: parseInt(to.params.tourId),
              isProposal: true,
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
        children: [
          {
            path: "",
            redirect: {
              name: "module-drum-proposals-individual",
            },
          },
          {
            path: "overview",
            component: () =>
              import("@/modules/drum/views/proposals/Individual"),
            children: [
              {
                path: "",
                name: "module-drum-proposals-individual",
                component: () =>
                  import("@/modules/drum/views/proposals/Overview"),
              },
              {
                path: "golfers",
                name: "module-drum-proposals-individual-golfers",
                component: () =>
                  import("@/modules/drum/views/proposals/Golfers"),
              },
              {
                path: "itineraries",
                name: "module-drum-proposals-individual-itineraries",
                component: () =>
                  import("@/modules/drum/views/proposals/Itineraries"),
              },
              {
                path: "itineraries-archived",
                name: "module-drum-proposals-individual-itineraries-archived",
                component: () =>
                  import("@/modules/drum/views/proposals/ItinerariesArchived"),
              },
            ],
          },
          {
            path: "itineraries",
            component: () => import("@/modules/drum/views/Template"),
            children: [
              {
                path: ":itineraryId",
                component: () =>
                  import("@/modules/drum/views/proposals/itinerary/Individual"),
                beforeEnter: (to, from, next) => {
                  store.commit("showHideLoader", true);
                  Promise.all([
                    store.dispatch("drum/tours/loadItinerary", {
                      appId: to.params.id,
                      tourId: parseInt(to.params.tourId),
                      itineraryId: parseInt(to.params.itineraryId),
                    }),
                    store.dispatch("drum/admin/loadRestaurantRegions", {
                      appId: to.params.id,
                    }),
                  ]).then(() => {
                    store.commit("showHideLoader", false);
                    next();
                  });
                },
                children: [
                  {
                    path: "",
                    redirect: {
                      name: "module-drum-proposals-itinerary",
                    },
                  },
                  {
                    path: "overview",
                    name: "module-drum-proposals-itinerary",
                    component: () =>
                      import(
                        "@/modules/drum/views/proposals/itinerary/Overview"
                      ),
                  },
                  {
                    path: "main-details",
                    name: "module-drum-proposals-itinerary-mainDetails",
                    component: () =>
                      import(
                        "@/modules/drum/views/proposals/itinerary/MainDetails"
                      ),
                  },
                  {
                    path: "itinerary",
                    name: "module-drum-proposals-itinerary-itinerary",
                    beforeEnter: (to, from, next) => {
                      store.commit("showHideLoader", true);
                      Promise.all([
                        store.dispatch("drum/admin/loadCourses", {
                          appId: to.params.id,
                        }),
                        store.dispatch("drum/admin/loadEvents", {
                          appId: to.params.id,
                        }),
                        store.dispatch("drum/admin/loadAccommodations", {
                          appId: to.params.id,
                        }),
                        store.dispatch("drum/admin/loadSightseeings", {
                          appId: to.params.id,
                        }),
                      ]).then(() => {
                        store.commit("showHideLoader", false);
                        next();
                      });
                    },
                    component: () =>
                      import(
                        "@/modules/drum/views/proposals/itinerary/Itinerary"
                      ),
                  },
                  {
                    path: "images",
                    name: "module-drum-proposals-itinerary-images",
                    beforeEnter: (to, from, next) => {
                      store.commit("showHideLoader", true);
                      Promise.all([
                        store.dispatch("drum/admin/loadImages", {
                          appId: to.params.id,
                        }),
                      ]).then(() => {
                        store.commit("showHideLoader", false);
                        next();
                      });
                    },
                    component: () =>
                      import("@/modules/drum/views/proposals/itinerary/Images"),
                  },
                  {
                    path: "pricing",
                    name: "module-drum-proposals-itinerary-pricing",
                    component: () =>
                      import(
                        "@/modules/drum/views/proposals/itinerary/Pricing"
                      ),
                  },
                  {
                    path: "costs",
                    name: "module-drum-proposals-itinerary-costs",
                    beforeEnter: (to, from, next) => {
                      store.commit("showHideLoader", true);
                      Promise.all([
                        store.dispatch("drum/admin/loadCourses", {
                          appId: to.params.id,
                        }),
                        store.dispatch("drum/tours/loadItinerary", {
                          appId: to.params.id,
                          tourId: parseInt(to.params.tourId),
                          itineraryId: parseInt(to.params.itineraryId),
                        }),
                        store.dispatch("drum/admin/loadAccommodations", {
                          appId: to.params.id,
                        }),
                        store.dispatch("drum/admin/loadSuppliers", {
                          appId: to.params.id,
                        }),
                      ]).then(() => {
                        store.commit("showHideLoader", false);
                        next();
                      });
                    },
                    component: () =>
                      import("@/modules/drum/views/proposals/itinerary/Costs"),
                  },
                  {
                    path: "payments",
                    name: "module-drum-proposals-itinerary-payments",
                    component: () =>
                      import(
                        "@/modules/drum/views/proposals/itinerary/Payments"
                      ),
                  },
                  {
                    path: "golfers",
                    name: "module-drum-proposals-itinerary-golfers",
                    component: () =>
                      import(
                        "@/modules/drum/views/proposals/itinerary/Golfers"
                      ),
                  },
                  {
                    path: "invoices",
                    name: "module-drum-proposals-itinerary-invoices",
                    component: () =>
                      import(
                        "@/modules/drum/views/proposals/itinerary/Invoices"
                      ),
                  },
                  {
                    path: "deposit",
                    name: "module-drum-proposals-itinerary-deposit",
                    component: () =>
                      import(
                        "@/modules/drum/views/proposals/itinerary/Deposit"
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
