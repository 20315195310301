import store from "@/stores/";

export default [
  {
    path: "species",
    component: () => import("@/modules/grogreen/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("grogreen/species/loadSpecies", {
          appId: to.params.id,
        }),
        store.dispatch("grogreen/species/loadCategories", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-grogreen-species",
        component: () => import("@/modules/grogreen/views/species/Dashboard"),
      },
      {
        path: "categories",
        name: "module-grogreen-species-categories",
        component: () =>
          import("@/modules/grogreen/views/species/categories/Dashboard"),
      },
    ],
  },
];
