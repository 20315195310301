import store from "@/stores/";

export default [
  {
    path: "settings",
    component: () => import("@/modules/drum/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      store
        .dispatch("drum/settings/loadSettings", {
          appId: to.params.id,
        })
        .then(() => {
          store.commit("showHideLoader", false);
          next();
        });
    },
    children: [
      {
        path: "",
        name: "module-drum-settings",
        component: () => import("@/modules/drum/views/settings/Dashboard"),
      },
    ],
  },
];
