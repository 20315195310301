import store from "@/stores/";

export default [
  {
    path: "contact-forms",
    component: () => import("@/modules/contact-forms/views/Template"),
    meta: { hasPermission: "view-module-contactforms" },
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("contactForms/loadSubmissions", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-contactforms",
        component: () => import("@/modules/contact-forms/views/Dashboard"),
      },
      {
        path: "archived",
        name: "module-contactforms-archived",
        component: () => import("@/modules/contact-forms/views/Archived"),
      },
      {
        path: "recipients",
        component: () => import("@/modules/contact-forms/views/Template"),
        children: [
          {
            path: "",
            name: "module-contactforms-recipients",
            component: () =>
              import("@/modules/contact-forms/views/recipients/Recipients"),
          },
          {
            path: "archived",
            name: "module-contactforms-recipients-archived",
            component: () =>
              import("@/modules/contact-forms/views/recipients/Archived"),
          },
        ],
      },
    ],
  },
];
