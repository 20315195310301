import store from "@/stores/";

export default [
  {
    path: "",
    name: "module-paw-deliveries",
    component: () => import("@/modules/paw/views/deliveries/Dashboard"),
  },
  {
    path: ":deliveryId",
    name: "module-paw-deliveries-individual",
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      store
        .dispatch("paw/loadDelivery", {
          appId: to.params.id,
          deliveryId: to.params.deliveryId,
        })
        .then(() => {
          store.commit("showHideLoader", false);
          next();
        });
    },
    component: () => import("@/modules/paw/views/deliveries/Individual"),
  },
];
