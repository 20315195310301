import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  pages: [],
  archived: [],
};

// getters
const getters = {
  allPages: (state) => state.pages,
  archived: (state) => state.archived,
};

// actions
const actions = {
  loadPages({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/seo/pages`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadPagesSuccess", resp.data);
          resolve();
        })
        .catch((err) => reject(err));
    });
  },
  restorePage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/seo/pages/${data.pageId}/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restorePageSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  archivePage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/seo/pages/${data.pageId}`,
        data: {
          id: data.id,
        },
        method: "DELETE",
      })
        .then((resp) => {
          commit("archivePageSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  savePage({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/seo/pages/${data.pageId}`
      : `${getApiUrl(data.appId)}/module/seo/pages`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("savePageSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadPagesSuccess(state, data) {
    state.pages = data.pages;
    state.archived = data.archived;
  },
  archivePageSuccess(state, data) {
    const index = state.pages.findIndex((c) => c.id === data.page.id);
    state.pages.splice(index, 1);
    state.archived.push(data.page);
  },
  restorePageSuccess(state, data) {
    const index = state.archived.findIndex((c) => c.id === data.page.id);
    state.archived.splice(index, 1);
    state.pages.push(data.page);
  },
  savePageSuccess(state, data) {
    if (data.isEditing) {
      const index = state.pages.findIndex((c) => c.id === data.page.id);
      state.pages.splice(index, 1, data.page);
    } else {
      state.pages.push(data.page);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
