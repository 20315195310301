import store from "@/stores/";

export default [
  {
    path: "litters",
    component: () => import("@/modules/lhl/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("lhl/litters/loadLitters", {
          appId: to.params.id,
        }),
        store.dispatch("lhl/breeds/loadBreeds", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-lhl-litters",
        component: () => import("@/modules/lhl/views/litters/Dashboard"),
      },
      {
        path: "planned",
        name: "module-lhl-litters-planned",
        component: () => import("@/modules/lhl/views/litters/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("lhl/litters/loadLittersStatus", {
              appId: to.params.id,
              status: "Planned",
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "due",
        name: "module-lhl-litters-due",
        component: () => import("@/modules/lhl/views/litters/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("lhl/litters/loadLittersStatus", {
              appId: to.params.id,
              status: "Due",
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "born",
        name: "module-lhl-litters-born",
        component: () => import("@/modules/lhl/views/litters/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("lhl/litters/loadLittersStatus", {
              appId: to.params.id,
              status: "Born",
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "failed",
        name: "module-lhl-litters-failed",
        component: () => import("@/modules/lhl/views/litters/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("lhl/litters/loadLittersStatus", {
              appId: to.params.id,
              status: "Failed",
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "completed",
        name: "module-lhl-litters-completed",
        component: () => import("@/modules/lhl/views/litters/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("lhl/litters/loadLittersStatus", {
              appId: to.params.id,
              status: "Completed",
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: ":litterId",
        name: "module-lhl-litters-individual",
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("lhl/litters/loadLitter", {
              appId: to.params.id,
              litterId: to.params.litterId,
            }),
            store.dispatch("lhl/dogs/loadDogs", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },

        component: () => import("@/modules/lhl/views/litters/Individual"),
        children: [
          {
            path: "",
            redirect: {
              name: "module-lhl-litters-individual",
            },
          },
          {
            path: "details",
            name: "module-lhl-litters-individual",
            component: () => import("@/modules/lhl/views/litters/tabs/Details"),
          },
          {
            path: "management-tasks",
            name: "module-lhl-litters-individual-management-tasks",

            component: () =>
              import("@/modules/lhl/views/litters/tabs/ManagementTasks"),
          },
          {
            path: "admin-tasks",
            name: "module-lhl-litters-individual-admin-tasks",
            component: () =>
              import("@/modules/lhl/views/litters/tabs/AdminTasks"),
          },
          {
            path: "operations-tasks",
            name: "module-lhl-litters-individual-operations-tasks",

            component: () =>
              import("@/modules/lhl/views/litters/tabs/OperationTasks"),
          },
          {
            path: "puppies",
            name: "module-lhl-litters-individual-puppies",
            component: () => import("@/modules/lhl/views/litters/tabs/Puppies"),
          },
          {
            path: "record",
            name: "module-lhl-litters-individual-record",
            component: () => import("@/modules/lhl/views/litters/tabs/Record"),
          },
          {
            path: "clp",
            name: "module-lhl-litters-individual-clp",
            component: () => import("@/modules/lhl/views/litters/tabs/Clp"),
          },
          {
            path: "visits",
            name: "module-lhl-litters-individual-visits",
            component: () => import("@/modules/lhl/views/litters/tabs/Visits"),
          },
          {
            path: "weight",
            name: "module-lhl-litters-individual-weight",
            component: () => import("@/modules/lhl/views/litters/tabs/Weight"),
          },
          {
            path: "website",
            name: "module-lhl-litters-individual-website",
            component: () => import("@/modules/lhl/views/litters/tabs/Website"),
          },
        ],
      },
    ],
  },
];
