import store from "@/stores/";

export default [
  {
    path: "",
    name: "module-paw-breeders",
    component: () => import("@/modules/paw/views/breeders/Dashboard"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("paw/loadBreederModule", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
  },
  {
    path: "archived",
    name: "module-paw-breeders-archived",
    component: () => import("@/modules/paw/views/breeders/Archived"),
  },
  {
    path: "codes",
    name: "module-paw-breeders-codes",
    component: () => import("@/modules/paw/views/breeders/Codes"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("paw/loadCodesModule", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
  },
  {
    path: "reward-stats",
    name: "module-paw-breeders-reward-stats",
    component: () => import("@/modules/paw/views/breeders/RewardStats"),
  },
  {
    path: ":customerId",
    name: "module-paw-breeders-individual",
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("paw/loadBreeder", {
          appId: to.params.id,
          customerId: to.params.customerId,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    component: () => import("@/modules/paw/views/breeders/Individual"),
  },
];
