import CustomersRoutes from "./customers.routes";
import EventsRoutes from "./events.routes";
import NewsRoutes from "./news.routes";
import SettingsRoutes from "./settings.routes";
import TicketsRoutes from "./tickets.routes";
import VenuesRoutes from "./venues.routes";

export default [
  {
    path: "craigtoun",
    component: () => import("@/modules/craigtoun/views/Template"),
    children: [
      ...CustomersRoutes,
      ...EventsRoutes,
      ...NewsRoutes,
      ...TicketsRoutes,
      ...VenuesRoutes,
      ...SettingsRoutes,
    ],
  },
];
