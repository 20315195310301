// import axios from "axios";
import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  area_guides: [],
  archived: [],
  area_guide: {},
};

// getters
const getters = {
  all: (state) => state.area_guides,
  archived: (state) => state.archived,
  get: (state) => state.area_guide,
};

// actions
const actions = {
  loadAreaGuides({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/area-guides`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadAreaGuidesSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadAreaGuide({ commit }, data) {
    commit("loadAreaGuideSuccess", data);
  },
  saveAreaGuide({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/melrose/area-guides/${
          data.areaGuideId
        }`
      : `${getApiUrl(data.appId)}/module/melrose/area-guides`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveAreaGuideSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  deleteAreaGuide({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/area-guides/${
          data.areaGuideId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteAreaGuideSuccess", {
            areaGuideId: data.areaGuideId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  restore({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/area-guides/${
          data.areaGuideId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreAreaGuideSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadAreaGuidesSuccess(state, data) {
    state.area_guides = data.area_guides;
    state.archived = data.archived;
  },
  loadAreaGuideSuccess(state, data) {
    state.area_guide = state.area_guides.find((a) => {
      return a.id === parseInt(data.areaGuideId);
    });
  },
  saveAreaGuideSuccess(state, data) {
    if (data.isEditing) {
      const index = state.area_guides.findIndex(
        (p) => p.id === data.area_guide.id
      );
      state.area_guides.splice(index, 1, data.area_guide);
    } else {
      state.area_guides.push(data.area_guide);
    }
  },
  deleteAreaGuideSuccess(state, data) {
    const index = state.area_guides.findIndex((c) => c.id === data.areaGuideId);
    state.area_guides.splice(index, 1);
  },
  restoreAreaGuideSuccess(state, data) {
    const index = state.archived.findIndex((c) => c.id === data.area_guide.id);
    state.archived.splice(index, 1);
    state.area_guides.push(data.area_guide);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
