import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  article: {},
  articles: [],
  categories: [],
  archived: [],
  archivedCategories: [],
  currentType: null,
};

// getters
const getters = {
  currentType: state => state.currentType,
};

// actions
const actions = {
  loadArticles({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/watson/articles/types/${
          data.type
        }`,
        method: "GET",
      })
        .then(resp => {
          commit("loadArticlesSuccess", { ...resp.data, type: data.type });
          resolve();
        })
        .catch(err => reject(err));
    });
  },
  saveArticle({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/watson/articles/${data.articleId}`
      : `${getApiUrl(data.appId)}/module/watson/articles`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then(resp => {
          commit("saveArticleSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteArticle({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/watson/articles/${
          data.articleId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteArticleSuccess", { articleId: data.articleId });
          resolve();
        })
        .catch(() => reject());
    });
  },
  loadArticle({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/watson/articles/${
          data.articleId
        }`,
        method: "GET",
      })
        .then(resp => {
          commit("loadArticleSuccess", { ...resp.data });
          resolve();
        })
        .catch(err => reject(err));
    });
  },
  saveCategory({ commit, getters }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/watson/articles/categories/${
          data.categoryId
        }`
      : `${getApiUrl(data.appId)}/module/watson/articles/categories`;

    let fields = data.fields;

    if (fields.thumbnail_has_changed === false) {
      fields.thumbnail = null;
    }

    let formData = data.fields;
    formData.append("type", getters.currentType);

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then(resp => {
          commit("saveCategorySuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/watson/articles/categories/${
          data.categoryId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteCategorySuccess", { categoryId: data.categoryId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
};

// mutations
const mutations = {
  loadArticlesSuccess(state, data) {
    state.articles = data.articles;
    state.categories = data.categories;
    state.archived = data.archived;
    state.archivedCategories = data.archivedCategories;
    state.currentType = data.type;
  },
  saveArticleSuccess(state, data) {
    if (data.isEditing) {
      const index = state.articles.findIndex(a => a.id === data.article.id);
      state.articles.splice(index, 1, data.article);
    } else {
      state.articles.push(data.article);
    }
  },
  deleteArticleSuccess(state, data) {
    const index = state.articles.findIndex(a => a.id === data.articleId);
    state.archived.push(state.articles[index]);
    state.articles.splice(index, 1);
  },
  loadArticleSuccess(state, data) {
    state.article = data.article;
  },
  saveCategorySuccess(state, data) {
    if (data.isEditing) {
      const index = state.categories.findIndex(c => c.id === data.category.id);
      state.categories.splice(index, 1, data.category);
    } else {
      state.categories.push(data.category);
    }
  },
  deleteCategorySuccess(state, data) {
    const index = state.categories.findIndex(c => c.id === data.categoryId);
    state.archivedCategories.push(data.categories[index]);
    state.categories.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
