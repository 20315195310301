import store from "@/stores/";

export default [
  {
    path: "articles",
    component: () => import("@/modules/grogreen/views/Template"),
    children: [
      {
        path: "",
        name: "module-grogreen-articles",
        beforeEnter: (to, from, next) => {
          next({ name: "app-dashboard" });
        },
      },
      {
        path: "downloads",
        component: () => import("@/modules/grogreen/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("grogreen/articles/loadDownloads", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: [
          {
            path: "",
            name: "module-grogreen-articles-downloads",
            component: () =>
              import("@/modules/grogreen/views/articles/download/Dashboard"),
          },
          {
            path: "archived",
            name: "module-grogreen-articles-downloads-archived",
            props: () => ({
              pageTitle: "Download Archived",
            }),
            component: () =>
              import("@/modules/grogreen/views/articles/download/Archived"),
          },
        ],
      },

      {
        path: "learn",
        component: () => import("@/modules/grogreen/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("grogreen/articles/loadArticles", {
              appId: to.params.id,
              type: "technical",
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: [
          {
            path: "",
            name: "module-grogreen-articles-learn",
            props: () => ({
              pageTitle: "Learn",
            }),
            component: () =>
              import("@/modules/grogreen/views/articles/Dashboard"),
          },
          {
            path: "categories",
            component: () => import("@/modules/grogreen/views/Template"),
            children: [
              {
                path: "/",
                name: "module-grogreen-articles-learn-categories",
                props: () => ({
                  pageTitle: "Learn",
                }),
                component: () =>
                  import(
                    "@/modules/grogreen/views/articles/categories/Dashboard"
                  ),
              },
              {
                path: "archived",
                name: "module-grogreen-articles-learn-categories-archived",
                props: () => ({
                  pageTitle: "Learn",
                  parentRouteName: "module-grogreen-articles-learn",
                  parentCategoryName:
                    "module-grogreen-articles-learn-categories",
                }),
                component: () =>
                  import(
                    "@/modules/grogreen/views/articles/categories/Archived"
                  ),
              },
            ],
          },
          {
            path: "archived",
            name: "module-grogreen-articles-learn-archived",
            props: () => ({
              pageTitle: "Learn",
              parentRouteName: "module-grogreen-articles-learn",
            }),
            component: () =>
              import("@/modules/grogreen/views/articles/Archived"),
          },
          {
            path: "create",
            name: "module-grogreen-articles-learn-create",
            props: () => ({
              parentRouteName: "module-grogreen-articles-learn",
            }),
            component: () =>
              import("@/modules/grogreen/views/articles/Individual"),
          },
          {
            path: ":articleId",
            name: "module-grogreen-articles-learn-individual",
            props: () => ({
              isEditing: true,
              parentRouteName: "module-grogreen-articles-learn",
            }),
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("grogreen/articles/loadArticle", {
                  appId: to.params.id,
                  articleId: to.params.articleId,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
            component: () =>
              import("@/modules/grogreen/views/articles/Individual"),
          },
        ],
      },
      {
        path: "videos",
        component: () => import("@/modules/grogreen/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("grogreen/articles/loadArticles", {
              appId: to.params.id,
              type: "video",
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: [
          {
            path: "",
            name: "module-grogreen-articles-videos",
            props: () => ({
              pageTitle: "Videos",
            }),
            component: () =>
              import("@/modules/grogreen/views/articles/Dashboard"),
          },
          {
            path: "categories",
            component: () => import("@/modules/grogreen/views/Template"),
            children: [
              {
                path: "/",
                name: "module-grogreen-articles-videos-categories",
                props: () => ({
                  pageTitle: "Videos",
                }),
                component: () =>
                  import(
                    "@/modules/grogreen/views/articles/categories/Dashboard"
                  ),
              },
              {
                path: "archived",
                name: "module-grogreen-articles-videos-categories-archived",
                props: () => ({
                  pageTitle: "Videos",
                  parentRouteName: "module-grogreen-articles-videos",
                  parentCategoryName:
                    "module-grogreen-articles-videos-categories",
                }),
                component: () =>
                  import(
                    "@/modules/grogreen/views/articles/categories/Archived"
                  ),
              },
            ],
          },
          {
            path: "archived",
            name: "module-grogreen-articles-videos-archived",
            props: () => ({
              pageTitle: "Videos",
              parentRouteName: "module-grogreen-articles-videos",
            }),
            component: () =>
              import("@/modules/grogreen/views/articles/Archived"),
          },
          {
            path: "create",
            name: "module-grogreen-articles-videos-create",
            props: () => ({
              parentRouteName: "module-grogreen-articles-videos",
            }),
            component: () =>
              import("@/modules/grogreen/views/articles/Individual"),
          },
          {
            path: ":articleId",
            name: "module-grogreen-articles-videos-individual",
            props: () => ({
              isEditing: true,
              parentRouteName: "module-grogreen-articles-videos",
            }),
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("grogreen/articles/loadArticle", {
                  appId: to.params.id,
                  articleId: to.params.articleId,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
            component: () =>
              import("@/modules/grogreen/views/articles/Individual"),
          },
        ],
      },
      {
        path: "legal",
        component: () => import("@/modules/grogreen/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("grogreen/articles/loadArticles", {
              appId: to.params.id,
              type: "legal",
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: [
          {
            path: "",
            name: "module-grogreen-articles-legal",
            props: () => ({
              pageTitle: "Legal",
            }),
            component: () =>
              import("@/modules/grogreen/views/articles/Dashboard"),
          },
          {
            path: "categories",
            component: () => import("@/modules/grogreen/views/Template"),
            children: [
              {
                path: "/",
                name: "module-grogreen-articles-legal-categories",
                props: () => ({
                  pageTitle: "Legal",
                }),
                component: () =>
                  import(
                    "@/modules/grogreen/views/articles/categories/Dashboard"
                  ),
              },
              {
                path: "archived",
                name: "module-grogreen-articles-legal-categories-archived",
                props: () => ({
                  pageTitle: "Legal",
                  parentRouteName: "module-grogreen-articles-legal",
                  parentCategoryName:
                    "module-grogreen-articles-legal-categories",
                }),
                component: () =>
                  import(
                    "@/modules/grogreen/views/articles/categories/Archived"
                  ),
              },
            ],
          },
          {
            path: "archived",
            name: "module-grogreen-articles-legal-archived",
            props: () => ({
              pageTitle: "Legal",
              parentRouteName: "module-grogreen-articles-legal",
            }),
            component: () =>
              import("@/modules/grogreen/views/articles/Archived"),
          },
          {
            path: "create",
            name: "module-grogreen-articles-legal-create",
            props: () => ({
              parentRouteName: "module-grogreen-articles-legal",
            }),
            component: () =>
              import("@/modules/grogreen/views/articles/Individual"),
          },
          {
            path: ":articleId",
            name: "module-grogreen-articles-legal-individual",
            props: () => ({
              isEditing: true,
              parentRouteName: "module-grogreen-articles-legal",
            }),
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("grogreen/articles/loadArticle", {
                  appId: to.params.id,
                  articleId: to.params.articleId,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
            component: () =>
              import("@/modules/grogreen/views/articles/Individual"),
          },
        ],
      },
    ],
  },
];
