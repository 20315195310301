import store from "@/stores/";

export default [
  {
    path: "teams",
    component: () => import("@/modules/easylets/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("easylets/teamsStore/loadTeams", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-easylets-teams",
        component: () => import("@/modules/easylets/views/teams/Dashboard"),
      },
      {
        path: "archived",
        name: "module-easylets-teams-archived",
        props: () => ({
          pageTitle: "Teams",
          parentRouteName: "module-easylets-teams",
        }),
        component: () => import("@/modules/easylets/views/teams/Archived"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("easylets/teamsStore/loadTeams", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
    ],
  },
];
