import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  landingpages: [],
  archived: [],
  landingpage: {},
};

// getters
const getters = {
  all: (state) => state.landingpages,
  archived: (state) => state.archived,
  get: (state) => state.landingpage,
};

// actions
const actions = {
  loadLandingPages({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lpb/landingpages`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadLandingPagesSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  restoreLandingPage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lpb/landingpages/${
          data.pageId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreLandingPageSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadLandingPage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lpb/landingpages/${data.pageId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadLandingPageSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  deleteLandingPage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lpb/landingpages/${data.pageId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteLandingPageSuccess", {
            pageId: data.pageId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  saveLandingPage({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lpb/landingpages/${data.pageId}`
      : `${getApiUrl(data.appId)}/module/lpb/landingpages`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveLandingPageSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadLandingPagesSuccess(state, data) {
    state.landingpages = data.pages;
    state.archived = data.archived;
  },

  loadLandingPageSuccess(state, data) {
    state.landingpage = data.page;
  },

  saveLandingPageSuccess(state, data) {
    if (data.isEditing) {
      const index = state.landingpages.findIndex((c) => c.id === data.page.id);
      state.landingpages.splice(index, 1, data.page);
    } else {
      state.landingpages.push(data.page);
    }
  },

  deleteLandingPageSuccess(state, data) {
    const index = state.landingpages.findIndex((p) => p.id === data.pageId);
    state.landingpages.splice(index, 1);
  },

  restoreLandingPageSuccess(state, data) {
    const index = state.archived.findIndex((c) => c.id === data.page.id);
    state.archived.splice(index, 1);
    state.landingpages.push(data.page);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
