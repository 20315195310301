import ApplicationRoutes from "./applications.routes";
import BreedRoutes from "./breeds.routes";
import CustomerRoutes from "./customers.routes";
import DogRoutes from "./dogs.routes";
import LitterRoutes from "./litters.routes";
import OrderRoutes from "./orders.routes";
import ProductRoutes from "./products.routes";
import ResourcesRoutes from "./resources.routes";
import TaskRoutes from "./tasks.routes";
import TestimonialRoutes from "./testimonials.routes";

export default [
  {
    path: "lhl",
    component: () => import("@/modules/lhl/views/Template"),
    children: [
      ...DogRoutes,
      ...LitterRoutes,
      ...BreedRoutes,
      ...ApplicationRoutes,
      ...TestimonialRoutes,
      ...ResourcesRoutes,
      ...CustomerRoutes,
      ...TaskRoutes,
      ...OrderRoutes,
      ...ProductRoutes,
    ],
  },
];
