import axios from "axios";
import timetastic from "./timetastic.store";

// initial state
const state = {
  teamMembers: [],
  teamMember: {},
  teamMemberTypes: [],
  departments: [],
  appRoles: [],
  posts: [],
  unassignedTeamMembers: [],
};

// getters
const getters = {
  all: (state) => state.teamMembers,
  currentUserMemberId: (state, getters, rootState, rootGetters) => {
    const teamMember = state.teamMembers.find(
      (m) => m.user.id === rootGetters.userId
    );

    return teamMember.id;
  },
};

// actions
const actions = {
  loadModule({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("moduleLoadRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/${data.module}/module`,
        method: "GET",
      })
        .then((resp) => {
          const module = resp.data;
          commit("moduleLoadSuccess", { module });
          resolve(resp);
        })
        .catch((err) => {
          commit("moduleLoadError");
          reject(err);
        });
    });
  },
  loadTeamMembers({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/team`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadTeamMembersSuccess", {
            teamMembers: resp.data.teamMembers,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addMember({ commit }, data) {
    commit("addMemberRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/team`,
        data: {
          ...data,
        },
        method: "POST",
      })
        .then((resp) => {
          const teamMembers = resp.data.teamMembers;
          commit("addMemberSuccess", { teamMembers });
          resolve(resp.data);
        })
        .catch((err) => {
          commit("addMemberError");
          reject(err);
        });
    });
  },
  addPost({ commit }, data) {
    commit("addPostRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/team/posts`,
        data: {
          name: data.name,
          department_id: data.department,
        },
        method: "POST",
      })
        .then((resp) => {
          const post = resp.data.post;
          commit("addPostSuccess", { post });
          resolve(resp.data);
        })
        .catch((err) => {
          commit("addPostError");
          reject(err);
        });
    });
  },
  addDepartment({ commit }, data) {
    commit("addDepartmentRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/team/organisation`,
        data: {
          name: data.name,
          parent_department: data.parent_department,
        },
        method: "POST",
      })
        .then((resp) => {
          const department = resp.data.department;
          commit("addDepartmentSuccess", { department });
          resolve(resp.data);
        })
        .catch((err) => {
          commit("addDepartmentError");
          reject(err);
        });
    });
  },
  editDepartment({ commit }, data) {
    commit("editDepartmentRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/team/organisation/${data.departmentId}`,
        data: {
          name: data.name,
          parent_department: data.parent_department,
          id: data.departmentId,
        },
        method: "POST",
      })
        .then((response) => {
          const department = response.data.department;
          commit("editDepartmentSuccess", { department });
          resolve();
        })
        .catch((error) => {
          commit("editDepartmentError");
          reject(error);
        });
    });
  },
  editPost({ commit }, data) {
    commit("editPostRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/team/posts/${data.postId}`,
        data: {
          name: data.name,
          department_id: data.department_id,
        },
        method: "POST",
      })
        .then((response) => {
          const post = response.data.post;
          const original_department_id = response.data.original_department_id;
          commit("editPostSuccess", { post, original_department_id });
          resolve();
        })
        .catch((error) => {
          commit("editPostError");
          reject(error);
        });
    });
  },
  addPosting({ commit }, data) {
    commit("addPostingRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/team/posts/${data.postId}/postings`,
        data: {
          ...data.fields,
          post_id: data.postId,
        },
        method: "POST",
      })
        .then((resp) => {
          const posting = resp.data.posting;
          commit("addPostingSuccess", { posting });
          resolve(resp.data);
        })
        .catch((err) => {
          commit("addPostingError");
          reject(err);
        });
    });
  },
  editPosting({ commit }, data) {
    commit("editPostingRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/team/posts/${data.postId}/postings/${data.postingId}`,
        data: {
          ...data.fields,
          team_member_id: data.team_member_id,
        },
        method: "POST",
      })
        .then((response) => {
          const posting = response.data.posting;
          commit("editPostingSuccess", { posting });
          resolve();
        })
        .catch((err) => {
          commit("editPostingError");
          reject(err);
        });
    });
  },
  archivePost({ commit }, data) {
    commit("archivePostRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/team/posts/${data.id}/archive`,
        data: {
          id: data.id,
        },
        method: "POST",
      })
        .then((resp) => {
          const post = resp.data.post;
          commit("archivePostSuccess", { post, id: data.id });
          resolve();
        })
        .catch((err) => {
          commit("archivePostError");
          reject(err);
        });
    });
  },
  loadDepartments({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("moduleLoadDepartmentRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/team/organisation`,
        method: "GET",
      })
        .then((resp) => {
          const departments = resp.data.departments;
          const unassignedTeamMembers = resp.data.unassignedTeamMembers;

          commit("moduleLoadDepartmentSuccess", {
            departments,
            unassignedTeamMembers,
          });
          resolve(resp);
        })
        .catch((err) => {
          commit("moduleLoadDepartmentError");
          reject(err);
        });
    });
  },
  loadTeamMember({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("loadTeamMemberRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/team/${data.teamMemberId}`,
        method: "GET",
      })
        .then((resp) => {
          const teamMember = resp.data.teamMember;
          commit("loadTeamMemberSuccess", { teamMember });
          resolve();
        })
        .catch(() => {
          commit("loadTeamMemberError");
          reject();
        });
    });
  },
  updateStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/team/${data.teamMemberId}/user/status`,
        data: {
          status: data.status,
        },
        method: "POST",
      })
        .then((resp) => {
          const teamMember = resp.data.teamMember;
          commit("updateStatusSuccess", { teamMember });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateContactInformation({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/team/${data.teamMemberId}/contact-information`,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("updateContactInformationSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  updateEmergencyContacts({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/team/${data.teamMemberId}/emergency-contacts`,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("updateEmergencyContactsSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  moduleLoadRequest() {},
  moduleLoadSuccess(state, data) {
    state.teamMembers = data.module.teamMembers;
    state.teamMemberTypes = data.module.teamMemberTypes;
    state.departments = data.module.departments;
    state.appRoles = data.module.roles;
  },
  moduleLoadError() {},

  loadTeamMembersSuccess(state, data) {
    state.teamMembers = data.teamMembers;
  },

  moduleLoadDepartmentRequest() {},
  moduleLoadDepartmentSuccess(state, data) {
    state.departments = data.departments;
    state.unassignedTeamMembers = data.unassignedTeamMembers;
  },
  moduleLoadDepartmentError() {},

  addMemberRequest() {},
  addMemberSuccess(state, data) {
    state.teamMembers = data.teamMembers;
  },
  addMemberError() {},
  addDepartmentRequest() {},
  addDepartmentSuccess(state, data) {
    state.departments.push(data.department);
  },
  addDepartmentError() {},

  addPostRequest() {},
  addPostSuccess(state, data) {
    const indexForDepartment = state.departments.findIndex(
      (c) => c.id === data.post.department_id
    );
    state.departments[indexForDepartment].posts.push(data.post);
  },
  addPostError() {},

  archivePostRequest() {},
  archivePostSuccess(state, data) {
    const indexForDepartment = state.departments.findIndex(
      (c) => c.id === data.post.department_id
    );
    const indexForPost = state.departments[indexForDepartment].posts.findIndex(
      (c) => c.id === data.post.id
    );

    state.departments[indexForDepartment].posts.splice(indexForPost, 1);
  },
  archivePostError() {},

  addPostingRequest() {},
  addPostingSuccess(state, data) {
    const indexForDepartment = state.departments.findIndex(
      (c) => c.id === data.posting.post.department_id
    );
    const indexForPost = state.departments[indexForDepartment].posts.findIndex(
      (c) => c.id === data.posting.post_id
    );
    state.departments[indexForDepartment].posts[indexForPost].postings.push(
      data.posting
    );
    const indexForTeamMember = state.teamMembers.findIndex(
      (c) => c.id === data.posting.team_member_id
    );
    state.teamMembers[indexForTeamMember].postings.push(data.posting);
    const indexForUnassignedMember = state.unassignedTeamMembers.findIndex(
      (c) => c.id === data.posting.team_member_id
    );
    state.unassignedTeamMembers.splice(indexForUnassignedMember, 1);
  },
  addPostingError() {},

  editDepartmentRequest() {},
  editDepartmentSuccess(state, data) {
    const index = state.departments.findIndex(
      (c) => c.id === data.department.id
    );
    const department = data.department;

    state.departments[index].name = department.name;
    state.departments[index].parent_department = department.parent_department;
  },
  editDepartmentError() {},
  editPostRequest() {},
  editPostSuccess(state, data) {
    const indexForDepartment = state.departments.findIndex(
      (c) => c.id === data.post.department_id
    );

    if (data.original_department_id === data.post.department_id) {
      const indexForPost = state.departments[
        indexForDepartment
      ].posts.findIndex((c) => c.id === data.post.id);
      state.departments[indexForDepartment].posts.splice(
        indexForPost,
        1,
        data.post
      );
    } else {
      const indexForOriginalDepartment = state.departments.findIndex(
        (c) => c.id === data.original_department_id
      );

      const indexForPost = state.departments[
        indexForOriginalDepartment
      ].posts.findIndex((c) => c.id === data.post.id);
      state.departments[indexForOriginalDepartment].posts.splice(
        indexForPost,
        1
      );
      state.departments[indexForDepartment].posts.push(data.post);
    }
  },
  editPostError() {},
  editPostingRequest() {},
  editPostingSuccess(state, data) {
    const indexForDepartment = state.departments.findIndex(
      (c) => c.id === data.posting.post.department_id
    );
    const indexForPost = state.departments[indexForDepartment].posts.findIndex(
      (p) => p.id === data.posting.post.id
    );
    const indexForPosting = state.departments[indexForDepartment].posts[
      indexForPost
    ].postings.findIndex((c) => c.id === data.posting.id);

    if (!data.posting.end_date) {
      state.departments[indexForDepartment].posts[indexForPost].postings.splice(
        indexForPosting,
        1,
        data.posting
      );
    } else {
      state.departments[indexForDepartment].posts[indexForPost].postings.splice(
        indexForPosting,
        1
      );
      const indexForTeamMember = state.teamMembers.findIndex(
        (c) => c.id === data.posting.team_member_id
      );
      state.teamMembers[indexForTeamMember].postings.splice(indexForPosting, 1);
    }
  },
  editPostingError() {},
  loadTeamMemberRequest() {},
  loadTeamMemberSuccess(state, data) {
    state.teamMember = data.teamMember;
  },
  loadTeamMemberError() {},
  updateStatusSuccess(state, data) {
    const index = state.teamMembers.findIndex(
      (m) => m.id === data.teamMember.id
    );
    state.teamMembers[index].user.apps[0].pivot.status =
      data.teamMember.user.apps[0].pivot.status;
  },
  updateTimetasticUserId(state, data) {
    const index = state.teamMembers.findIndex(
      (m) => m.id === parseInt(data.userId)
    );
    state.teamMembers[index].timetastic_user_id = parseInt(
      data.timetasticUserId
    );
    if (parseInt(data.userId) === parseInt(state.teamMember.id)) {
      state.teamMember.timetastic_user_id = parseInt(data.timetasticUserId);
    }
  },
  updateContactInformationSuccess(state, data) {
    state.teamMember = data.teamMember;
  },
  updateEmergencyContactsSuccess(state, data) {
    state.teamMember = data.teamMember;
  },
};

// modules
const modules = {
  timetastic,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
