import axios from "axios";
import { getApiUrl } from "@/utils";

// actions
const actions = {
  decrypt(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/decrypter`,
        method: "POST",
        data: data.fields,
      })
        .then(resp => resolve(resp.data))
        .catch(err => reject(err));
    });
  },
};

export default {
  namespaced: true,
  actions,
};
