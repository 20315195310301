import enquiries from "./enquiries.store";
import landingpages from "./landingpages.store";

// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

// modules
const modules = {
  landingpages,
  enquiries,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
