// import axios from "axios";

import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  solicitors: [],
  archived: [],
  solicitor: {},
};

// getters
const getters = {
  all: (state) => state.solicitors,
  archived: (state) => state.archived,
  get: (state) => state.solicitor,
};

// actions
const actions = {
  loadSolicitors({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/solicitors`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadSolicitorsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadSolicitor({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/solicitors/${
          data.slicitorId
        }`,
        method: "GET",
      })
        .then((resp) => {
          const solicitor = resp.data.solicitor;
          commit("loadSolicitorSuccess", { solicitor });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteSolicitor({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/solicitors/${
          data.solicitorId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteSolicitorSuccess", {
            solicitorId: data.solicitorId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  restoreSolicitor({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/solicitors/${
          data.solicitorId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreSolicitorSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveSolicitor({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/melrose/solicitors/${data.solicitorId}`
      : `${getApiUrl(data.appId)}/module/melrose/solicitors`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveSolicitorSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadSolicitorsSuccess(state, data) {
    state.solicitors = data.solicitors;
    state.archived = data.archived;
  },
  loadSolicitorSuccess(state, data) {
    state.solicitor = data.solicitor;
  },
  deleteSolicitorSuccess(state, data) {
    const index = state.solicitors.findIndex((c) => c.id === data.solicitorId);
    state.solicitors.splice(index, 1);
  },
  restoreSolicitorSuccess(state, data) {
    const index = state.archived.findIndex((c) => c.id === data.solicitor.id);
    state.archived.splice(index, 1);
    state.solicitors.push(data.solicitor);
  },
  saveSolicitorSuccess(state, data) {
    if (data.isEditing) {
      const index = state.solicitors.findIndex(
        (p) => p.id === data.solicitor.id
      );
      state.solicitors.splice(index, 1, data.solicitor);
    } else {
      state.solicitors.push(data.solicitor);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
