import store from "@/stores/";

export default [
  {
    path: "area-guides",
    component: () => import("@/modules/melrose/views/Template"),
    children: [
      {
        path: "",
        name: "module-melrose-areaguides",
        component: () =>
          import("@/modules/melrose/views/area-guides/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("melrose/areaGuidesStore/loadAreaGuides", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "archived",
        name: "module-melrose-areaguides-archived",
        props: () => ({
          pageTitle: "Area Guides",
          parentRouteName: "module-melrose-areaguides",
        }),
        component: () => import("@/modules/melrose/views/area-guides/Archived"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("melrose/areaGuidesStore/loadAreaGuides", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
    ],
  },
];
