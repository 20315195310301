import axios from "axios";
import { getApiUrl } from "@/utils";

const state = {
  diaries: [],
  diary: {},
};

// getters
const getters = {
  all: (state) => state.diaries,
  get: (state) => state.diary,
};

// actions
const actions = {
  loadDiaries({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/diaries/${data.type}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadDiariesSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadDiary({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/diaries/${data.diaryId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadDiariesuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveItem({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/sbpm/diaries/${data.diaryId}/items/${
          data.itemId
        }`
      : `${getApiUrl(data.appId)}/module/sbpm/diaries/${data.diaryId}/items`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveItemSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveDiary({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/sbpm/diaries/${data.diaryId}`
      : `${getApiUrl(data.appId)}/module/sbpm/diaries`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveDiariesuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteDiary({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/diaries/${data.diaryId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteDiarySuccess", {
            diaryId: data.diaryId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadDiariesSuccess(state, data) {
    state.diaries = data.diaries;
  },

  loadDiariesuccess(state, data) {
    state.diary = data.diary;
  },

  saveDiariesuccess(state, data) {
    if (data.isEditing) {
      const index = state.diaries.findIndex((p) => p.id === data.diary.id);
      state.diaries.splice(index, 1, data.diary);
    } else {
      state.diaries.push(data.diary);
    }
  },

  deleteDiarySuccess(state, data) {
    const index = state.diaries.findIndex((c) => c.id === data.diaryId);
    state.diaries.splice(index, 1);
  },

  deleteItemSuccess(state, data) {
    const index = state.diary.items.findIndex((c) => c.id === data.itemId);
    state.diary.items.splice(index, 1);
  },

  saveItemSuccess(state, data) {
    if (data.isEditing) {
      const index = state.diary.items.findIndex((p) => p.id === data.item.id);
      state.diary.items.splice(index, 1, data.item);
    } else {
      state.diary.items.push(data.item);
    }
  },
  saveDiaryVisibleSuccess(state, data) {
    const index = state.diaries.findIndex((p) => p.id === data.diary.id);
    state.diaries.splice(index, 1, data.diary);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
