// import store from "@/store";

export default [
  {
    path: "news",
    component: () => import("@/modules/craigtoun/views/Template"),
    children: [
      {
        path: "",
        name: "module-craigtoun-news",
        component: () => import("@/modules/craigtoun/views/news/Dashboard"),
      },
      {
        path: "categories",
        component: () => import("@/modules/craigtoun/views/Template"),
        children: [
          {
            path: "/",
            name: "module-craigtoun-news-categories",
            component: () =>
              import("@/modules/craigtoun/views/news/categories/Dashboard"),
          },
        ],
      },
    ],
  },
];
