import deliverySettingsStore from "./delivery-settings.store";
import couponCodesStore from "./coupon-codes.store";

// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

// modules
const modules = {
  deliverySettingsStore,
  couponCodesStore,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
