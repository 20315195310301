import axios from "axios";
import { getApiUrl } from "@/utils";

const state = {
  enquiries: [],
  enquiry: {},
};

// getters
const getters = {
  all: (state) => state.enquiries,
  get: (state) => state.enquiry,
};

// actions
const actions = {
  loadEnquiries({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/enquiries`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadEnquiriesSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadEnquiry({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/enquiries/${data.enquiryId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadEnquirySuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveNote({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/sbpm/notes/${
      data.modelId
    }/note`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveNoteSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadEnquiriesSuccess(state, data) {
    state.enquiries = data.enquiries;
  },

  loadEnquiry(state, data) {
    state.enquiry = data.enquiry;
  },

  saveEnquiriesuccess(state, data) {
    if (data.isEditing) {
      const index = state.enquiries.findIndex((p) => p.id === data.enquiry.id);
      state.enquiries.splice(index, 1, data.enquiry);
    } else {
      state.enquiries.push(data.enquiry);
    }
  },

  deleteEnquirySuccess(state, data) {
    const index = state.enquiries.findIndex((c) => c.id === data.enquiryId);
    state.enquiries.splice(index, 1);
  },

  deleteItemSuccess(state, data) {
    const index = state.enquiry.items.findIndex((c) => c.id === data.itemId);
    state.enquiry.items.splice(index, 1);
  },

  saveItemSuccess(state, data) {
    if (data.isEditing) {
      const index = state.enquiry.items.findIndex((p) => p.id === data.item.id);
      state.enquiry.items.splice(index, 1, data.item);
    } else {
      state.enquiry.items.push(data.item);
    }
  },
  saveEnquiryVisibleSuccess(state, data) {
    const index = state.enquiries.findIndex((p) => p.id === data.enquiry.id);
    state.enquiries.splice(index, 1, data.enquiry);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
