import articles from "./articles.store";
import customers from "./customers.store";
import orders from "./orders.store";
import products from "./products.store";
import species from "./species.store";
import testimonials from "./testimonials.store";

// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

// modules
const modules = {
  products,
  species,
  orders,
  testimonials,
  customers,
  articles,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
