import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  coupons: [],
  archived: [],
};

// getters
const getters = {
  allCoupons: (state) => state.coupons,
  allArchivedCoupons: (state) => state.archived,
};

// actions
const actions = {
  loadCoupons({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/ecommerce/coupon-codes`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadCouponsSuccess", resp.data);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveCoupon({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/ecommerce/coupon-codes/${
          data.couponId
        }`
      : `${getApiUrl(data.appId)}/module/ecommerce/coupon-codes`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveCouponSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteCoupon({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/ecommerce/coupon-codes/${
          data.couponId
        }`,
        method: "DELETE",
      })
        .then((resp) => {
          commit("deleteCouponSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  restoreCoupon({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/ecommerce/coupon-codes/${
          data.couponId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreCouponSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadCouponsSuccess(state, data) {
    state.coupons = data.coupons;
    state.archived = data.archived;
  },
  deleteCouponSuccess(state, data) {
    const index = state.coupons.findIndex((c) => c.id === data.coupon.id);
    state.coupons.splice(index, 1);
    state.archived.push(data.coupon);
  },
  restoreCouponSuccess(state, data) {
    const index = state.archived.findIndex((c) => c.id === data.coupon.id);
    state.archived.splice(index, 1);
    state.coupons.push(data.coupon);
  },
  saveCouponSuccess(state, data) {
    if (data.isEditing) {
      const index = state.coupons.findIndex((c) => c.id === data.coupon.id);
      state.coupons.splice(index, 1, data.coupon);
    } else {
      state.coupons.push(data.coupon);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
