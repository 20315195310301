import store from "@/stores/";

export default [
  {
    path: "news",
    component: () => import("@/modules/news/views/Template"),
    meta: { hasPermission: "view-module-news" },
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("news/loadNewsModule", {
          appId: to.params.id,
        }),
        store.dispatch("news/loadNewsCategories", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-news",
        component: () => import("@/modules/news/views/Dashboard"),
      },
      {
        path: "categories",
        component: () => import("@/modules/news/views/Template"),
        children: [
          {
            path: "",
            name: "module-news-categories",
            props: () => ({
              parentRouteName: "module-news",
            }),
            component: () => import("@/modules/news/views/Categories"),
          },
          {
            path: "",
            name: "module-news-categories-archived",
            props: () => ({
              parentRouteName: "module-news-categories",
            }),
            component: () => import("@/modules/news/views/ArchivedCategories"),
          },
        ],
      },
      {
        path: "archived",
        name: "module-news-archived",
        props: () => ({
          parentRouteName: "module-news",
        }),
        component: () => import("@/modules/news/views/Archived"),
      },
      {
        path: "create",
        name: "module-news-create",
        props: () => ({
          parentRouteName: "module-news",
        }),
        component: () => import("@/modules/news/views/Article"),
      },
      {
        path: ":articleId",
        name: "module-news-individual",
        props: () => ({
          isEditing: true,
          parentRouteName: "module-news",
        }),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("news/loadArticle", {
              appId: to.params.id,
              articleId: to.params.articleId,
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
        component: () => import("@/modules/news/views/Article"),
      },
    ],
  },
];
