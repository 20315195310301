import store from "@/stores/";

export default [
  {
    path: "",
    name: "module-paw-orders",
    component: () => import("@/modules/paw/views/orders/Dashboard"),
  },
  {
    path: ":orderId",
    name: "module-paw-orders-individual",
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("paw/loadOrder", {
          appId: to.params.id,
          orderId: to.params.orderId,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    component: () => import("@/modules/paw/views/orders/Individual"),
  },
];
