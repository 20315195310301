import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  breeds: [],
  markings: [],
  colors: [],
  breed: {},
};

// getters
const getters = {
  all: (state) => state.breeds,
  get: (state) => state.breed,
  colors: (state) => state.colors,
  markings: (state) => state.markings,
};

// actions
const actions = {
  loadBreeds({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/breeds`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadBreedsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  loadBreed({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/breeds/${data.breedId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadBreedSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  deleteSize({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/breeds/${data.breedId}/size/${
          data.sizeId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteSizeSuccess", { sizeId: data.sizeId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  deleteBreed({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/breeds/${data.breedId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteBreedSuccess", { breedId: data.breedId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  deleteMarking({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/breeds/${
          data.breedId
        }/marking/${data.markingId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteMarkingSuccess", { markingId: data.markingId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  deleteCoatType({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/breeds/${
          data.breedId
        }/coat-type/${data.coatTypeId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteCoatTypeSuccess", { coatTypeId: data.coatTypeId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  deleteColor({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/breeds/${
          data.breedId
        }/color/${data.colorId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteColorSuccess", { colorId: data.colorId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  saveBreed({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/breeds/${data.breedId}`
      : `${getApiUrl(data.appId)}/module/lhl/breeds`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else if (field === "breeding_resting_sequence") {
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(
            `breeding_resting_sequence[${i}][title]`,
            data[i]["title"]
          );
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveBreedSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveCoatType({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/breeds/${data.breedId}/coat-type/${
          data.coatTypeId
        }`
      : `${getApiUrl(data.appId)}/module/lhl/breeds/${data.breedId}/coat-type`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveCoatTypeSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveMarking({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/breeds/${data.breedId}/marking/${
          data.markingId
        }`
      : `${getApiUrl(data.appId)}/module/lhl/breeds/${data.breedId}/marking`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveMarkingSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveSize({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/breeds/${data.breedId}/size/${
          data.sizeId
        }`
      : `${getApiUrl(data.appId)}/module/lhl/breeds/${data.breedId}/size`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveSizeSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveOutcome({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/breeds/${
      data.breedId
    }/outcome`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else if (field === "outcomes") {
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(`outcomes[${i}][breed_2]`, data[i]["breed_2"]);

          if (data[i]["breed_outcome"] === null) {
            formData.append(`outcomes[${i}][breed_outcome]`, "");
          } else {
            formData.append(
              `outcomes[${i}][breed_outcome]`,
              data[i]["breed_outcome"]
            );
          }
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveOutcomeSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveColor({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/breeds/${data.breedId}/color/${
          data.colorId
        }`
      : `${getApiUrl(data.appId)}/module/lhl/breeds/${data.breedId}/color`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveColorSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadBreedsSuccess(state, data) {
    state.breeds = data.breeds;
    state.colors = data.colors;
    state.markings = data.markings;
  },
  loadBreedSuccess(state, data) {
    state.breed = data.breed;
  },

  saveBreedSuccess(state, data) {
    if (data.isEditing) {
      const index = state.breeds.findIndex((c) => c.id === data.breed.id);
      state.breeds.splice(index, 1, data.breed);
      state.breed = data.breed;
    } else {
      state.breeds.push(data.breed);
    }
  },

  saveColorSuccess(state, data) {
    if (data.isEditing) {
      const index = state.breed.colors.findIndex((p) => p.id === data.color.id);
      state.breed.colors.splice(index, 1, data.color);
    } else {
      state.breed.colors.push(data.color);
    }
  },

  saveSizeSuccess(state, data) {
    if (data.isEditing) {
      const index = state.breed.sizes.findIndex((p) => p.id === data.size.id);
      state.breed.sizes.splice(index, 1, data.size);
    } else {
      state.breed.sizes.push(data.size);
    }
  },

  saveMarkingSuccess(state, data) {
    if (data.isEditing) {
      const index = state.breed.markings.findIndex(
        (p) => p.id === data.marking.id
      );
      state.breed.markings.splice(index, 1, data.marking);
    } else {
      state.breed.markings.push(data.marking);
    }
  },

  saveCoatTypeSuccess(state, data) {
    if (data.isEditing) {
      const index = state.breed.coat_types.findIndex(
        (p) => p.id === data.coatType.id
      );
      state.breed.coat_types.splice(index, 1, data.coatType);
    } else {
      state.breed.coat_types.push(data.coatType);
    }
  },

  saveOutcomeSuccess(state, data) {
    state.breed = data.breed;
  },

  deleteBreedSuccess(state, data) {
    const index = state.breeds.findIndex((p) => p.id === data.breedId);
    state.breeds.splice(index, 1);
  },

  deleteSizeSuccess(state, data) {
    const index = state.breed.sizes.findIndex((p) => p.id === data.sizeId);
    state.breed.sizes.splice(index, 1);
  },

  deleteMarkingSuccess(state, data) {
    const index = state.breed.markings.findIndex(
      (p) => p.id === data.markingId
    );
    state.breed.markings.splice(index, 1);
  },

  deleteColorSuccess(state, data) {
    const index = state.breed.colors.findIndex((p) => p.id === data.colorId);
    state.breed.colors.splice(index, 1);
  },

  deleteCoatTypeSuccess(state, data) {
    const index = state.breed.coat_types.findIndex(
      (p) => p.id === data.coatTypeId
    );
    state.breed.coat_types.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
