import store from "@/stores/";

export default [
  {
    path: "products",
    component: () => import("@/modules/flightmode/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      store
        .dispatch("flightmode/products/loadProducts", {
          appId: to.params.id,
        })
        .then(() => {
          store.commit("showHideLoader", false);
          next();
        });
    },
    children: [
      {
        path: "",
        name: "module-flightmode-products",
        component: () =>
          import("@/modules/flightmode/views/products/Dashboard"),
      },
      {
        path: "archived",
        name: "module-flightmode-products-archived",
        component: () => import("@/modules/flightmode/views/products/Archived"),
      },
      {
        path: "categories",
        component: () => import("@/modules/flightmode/views/Template"),
        children: [
          {
            path: "/",
            name: "module-flightmode-products-categories",
            component: () =>
              import("@/modules/flightmode/views/products/Categories"),
          },
          {
            path: "archived",
            name: "module-flightmode-products-categories-archived",
            component: () =>
              import("@/modules/flightmode/views/products/ArchivedCategories"),
          },
        ],
      },
    ],
  },
];
