import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  applications: [],
  fch_applications: [],
  archived: [],
  fch_archived: [],
  application: {},
  fch_application: {},
};

// getters
const getters = {
  all: (state) => state.applications,
  fch: (state) => state.fch_applications,
  archived: (state) => state.archived,
  fch_archived: (state) => state.fch_archived,
  get: (state) => state.application,
  getFch: (state) => state.fch_application,
};

// actions
const actions = {
  loadApplications({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/applications/puppy`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadApplicationsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  loadFchApplications({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/applications/fch`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadFchApplicationsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  loadApplication({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/applications/puppy/${
          data.applicationId
        }`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadApplicationSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  loadFchApplication({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/applications/fch/${
          data.applicationId
        }`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadFchApplicationSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  deleteApplication({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/applications/puppy/${
          data.applicationId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteApplicationSuccess", {
            applicationId: data.applicationId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  deleteApplicationFch({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/applications/fch/${
          data.applicationId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteApplicationFchSuccess", {
            applicationId: data.applicationId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  restoreApplication({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/applications/puppy/${
          data.applicationId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreApplicationSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  restoreApplicationFch({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/applications/fch/${
          data.applicationId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreApplicationFchSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  checkCustomerExist({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/applications/puppy/${
      data.applicationId
    }/check-customer-exist`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("checkCustomerExistSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  exisingContact({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/applications/puppy/${
      data.applicationId
    }/exising-contact`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveExisingContactSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  newContact({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/applications/puppy/${
      data.applicationId
    }/new-contact`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveNewContactSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  exisingFchContact({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/applications/fch/${
      data.applicationId
    }/exising-contact`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveExisingFchContactSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  newFchContact({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/applications/fch/${
      data.applicationId
    }/new-contact`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveNewFchContactSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveApplicationPuppy({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/applications/puppy/${
      data.applicationId
    }`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else if (typeof fields[field] === "object") {
        if (field == "dogs") {
          formData.append(field, fields[field]);
        } else {
          formData.append(field, fields[field]["id"]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveApplicationSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveApplicationFch({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/applications/fch/${
      data.applicationId
    }`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else if (typeof fields[field] === "object") {
        if (field == "dogs") {
          formData.append(field, fields[field]);
        } else {
          formData.append(field, fields[field]["id"]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveApplicationFchSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadApplicationsSuccess(state, data) {
    state.applications = data.applications;
    state.archived = data.archived;
  },

  loadFchApplicationsSuccess(state, data) {
    state.fch_applications = data.applications;
    state.fch_archived = data.archived;
  },

  loadApplicationSuccess(state, data) {
    state.application = data.application;
  },

  loadFchApplicationSuccess(state, data) {
    state.fch_application = data.application;
  },

  deleteApplicationSuccess(state, data) {
    const index = state.applications.findIndex(
      (p) => p.id === data.applicationId
    );
    state.applications.splice(index, 1);
  },

  deleteApplicationFchSuccess(state, data) {
    const index = state.fch_applications.findIndex(
      (p) => p.id === data.applicationId
    );
    state.fch_applications.splice(index, 1);
  },

  restoreApplicationSuccess(state, data) {
    const index = state.archived.findIndex((c) => c.id === data.application.id);
    state.archived.splice(index, 1);
    state.applications.push(data.application);
  },

  restoreApplicationFchSuccess(state, data) {
    const index = state.fch_archived.findIndex(
      (c) => c.id === data.application.id
    );
    state.fch_archived.splice(index, 1);
    state.fch_applications.push(data.application);
  },

  saveNewContactSuccess(state, data) {
    state.application = data.application;
  },

  saveExisingContactSuccess(state, data) {
    state.application = data.application;
  },

  saveNewFchContactSuccess(state, data) {
    state.fch_application = data.application;
  },

  saveExisingFchContactSuccess(state, data) {
    state.fch_application = data.application;
  },

  checkCustomerExistSuccess() {},

  saveApplicationSuccess(state, data) {
    const index = state.applications.findIndex(
      (o) => o.id === data.application.id
    );
    state.applications.splice(index, 1, data.application);
    state.application = data.application;
  },

  saveApplicationFchSuccess(state, data) {
    const index = state.fch_applications.findIndex(
      (o) => o.id === data.application.id
    );
    state.fch_applications.splice(index, 1, data.application);
    state.fch_application = data.application;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
