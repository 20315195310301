import attractionsStore from "./attractions.store";
import bookingsStore from "./bookings.store";
import customersStore from "./customers.store";
import faqsStore from "./faqs.store";
import ordersStore from "./orders.store";
import podsStore from "./pods.store";
import poolStore from "./pool.store";
import settingsStore from "./settings.store";
import vouchersStore from "./vouchers.store";

// import axios from "axios";

// default state
const getDefaultState = () => {
  return {};
};

// initial state
const state = getDefaultState();

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

// modules
const modules = {
  bookingsStore,
  customersStore,
  attractionsStore,
  faqsStore,
  ordersStore,
  vouchersStore,
  podsStore,
  poolStore,
  settingsStore,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
