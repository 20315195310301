import store from "@/stores/";

export default [
  {
    path: "monthly-templates",
    component: () => import("@/modules/flightmode/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      store
        .dispatch("flightmode/monthlyTemplates/loadTemplates", {
          appId: to.params.id,
        })
        .then(() => {
          store.commit("showHideLoader", false);
          next();
        });
    },
    children: [
      {
        path: "",
        name: "module-flightmode-monthlytemplates",
        component: () =>
          import("@/modules/flightmode/views/monthly-templates/Dashboard"),
      },
      {
        path: "create",
        name: "module-flightmode-monthlytemplates-create",
        component: () =>
          import("@/modules/flightmode/views/monthly-templates/TemplateForm"),
      },
      {
        path: "previous",
        name: "module-flightmode-monthlytemplates-previous",
        component: () =>
          import("@/modules/flightmode/views/monthly-templates/Previous"),
      },
      {
        path: ":templateId",
        component: () => import("@/modules/flightmode/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("flightmode/monthlyTemplates/loadTemplate", {
              appId: to.params.id,
              templateId: parseInt(to.params.templateId),
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
        children: [
          {
            path: "edit",
            name: "module-flightmode-monthlytemplates-edit",
            props: () => ({
              isEditing: true,
            }),
            component: () =>
              import(
                "@/modules/flightmode/views/monthly-templates/TemplateForm"
              ),
          },
        ],
      },
    ],
  },
];
