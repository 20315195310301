import ArticleRoutes from "./articles.routes";
import CustomerRoutes from "./customers.routes";
import EnquiriesRoutes from "./enquiries.routes";
import OrderRoutes from "./orders.routes";
import ProductRoutes from "./products.routes";
import SpeciesRoutes from "./species.routes";
import TestimonialsRoutes from "./testimonials.routes";
import SettingsRoutes from "./settings.routes";

export default [
  {
    path: "grogreen",
    component: () => import("@/modules/grogreen/views/Template"),
    children: [
      ...ProductRoutes,
      ...SpeciesRoutes,
      ...OrderRoutes,
      ...TestimonialsRoutes,
      ...CustomerRoutes,
      ...ArticleRoutes,
      ...EnquiriesRoutes,
      ...SettingsRoutes,
    ],
  },
];
