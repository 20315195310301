import store from "@/stores/";

export default [
  {
    path: "coupon-codes",
    component: () => import("@/modules/ecommerce/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      store
        .dispatch("ecommerce/couponCodesStore/loadCoupons", {
          appId: to.params.id,
        })
        .then(() => {
          store.commit("showHideLoader", false);
          next();
        });
    },
    children: [
      {
        path: "",
        name: "module-ecommerce-ecommerce-coupon-codes",
        component: () =>
          import("@/modules/ecommerce/views/coupon-codes/Dashboard"),
      },
      {
        path: "archived",
        name: "module-ecommerce-ecommerce-coupon-codes-archived",
        component: () =>
          import("@/modules/ecommerce/views/coupon-codes/Archived"),
      },
    ],
  },
];
