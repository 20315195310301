import store from "@/stores/";

export default [
  {
    path: "properties",
    component: () => import("@/modules/melrose/views/Template"),
    children: [
      {
        path: "",
        name: "module-melrose-properties",
        beforeEnter: (to, from, next) => {
          next({ name: "app-dashboard" });
        },
      },
      {
        path: "archived",
        name: "module-melrose-properties-all-archived",
        props: () => ({
          pageTitle: "Properties",
          parentRouteName: "module-melrose-properties-all",
        }),
        component: () => import("@/modules/melrose/views/properties/Archived"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("melrose/propertiesStore/loadProperties", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "all",
        name: "module-melrose-properties-all",
        component: () => import("@/modules/melrose/views/properties/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("melrose/propertiesStore/loadProperties", {
              appId: to.params.id,
            }),
            store.dispatch("melrose/customersStore/loadCustomers", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "notes-of-interest",
        component: () => import("@/modules/melrose/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("melrose/propertiesStore/loadNoteOfInterest", {
              appId: to.params.id,
            }),
            store.dispatch("melrose/customersStore/loadCustomers", {
              appId: to.params.id,
            }),
            store.dispatch("melrose/propertiesStore/loadProperties", {
              appId: to.params.id,
            }),
            store.dispatch("melrose/solicitorsStore/loadSolicitors", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: [
          {
            path: "",
            name: "module-melrose-properties-notes-of-interest",
            component: () =>
              import(
                "@/modules/melrose/views/properties/notes-of-interest/Dashboard"
              ),
          },
          {
            path: "archived",
            name: "module-melrose-properties-notes-of-interest-archived",
            component: () =>
              import(
                "@/modules/melrose/views/properties/notes-of-interest/Archived"
              ),
          },
        ],
      },
      {
        path: "property-matching",
        component: () => import("@/modules/melrose/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("contactForms/loadSubmissions", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: [
          {
            path: "",
            name: "module-melrose-properties-property-matching",
            component: () =>
              import(
                "@/modules/melrose/views/properties/property-matching/Dashboard"
              ),
          },
          {
            path: "archived",
            name: "module-melrose-properties-property-matching-archived",
            component: () =>
              import(
                "@/modules/melrose/views/properties/property-matching/Archived"
              ),
          },
        ],
      },

      {
        path: "offers",
        component: () => import("@/modules/melrose/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("melrose/propertiesStore/loadOffers", {
              appId: to.params.id,
            }),
            store.dispatch("melrose/customersStore/loadCustomers", {
              appId: to.params.id,
            }),
            store.dispatch("melrose/propertiesStore/loadProperties", {
              appId: to.params.id,
            }),
            store.dispatch("melrose/solicitorsStore/loadSolicitors", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: [
          {
            path: "",
            name: "module-melrose-properties-offers",
            component: () =>
              import("@/modules/melrose/views/properties/offers/Dashboard"),
          },
          {
            path: "archived",
            name: "module-melrose-properties-offers-archived",
            component: () =>
              import("@/modules/melrose/views/properties/offers/Archived"),
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("melrose/propertiesStore/loadOffers", {
                  appId: to.params.id,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
          },
        ],
      },

      {
        path: "valuation-requests",
        component: () => import("@/modules/melrose/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("melrose/propertiesStore/loadValuationRequests", {
              appId: to.params.id,
            }),
            store.dispatch("melrose/customersStore/loadCustomers", {
              appId: to.params.id,
            }),
            store.dispatch("melrose/propertiesStore/loadProperties", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: [
          {
            path: "",
            name: "module-melrose-properties-valuation-requests",
            component: () =>
              import(
                "@/modules/melrose/views/properties/valuation-requests/Dashboard"
              ),
          },
          {
            path: "archived",
            name: "module-melrose-properties-valuation-requests-archived",
            props: () => ({
              pageTitle: "Properties",
              parentRouteName: "module-melrose-properties-valuation-requests",
            }),
            component: () =>
              import(
                "@/modules/melrose/views/properties/valuation-requests/Archived"
              ),
          },
        ],
      },
      {
        path: "viewings",
        component: () => import("@/modules/melrose/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("melrose/propertiesStore/loadViewings", {
              appId: to.params.id,
            }),
            store.dispatch("melrose/customersStore/loadCustomers", {
              appId: to.params.id,
            }),
            store.dispatch("melrose/propertiesStore/loadProperties", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: [
          {
            path: "",
            name: "module-melrose-properties-viewings",
            component: () =>
              import("@/modules/melrose/views/properties/viewings/Dashboard"),
          },
          {
            path: "archived",
            name: "module-melrose-properties-viewings-archived",
            component: () =>
              import("@/modules/melrose/views/properties/viewings/Archived"),
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("melrose/propertiesStore/loadViewings", {
                  appId: to.params.id,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
          },
        ],
      },
      {
        path: ":propertyId",
        component: () =>
          import("@/modules/melrose/views/properties/Individual"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store.dispatch("melrose/propertiesStore/loadProperty", {
            appId: to.params.id,
            propertyId: to.params.propertyId,
          }),
            store.dispatch("melrose/propertiesStore/loadViewings", {
              appId: to.params.id,
            }),
            store.dispatch("melrose/customersStore/loadCustomers", {
              appId: to.params.id,
            }),
            store.dispatch("melrose/propertiesStore/loadProperties", {
              appId: to.params.id,
            }),
            store.dispatch("contactForms/loadSubmissions", {
              appId: to.params.id,
            }),
            store
              .dispatch("melrose/solicitorsStore/loadSolicitors", {
                appId: to.params.id,
              })
              .then(() => {
                store.commit("showHideLoader", false);
                next();
              });
        },
        children: [
          {
            path: "all",
            name: "module-melrose-properties-individual-all",
            component: () =>
              import("@/modules/melrose/views/properties/IndividualAll"),
          },
          {
            path: "",
            name: "module-melrose-properties-individual",
            component: () =>
              import("@/modules/melrose/views/properties/Details"),
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("melrose/customersStore/loadCustomers", {
                  appId: to.params.id,
                }),
                store.dispatch("melrose/propertiesStore/loadProperty", {
                  appId: to.params.id,
                  propertyId: to.params.propertyId,
                }),
                store.dispatch("melrose/propertiesStore/loadProperties", {
                  appId: to.params.id,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
          },
          {
            path: "descriptions",
            name: "module-melrose-properties-individual-descriptions",
            component: () =>
              import("@/modules/melrose/views/properties/Descriptions"),
          },
          {
            path: "admin",
            name: "module-melrose-properties-individual-admin",
            component: () => import("@/modules/melrose/views/properties/Admin"),
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("melrose/propertiesStore/loadManagers", {
                  appId: to.params.id,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
          },
          {
            path: "noi",
            name: "module-melrose-properties-individual-noi",
            component: () => import("@/modules/melrose/views/properties/Noi"),
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("melrose/customersStore/loadCustomers", {
                  appId: to.params.id,
                }),
                store.dispatch("melrose/solicitorsStore/loadSolicitors", {
                  appId: to.params.id,
                }),

                store.dispatch("melrose/propertiesStore/loadProperties", {
                  appId: to.params.id,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
          },
          {
            path: "viewings",
            name: "module-melrose-properties-individual-viewings",
            component: () =>
              import("@/modules/melrose/views/properties/Viewings"),
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("melrose/customersStore/loadCustomers", {
                  appId: to.params.id,
                }),
                store.dispatch("melrose/propertiesStore/loadProperty", {
                  appId: to.params.id,
                  propertyId: to.params.propertyId,
                }),
                store.dispatch("melrose/propertiesStore/loadProperties", {
                  appId: to.params.id,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
          },
          {
            path: "offers",
            name: "module-melrose-properties-individual-offers",
            component: () =>
              import("@/modules/melrose/views/properties/Offers"),
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("melrose/propertiesStore/loadViewings", {
                  appId: to.params.id,
                }),
                store.dispatch("melrose/customersStore/loadCustomers", {
                  appId: to.params.id,
                }),
                store.dispatch("melrose/propertiesStore/loadProperties", {
                  appId: to.params.id,
                }),
                store.dispatch("melrose/solicitorsStore/loadSolicitors", {
                  appId: to.params.id,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
          },
          {
            path: "matching",
            name: "module-melrose-properties-individual-matching",
            component: () =>
              import("@/modules/melrose/views/properties/Matching"),
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("melrose/propertiesStore/loadViewings", {
                  appId: to.params.id,
                }),
                store.dispatch("melrose/customersStore/loadCustomers", {
                  appId: to.params.id,
                }),
                store.dispatch("melrose/propertiesStore/loadProperties", {
                  appId: to.params.id,
                }),
                store.dispatch("melrose/solicitorsStore/loadSolicitors", {
                  appId: to.params.id,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
          },

          {
            path: "images",
            name: "module-melrose-properties-individual-images",
            component: () =>
              import("@/modules/melrose/views/properties/Images"),
          },
          {
            path: "floor-plan",
            name: "module-melrose-properties-individual-floor-plan",
            component: () =>
              import("@/modules/melrose/views/properties/FloorPlan"),
          },
          {
            path: "pdf",
            name: "module-melrose-properties-individual-pdf",
            component: () => import("@/modules/melrose/views/properties/Pdf"),
          },
        ],
      },
    ],
  },
];
