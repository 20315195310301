import store from "@/stores/";

export default [
  {
    path: "opportunities",
    component: () => import("@/modules/opportunities/views/Template"),
    meta: { hasPermission: "view-module-opportunities" },
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("opportunities/loadModule", {
          appId: to.params.id,
          module: "opportunities",
        }),
        store.dispatch("customers/loadModule", {
          appId: to.params.id,
          module: "customers",
        }),
        store.dispatch("team/loadModule", {
          appId: to.params.id,
          module: "team",
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-opportunities",
        component: () => import("@/modules/opportunities/views/Dashboard"),
      },
      {
        path: "archived",
        name: "module-opportunities-archived",
        component: () => import("@/modules/opportunities/views/Archived"),
      },
      {
        path: "in-xero",
        name: "module-opportunities-in-xero",
        component: () => import("@/modules/opportunities/views/InXero"),
      },
    ],
  },
];
