import store from "@/stores/";

export default [
  {
    path: "customers",
    component: () => import("@/modules/craigtoun/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      store
        .dispatch("craigtoun/customersStore/loadCustomers", {
          appId: to.params.id,
        })
        .then(() => {
          store.commit("showHideLoader", false);
          next();
        });
    },
    children: [
      {
        path: "",
        name: "module-craigtoun-customers",
        component: () =>
          import("@/modules/craigtoun/views/customers/Dashboard"),
      },
      {
        path: "archived",
        name: "module-craigtoun-customers-archived",
        props: () => ({
          pageTitle: "Customers",
          parentRouteName: "module-craigtoun-customers",
        }),
        component: () => import("@/modules/craigtoun/views/customers/Archived"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("craigtoun/customersStore/loadCustomers", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: ":customerId",
        name: "module-craigtoun-customers-individual",
        component: () =>
          import("@/modules/craigtoun/views/customers/Individual"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("craigtoun/customersStore/loadCustomer", {
              appId: to.params.id,
              customerId: to.params.customerId,
            }),
            store.dispatch("craigtoun/venuesStore/loadVenues", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
    ],
  },
];
