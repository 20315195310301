import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  products: [],
  categories: [],
  archived: [],
  archivedCategories: [],
};

// getters
const getters = {};

// actions
const actions = {
  loadProducts({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/flightmode/products`,
        method: "GET",
      })
        .then(resp => {
          commit("loadProductsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  saveProduct({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/flightmode/products/${data.productId}`
      : `${getApiUrl(data.appId)}/module/flightmode/products`;

    return new Promise((resolve, reject) => {
      axios
        .post(url, data.formData)
        .then(resp => {
          commit("saveProductSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/flightmode/products/${
          data.productId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteProductSuccess", { productId: data.productId });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  restoreProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/flightmode/products/${
          data.productId
        }/restore`,
        method: "POST",
      })
        .then(resp => {
          commit("restoreProductSuccess", { ...resp.data });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  saveCategory({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/flightmode/products/categories/${
          data.categoryId
        }`
      : `${getApiUrl(data.appId)}/module/flightmode/products/categories`;

    return new Promise((resolve, reject) => {
      axios
        .post(url, data.fields)
        .then(resp => {
          commit("saveCategorySuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/flightmode/products/categories/${
          data.categoryId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteCategorySuccess", { categoryId: data.categoryId });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  restoreCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/flightmode/products/categories/${
          data.categoryId
        }/restore`,
        method: "POST",
      })
        .then(resp => {
          commit("restoreCategorySuccess", { ...resp.data });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadProductsSuccess(state, data) {
    state.products = data.products;
    state.categories = data.categories;
    state.archived = data.archived;
    state.archivedCategories = data.archivedCategories;
  },
  saveProductSuccess(state, data) {
    if (data.isEditing) {
      const index = state.products.findIndex(p => p.id === data.product.id);
      state.products.splice(index, 1, data.product);
    } else {
      state.products.push(data.product);
    }
  },
  deleteProductSuccess(state, data) {
    const index = state.products.findIndex(p => p.id === data.productId);
    state.archived.push(state.products[index]);
    state.products.splice(index, 1);
  },
  restoreProductSuccess(state, data) {
    const index = state.archived.findIndex(p => p.id === data.product.id);
    state.archived.splice(index, 1);
    state.products.push(data.product);
  },
  saveCategorySuccess(state, data) {
    if (data.isEditing) {
      const index = state.categories.findIndex(c => c.id === data.category.id);
      state.categories.splice(index, 1, data.category);
    } else {
      state.categories.push(data.category);
    }
  },
  deleteCategorySuccess(state, data) {
    const index = state.categories.findIndex(c => c.id === data.categoryId);
    state.archivedCategories.push(state.categories[index]);
    state.categories.splice(index, 1);
  },
  restoreCategorySuccess(state, data) {
    const index = state.archivedCategories.findIndex(
      c => c.id === data.category.id
    );
    state.archivedCategories.splice(index, 1);
    state.categories.push(data.category);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
