import store from "@/stores/";

export default [
  {
    path: "move-out",
    component: () => import("@/modules/sbpm/views/Template"),
    children: [
      {
        path: "",
        name: "module-sbpm-moveout",
        component: () => import("@/modules/sbpm/views/move-out/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("sbpm/moveoutStore/loadMoveOuts", {
              appId: to.params.id,
            }),
            store.dispatch("users/loadModule", {
              appId: to.params.id,
              module: "users",
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: ":moveoutId",
        name: "module-sbpm-moveout-individual",
        component: () => import("@/modules/sbpm/views/move-out/Individual"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("sbpm/moveoutStore/loadMoveOut", {
              appId: to.params.id,
              moveoutId: to.params.moveoutId,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
    ],
  },
];
