import store from "@/stores/";

export default [
  {
    path: "team",
    component: () => import("@/modules/team/views/Template"),
    meta: { hasPermission: "view-module-team" },
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      store
        .dispatch("team/loadModule", {
          appId: to.params.id,
          module: "team",
        })
        .then(() => {
          store.commit("showHideLoader", false);
          next();
        });
    },
    children: [
      {
        path: "",
        name: "module-team",
        component: () => import("@/modules/team/views/Dashboard"),
      },
      {
        path: "resourcing",
        name: "module-team-resourcing",
        component: () => import("@/modules/team/views/Resourcing"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("team/timetastic/loadResourcing", {
              appId: to.params.id,
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
      },
      {
        path: "organisation",
        name: "module-team-organisation",
        component: () => import("@/modules/team/views/Organisation"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("team/loadDepartments", {
              appId: to.params.id,
              module: "departments",
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
      },
      {
        path: "roles-permissions",
        name: "module-team-roles-permissions",
        component: () => import("@/modules/team/views/RolesPermissions"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("users/loadPermissions", {
              appId: to.params.id,
            }),
            store.dispatch("users/loadRoles", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: ":teamMemberId",
        component: () => import("@/modules/team/views/Profile"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("team/loadTeamMember", {
              appId: to.params.id,
              teamMemberId: to.params.teamMemberId,
              module: "team",
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
        children: [
          {
            path: "",
            name: "module-team-profile",
            component: () =>
              import("@/modules/team/views/components/profile/BasicDetails"),
          },
          {
            path: "contact-information",
            name: "module-team-profile-contact",
            component: () =>
              import(
                "@/modules/team/views/components/profile/ContactInformation"
              ),
          },
          {
            path: "contract-details",
            name: "module-team-profile-contract",
            component: () =>
              import("@/modules/team/views/components/profile/ContractDetails"),
          },
          {
            path: "user-access",
            name: "module-team-profile-user-access",
            component: () =>
              import("@/modules/team/views/components/profile/UserAccess"),
          },
          {
            path: "time-logged",
            name: "module-team-profile-time-logged",
            component: () =>
              import("@/modules/team/views/components/profile/TimeLogged"),
          },
          {
            path: "integrations",
            name: "module-team-profile-integrations",
            component: () =>
              import("@/modules/team/views/components/profile/Integrations"),
          },
        ],
      },
    ],
  },
];
