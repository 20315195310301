// import axios from "axios";
import areaGuidesStore from "./area-guides.store";
import customersStore from "./customers.store";
import propertiesStore from "./properties.store";
import solicitorsStore from "./solicitors.store";

// default state
const getDefaultState = () => {
  return {};
};

// initial state
const state = getDefaultState();

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

// modules
const modules = {
  areaGuidesStore,
  customersStore,
  propertiesStore,
  solicitorsStore,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
