import applicationformsRoutes from "./applicationforms.routes";
import customersRoutes from "./customers.routes";
import propertiesRoutes from "./properties.routes";
import salesRoutes from "./sales.routes";
import teamsRoutes from "./teams.routes";

export default [
  {
    path: "easylets",
    component: () => import("@/modules/easylets/views/Template"),
    children: [
      ...customersRoutes,
      ...propertiesRoutes,
      ...applicationformsRoutes,
      ...salesRoutes,
      ...teamsRoutes,
    ],
  },
];
