import store from "@/stores/";

export default [
  {
    path: "bookings",
    component: () => import("@/modules/arnprior/views/Template"),
    children: [
      {
        path: "",
        name: "module-arnprior-bookings",
        component: () => import("@/modules/arnprior/views/bookings/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("arnprior/ordersStore/loadOrders", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
    ],
  },
];
