import applicationRoutes from "./applications.routes";
import checklistsRoutes from "./checklists.routes";
import checkoutRoutes from "./checkout.routes";
import communalrepairsRoutes from "./communalrepairs.routes";
import diariesRoutes from "./diaries.routes";
import enquiriesRoutes from "./enquiries.routes";
import managedpropertiesRoutes from "./managedproperties.routes";
import moveoutsRoutes from "./moveout.routes";
import processesRoutes from "./processes.routes";
import propertiesRoutes from "./properties.routes";

export default [
  {
    path: "sbpm",
    component: () => import("@/modules/sbpm/views/Template"),
    children: [
      ...propertiesRoutes,
      ...diariesRoutes,
      ...applicationRoutes,
      ...checklistsRoutes,
      ...processesRoutes,
      ...enquiriesRoutes,
      ...moveoutsRoutes,
      ...checkoutRoutes,
      ...managedpropertiesRoutes,
      ...communalrepairsRoutes,
    ],
  },
];
