import store from "@/stores/";

export default [
  {
    path: "pipeline",
    component: () => import("@/modules/pipeline/views/Template"),
    meta: { hasPermission: "view-module-pipeline" },
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      store
        .dispatch("pipeline/loadModule", {
          appId: to.params.id,
          module: "pipeline",
        })
        .then(resp => {
          if (resp.data.success === false) {
            localStorage.setItem("xeroAppId", to.params.id);
            localStorage.setItem("xeroState", resp.data.state);
            location.replace(resp.data.redirectUrl);
          }
          store.commit("showHideLoader", false);
          next();
        });
      // check setup status
      // direct based on the status
      // - if not setup, direct to form to setup
      // - if setup, direct to main page
      next();
    },
    children: [
      {
        path: "",
        name: "module-pipeline",
        component: () => import("@/modules/pipeline/views/Dashboard"),
      },
      {
        path: "targets",
        name: "module-pipeline-targets",
        component: () => import("@/modules/pipeline/views/Targets"),
      },
    ],
  },
];
