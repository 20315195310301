import store from "@/stores/";

export default [
  {
    path: "enquiries",
    component: () => import("@/modules/grogreen/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("grogreen/enquiries/loadEnquiries", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-grogreen-productenquiries",
        component: () => import("@/modules/grogreen/views/enquiries/Dashboard"),
      },
      {
        path: ":enquiryId",
        name: "module-grogreen-productenquiries-individual",
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("grogreen/enquiries/loadEnquiry", {
              appId: to.params.id,
              enquiryId: to.params.enquiryId,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        component: () =>
          import("@/modules/grogreen/views/enquiries/Individual"),
      },
    ],
  },
];
