import store from "@/stores/";

export default [
  {
    path: "end-of-tenancy",
    component: () => import("@/modules/ibm/views/Template"),

    children: [
      {
        path: "",
        name: "module-ibm-endoftenancy",
        component: () => import("@/modules/ibm/views/end-of-tenancy/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("ibm/endoftenancyStore/loadTenancies", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      // {
      //   path: ":enquiryId",
      //   name: "module-sbpm-communalrepairs-individual",
      //   component: () =>
      //     import("@/modules/sbpm/views/communal-repairs/Individual"),
      //   beforeEnter: (to, from, next) => {
      //     store.commit("showHideLoader", true);
      //     Promise.all([
      //       store.dispatch("sbpm/enquiriesStore/loadEnquiry", {
      //         appId: to.params.id,
      //         enquiryId: to.params.enquiryId,
      //       }),
      //     ]).then(() => {
      //       store.commit("showHideLoader", false);
      //       next();
      //     });
      //   },
      // },
    ],
  },
];
