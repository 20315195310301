import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  resources: [],
  resource: {},
};

// getters
const getters = {
  all: (state) => state.resources,
  get: (state) => state.resource,
};

// actions
const actions = {
  loadResources({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/resources`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadResourcesSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },
  loadResource({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/resources/${data.resourceId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadResourceSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  deleteResource({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/resources/${data.resourceId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteResourceSuccess", { resourceId: data.resourceId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  saveResource({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/resources/${data.resourceId}`
      : `${getApiUrl(data.appId)}/module/lhl/resources`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveResourceSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadResourcesSuccess(state, data) {
    state.resources = data.resources;
  },

  loadResourceSuccess(state, data) {
    state.resource = data.resource;
  },

  saveResourceSuccess(state, data) {
    if (data.isEditing) {
      state.resource = data.resource;

      const index = state.resources.findIndex((c) => c.id === data.resource.id);
      state.resources.splice(index, 1, data.resource);
    } else {
      state.resources.push(data.resource);
    }
  },

  deleteResourceSuccess(state, data) {
    const index = state.resources.findIndex((p) => p.id === data.resourceId);
    state.resources.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
