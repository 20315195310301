import store from "@/stores/";

export default [
  {
    path: "seo",
    component: () => import("@/modules/seo/views/Template"),
    children: [
      {
        path: "redirection",
        component: () => import("@/modules/seo/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("seo/redirectionStore/loadRedirects", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: [
          {
            path: "",
            name: "module-seo-seo-redirection",
            component: () => import("@/modules/seo/views/Redirection"),
          },
        ],
      },
      {
        path: "pages",
        component: () => import("@/modules/seo/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("seo/pageStore/loadPages", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: [
          {
            path: "archived",
            name: "module-seo-seo-meta-data-archived",
            component: () => import("@/modules/seo/views/ArchiveMetaData"),
          },
          {
            path: "",
            name: "module-seo-seo-meta-data",
            component: () => import("@/modules/seo/views/MetaData"),
          },
        ],
      },
    ],
  },
];
