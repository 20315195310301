import store from "@/stores/";

export default [
  {
    path: "applications",
    component: () => import("@/modules/sbpm/views/Template"),
    children: [
      {
        path: "",
        name: "module-sbpm-applications",
        component: () => import("@/modules/sbpm/views/applications/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("sbpm/applicationsStore/loadApplications", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "archived",
        name: "module-sbpm-applications-archived",
        component: () => import("@/modules/sbpm/views/applications/Archived"),
      },
      {
        path: ":applicationId",
        name: "module-sbpm-applications-individual",
        component: () => import("@/modules/sbpm/views/applications/Individual"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("sbpm/applicationsStore/loadApplication", {
              appId: to.params.id,
              applicationId: to.params.applicationId,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
    ],
  },
];
