import areaGuidesRoutes from "./area-guides.routes";
import customersRoutes from "./customers.routes";
import propertiesRoutes from "./properties.routes";
import solicitorsRoutes from "./solicitors.routes";

export default [
  {
    path: "melrose",
    component: () => import("@/modules/melrose/views/Template"),
    children: [
      ...propertiesRoutes,
      ...customersRoutes,
      ...solicitorsRoutes,
      ...areaGuidesRoutes,
    ],
  },
];
