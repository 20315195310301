import EnquiriesRoutes from "./enquiries.routes";
import LandingPagesRoutes from "./landingpages.routes";

export default [
  {
    path: "lpb",
    component: () => import("@/modules/lpb/views/Template"),
    children: [...EnquiriesRoutes, ...LandingPagesRoutes],
  },
];
