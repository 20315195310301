import store from "@/stores/";

export default [
  {
    path: "breeds",
    component: () => import("@/modules/lhl/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("lhl/breeds/loadBreeds", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-lhl-breeds",
        component: () => import("@/modules/lhl/views/breeds/Dashboard"),
      },
      {
        path: ":breedId",
        name: "module-lhl-breeds-individual",
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("lhl/breeds/loadBreed", {
              appId: to.params.id,
              breedId: to.params.breedId,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        component: () => import("@/modules/lhl/views/breeds/Individual"),
      },
    ],
  },
];
