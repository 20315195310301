import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  video1: null,
  video2: null,
};

// getters
const getters = {};

// actions
const actions = {
  loadSettings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/grogreen/settings`,
        method: "GET",
      })
        .then((resp) => {
          commit("setSettings", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveSettings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/grogreen/settings`,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("setSettings", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  setSettings(state, data) {
    state.video1 = data.video_1;
    state.video2 = data.video_2;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
