import store from "@/stores/";

export default [
  {
    path: "members",
    component: () => import("@/modules/flightmode/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      store
        .dispatch("flightmode/members/loadMembers", {
          appId: to.params.id,
        })
        .then(() => {
          store.commit("showHideLoader", false);
          next();
        });
    },
    children: [
      {
        path: "",
        name: "module-flightmode-members",
        component: () => import("@/modules/flightmode/views/members/Dashboard"),
      },
      {
        path: "past",
        name: "module-flightmode-members-past",
        component: () => import("@/modules/flightmode/views/members/Past"),
      },
      {
        path: ":memberId",
        component: () =>
          import("@/modules/flightmode/views/members/profile/Index"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("flightmode/members/loadMember", {
              appId: to.params.id,
              memberId: parseInt(to.params.memberId),
              isCurrent: to.params.isCurrent,
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
        children: [
          {
            path: "",
            redirect: {
              name: "module-flightmode-members-profile",
            },
          },
          {
            path: "details",
            name: "module-flightmode-members-profile",
            component: () =>
              import("@/modules/flightmode/views/members/profile/Details"),
          },
          {
            path: "creative-profile",
            name: "module-flightmode-members-profile-creative",
            component: () =>
              import(
                "@/modules/flightmode/views/members/profile/CreativeProfile"
              ),
          },
          {
            path: "memberships",
            name: "module-flightmode-members-profile-memberships",
            component: () =>
              import("@/modules/flightmode/views/members/profile/Memberships"),
          },
          {
            path: "courses",
            name: "module-flightmode-members-profile-courses",
            component: () =>
              import("@/modules/flightmode/views/members/profile/Courses"),
          },
        ],
      },
    ],
  },
];
