import store from "@/stores/";

export default [
  {
    path: "season-tickets",
    component: () => import("@/modules/craigtoun/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      store.dispatch("craigtoun/ticketsStore/loadTickets", {
        appId: to.params.id,
      }),
        store.dispatch("craigtoun/ticketsStore/loadTypes", {
          appId: to.params.id,
        }),
        store
          .dispatch("craigtoun/customersStore/loadCustomers", {
            appId: to.params.id,
          })
          .then(() => {
            store.commit("showHideLoader", false);
            next();
          });
    },
    children: [
      {
        path: "",
        name: "module-craigtoun-seasontickets",
        component: () => import("@/modules/craigtoun/views/tickets/Dashboard"),
      },
      {
        path: ":ticketId",
        name: "module-craigtoun-tickets-individual",
        component: () => import("@/modules/craigtoun/views/tickets/Individual"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store.dispatch("craigtoun/ticketsStore/loadTicket", {
            appId: to.params.id,
            ticketId: to.params.ticketId,
          }),
            store.dispatch("craigtoun/customersStore/loadCustomers", {
              appId: to.params.id,
            });
          store
            .dispatch("craigtoun/ticketsStore/loadTypes", {
              appId: to.params.id,
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
      },
    ],
  },
];
