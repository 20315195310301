import store from "@/stores/";

export default [
  {
    path: "delivery-settings",
    component: () => import("@/modules/ecommerce/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("ecommerce/deliverySettingsStore/loadCosts", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-ecommerce-delivery-settings",
        component: () =>
          import("@/modules/ecommerce/views/delivery-settings/Dashboard"),
      },
    ],
  },
];
