import * as Utils from "@/utils";

import axios from "axios";
import router from "@/router";

// initial state
const state = {
  status: "",
  token: localStorage.getItem("token") || "",
  user: {},
  apps: [],
  widgets: [],
  brand: {
    name: "",
    color: "accent",
    background: "pic-t3a2w596ks.jpg",
    logo: "main-logo.svg",
    bugherd_api_key: "",
  },
  userLoaded: false,
  showLoader: false,
  globalSnackbar: {
    open: false,
    text: "",
    timeout: 4000,
    color: undefined,
    icon: "",
  },
};

// getters
const getters = {
  isLoggedIn: (state) => !!state.token,
  authStatus: (state) => state.status,
  userStatus: (state) => parseInt(state.user.status),
  userLoaded: (state) => state.userLoaded,
  showLoader: (state) => state.showLoader,
  globalSnackbar: (state) => state.globalSnackbar,
  user: (state) => state.user,
  userFirstName: (state) => state.user.first_name,
  userLastName: (state) => state.user.last_name,
  userFullName: (state) => state.user.first_name + " " + state.user.last_name,
  userInitials: (state) => state.user.initials,
  userId: (state) => state.user.id,
  userEmail: (state) => state.user.email,
  userAvatar: (state) => state.user.avatar,
  sumOfApps: (state) => state.apps.length || 0,
  singleAppId: (state) => state.apps[0].id,
  apps: (state) => state.apps,
  roles: (state) => state.user.roles,
  brand: (state) => state.brand,
};

// actions
const actions = {
  login({ commit }, user) {
    commit("showHideLoader", true);
    return new Promise((resolve, reject) => {
      commit("authRequest");
      Utils.getCookie().then(() => {
        axios({
          url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/auth/login`,
          data: user,
          method: "POST",
        })
          .then((resp) => {
            const token = resp.data.token;
            const user = resp.data.user;
            const apps = resp.data.apps;

            commit("authSuccess", { token, user, apps });

            axios({
              url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/user`,
            }).then(() => {
              commit("showHideLoader", false);
              resolve();
            });
          })
          .catch((err) => {
            commit("authError");
            commit("showHideLoader", false);
            reject(err);
          });
      });
    });
  },
  resetPasswordRequest({ commit }, email) {
    commit("showHideLoader", true);
    return new Promise((resolve, reject) => {
      commit("requestResetPasswordRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/auth/reset-password`,
        data: email,
        method: "POST",
      })
        .then((resp) => {
          commit("requestResetPasswordSuccess");
          commit("showHideLoader", false);
          resolve(resp);
        })
        .catch((err) => {
          commit("requestResetPasswordError");
          commit("showHideLoader", false);
          reject(err);
        });
    });
  },
  saveResetPassword({ commit }, data) {
    commit("showHideLoader", true);
    return new Promise((resolve, reject) => {
      commit("saveResetPasswordRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/auth/reset/password`,
        data: { ...data },
        method: "POST",
      })
        .then((resp) => {
          commit("saveResetPasswordSuccess");
          commit("showHideLoader", false);
          resolve(resp);
        })
        .catch((err) => {
          commit("saveResetPasswordError");
          commit("showHideLoader", false);
          reject(err);
        });
    });
  },
  logout({ commit }) {
    commit("showHideLoader", true);
    return new Promise((resolve, reject) => {
      commit("logoutRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/auth/logout`,
        method: "POST",
      })
        .then((resp) => {
          commit("logoutSuccess");
          commit("showHideLoader", false);
          router.push("/login");
          resolve(resp);
        })
        .catch((err) => {
          commit("logoutError");
          commit("showHideLoader", false);
          reject(err);
        });
    });
  },
  forceLogout({ commit }) {
    return new Promise((resolve) => {
      commit("logoutSuccess");
      commit("showHideLoader", false);
      resolve();
    });
  },
  setupAccount({ commit }, user) {
    commit("showHideLoader", true);
    return new Promise((resolve, reject) => {
      commit("setupAccountRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/account/setup`,
        data: user,
        method: "POST",
      })
        .then((resp) => {
          commit("setupAccountSuccess");
          resolve(resp);
        })
        .catch((err) => {
          commit("setupAccountError");
          reject(err);
        });
    });
  },
  reloadUserSetup({ commit }) {
    return new Promise((resolve, reject) => {
      commit("reloadUserRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/auth/reload`,
        method: "GET",
      })
        .then((resp) => {
          const user = resp.data.user;
          const apps = resp.data.apps;

          commit("reloadUserSuccess", { user, apps });
          commit("showHideLoader", false);
          resolve(resp);
        })
        .catch((err) => {
          commit("reloadUserError");
          commit("showHideLoader", false);
          commit("logoutSuccess");
          reject(err);
        });
    });
  },
  loadApplication({ commit }) {
    return new Promise((resolve, reject) => {
      commit("loadApplicationRequest");
      Utils.getCookie().then(() => {
        axios({
          url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/loadApplication`,
          method: "GET",
        })
          .then((resp) => {
            const brand = resp.data.brand;
            const setupType = resp.data.setupType;

            commit("loadApplicationSuccess", { brand, setupType });
            resolve(resp);
          })
          .catch((err) => {
            commit("loadApplicationError");
            reject(err);
          });
      });
    });
  },
  loadAppModules({ commit }, data) {
    commit("loadAppModulesRequest");
    return new Promise((resolve, reject) => {
      Utils.getCookie().then(() => {
        axios({
          url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/dashboard/widgets`,
          method: "GET",
        })
          .then((resp) => {
            const widgets = resp.data.widgets;

            commit("loadAppModulesSuccess", { widgets });

            resolve(resp.data.widgets);
          })
          .catch(() => {
            commit("loadAppModulesError");
            reject();
          });
      });
    });
  },

  changeStatusProperty({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/sbpm/properties/${data.propertyId}/change-status`,
        method: "POST",
      })
        .then((resp) => {
          const widgetId = data.widgetId;
          const indexId = data.indexId;
          commit("changeStatusSuccess", { widgetId, indexId });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  completeWorkChunk({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/chunks/${data.chunkId}/complete`,
        method: "POST",
      })
        .then((resp) => {
          const work_chunk = resp.data.work_chunk;
          const widgetId = data.widgetId;
          const indexId = data.indexId;
          commit("closeWorkChunksSuccess", {
            work_chunk,
            widgetId,
            indexId,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  showHideLoader(state, show) {
    state.showLoader = show;
  },
  showGlobalSnackbar(state, content) {
    if (content.hasOwnProperty("text")) {
      state.globalSnackbar.text = content.text;
    }
    if (content.hasOwnProperty("open")) {
      state.globalSnackbar.open = content.open;
    }

    if (content.hasOwnProperty("icon")) {
      state.globalSnackbar.icon = content.icon;
    }

    state.globalSnackbar.color = content.color ? content.color : undefined;
  },
  authRequest() {},
  authSuccess(state, payload) {
    localStorage.setItem("token", payload.token);
    axios.defaults.headers.common = {
      Authorization: "Bearer " + payload.token,
    };

    state.status = "success";
    state.token = payload.token;
    state.user = payload.user;
    state.apps = payload.apps;
    state.userLoaded = true;
  },
  authError() {
    localStorage.clear();
  },
  logoutRequest() {},
  logoutSuccess(state) {
    localStorage.clear();
    delete axios.defaults.headers.common["Authorization"];

    state.status = "";
    state.token = "";
    state.user = {};
    state.apps = {};
    state.userLoaded = false;
  },
  logoutError() {},
  setupAccountRequest() {},
  setupAccountSuccess() {},
  setupAccountError() {},
  reloadUserRequest() {},
  reloadUserSuccess(state, payload) {
    state.status = "success";
    state.user = payload.user;
    state.apps = payload.apps;
    state.userLoaded = true;
  },
  reloadUserError() {},
  loadApplicationRequest() {},
  loadApplicationSuccess(state, payload) {
    if (payload.setupType === "custom") {
      if (payload.brand.name !== null && payload.brand.name !== "") {
        state.brand.name = payload.brand.name;
      }

      if (payload.brand.color !== null && payload.brand.color !== "") {
        state.brand.color = payload.brand.color;
      }

      if (
        payload.brand.background !== null &&
        payload.brand.background !== ""
      ) {
        state.brand.background = payload.brand.background;
      }
      if (payload.brand.logo !== null && payload.brand.logo !== "") {
        state.brand.logo = payload.brand.logo;
      }
      if (
        payload.brand.bugherd_api_key !== null &&
        payload.brand.bugherd_api_key !== ""
      ) {
        state.brand.bugherd_api_key = payload.brand.bugherd_api_key;
      }
    }
  },
  loadApplicationError() {},
  requestResetPasswordRequest() {},
  requestResetPasswordSuccess() {},
  requestResetPasswordError() {},
  saveResetPasswordRequest() {},
  saveResetPasswordSuccess() {},
  saveResetPasswordError() {},
  uploadAvatarSuccess(state, payload) {
    state.user.avatar = payload.avatar;
  },
  deleteAvatarSuccess(state) {
    state.user.avatar = null;
  },
  updateInformationSuccess(state, payload) {
    state.user.email = payload.user.email;
    state.user.first_name = payload.user.first_name;
    state.user.last_name = payload.user.last_name;
    state.user.full_name = payload.user.full_name;
  },
  loadAppModulesRequest() {},
  loadAppModulesSuccess(state, payload) {
    state.widgets = payload.widgets;
  },

  closeWorkChunksSuccess(state, data) {
    state.widgets[data.widgetId].table.body.splice(data.indexId, 1);
  },

  changeStatusSuccess(state, data) {
    state.widgets[data.widgetId].table.body.splice(data.indexId, 1);
  },

  loadAppModulesError() {},
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
