import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  dogs: [],
  colors: [],
  coat_types: [],
  markings: [],
  sizes: [],
  medications: [],
  medications_products: [],
  pedigreeArray: [],
  dog: {},
};

// getters
const getters = {
  all: (state) => state.dogs,
  get: (state) => state.dog,
  colors: (state) => state.colors,
  pedigreeArray: (state) => state.pedigreeArray,
  sires: (state) => {
    return state.dogs.filter(
      (c) => c.gender == "M" && c.status == "breeding-dog"
    );
  },
  dams: (state) => {
    return state.dogs.filter(
      (c) => c.gender == "F" && c.status == "breeding-dog"
    );
  },

  siresAll: (state) => {
    return state.dogs.filter((c) => c.gender == "M" && c.status != "puppy");
  },
  damsAll: (state) => {
    return state.dogs.filter((c) => c.gender == "F" && c.status != "puppy");
  },

  puppies: (state) => {
    return state.dogs.filter((c) => c.status == "puppy");
  },

  medications: (state) => state.medications,
  medications_products: (state) => state.medications_products,
};

// actions
const actions = {
  loadDogs({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/dogs`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadDogsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  loadDog({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadDogSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  deleteNote({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/note/${
          data.noteId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteNoteSuccess", { noteId: data.noteId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  deleteImage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/image/${
          data.imageId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteImageSuccess", {
            imageId: data.imageId,
            type: data.type,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  saveImage({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/image/${
          data.imageId
        }`
      : `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/image`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveImageSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveTaskStatus({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/tasks/${
      data.taskId
    }/complete`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveTaskStatusSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteWeight({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/weight/${
          data.weightId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteWeightSuccess", { weightId: data.weightId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  deleteMedication({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/dogs/${
          data.dogId
        }/medication/${data.medicationId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteMedicationSuccess", { weightId: data.medicationId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  deleteRecord({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/record/${
          data.recordId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteRecordSuccess", { recordId: data.recordId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  deleteDog({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteDogSuccess", { dogId: data.dogId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  deleteWorming({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/worming/${
          data.wormingId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteWormingSuccess", { dogId: data.wormingId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  deleteLastSeason({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/season/${
          data.seasonId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteLastSeasongSuccess", { dogId: data.seasonId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  saveSeason({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/season/${
          data.seasonId
        }`
      : `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/season`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveSeasonSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  generateSeason({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/dogs/${
      data.dogId
    }/generate-seasons`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveGenerateSeasonSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveWorming({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/worming/${
          data.wormingId
        }`
      : `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/worming`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveWormingSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveMedication({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/medication/${
          data.medicationId
        }`
      : `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/medication`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveMedicationSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveDog({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}`
      : `${getApiUrl(data.appId)}/module/lhl/dogs`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveDogSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveTesting({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/dogs/${
      data.dogId
    }/testing`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveTestingSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  savePedigree({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/dogs/${
      data.dogId
    }/pedigree`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else if (typeof fields[field] === "object") {
        formData.append(field, fields[field]["id"]);
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("savePedigreeSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveBasic({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/basic`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveBasicSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveDefect({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/dogs/${
      data.dogId
    }/defects`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveDefectSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveDogNote({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/dogs/${
      data.dogId
    }/dog-note`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveDogNoteSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveNote({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/note/${
          data.noteId
        }`
      : `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/note`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveNoteSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveRecord({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/record/${
          data.recordId
        }`
      : `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/record`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveRecordSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadFamilyCarePdf(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/pdf`,
        method: "POST",
        responseType: "arraybuffer",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadFamilyCareHomeContract3Pdf(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/dogs/${
          data.dogId
        }/pdf-family-care-home-contract-3`,
        method: "POST",
        responseType: "arraybuffer",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadFamilyCareHomeContract4Pdf(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/dogs/${
          data.dogId
        }/pdf-family-care-home-contract-4`,
        method: "POST",
        responseType: "arraybuffer",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadTemperamentAssessmentPdf(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/dogs/${
          data.dogId
        }/pdf-temperament-assessment`,
        method: "POST",
        responseType: "arraybuffer",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadContractOfSalePdf(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/dogs/${
          data.dogId
        }/pdf-contact-of-sale`,
        method: "POST",
        responseType: "arraybuffer",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadWormingPdf(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/dogs/${
          data.dogId
        }/pdf-worming`,
        method: "POST",
        responseType: "arraybuffer",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadPedigreePdf(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/dogs/${
          data.dogId
        }/pdf-pedigree`,
        method: "POST",
        responseType: "arraybuffer",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveWeight({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/weight/${
          data.weightId
        }`
      : `${getApiUrl(data.appId)}/module/lhl/dogs/${data.dogId}/weight`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveWeightSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveLitter({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/litters`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (
        fields[field] === null ||
        typeof fields[field] === "undefined"
      ) {
        formData.append(field, "");
      } else if (typeof fields[field] === "object") {
        if (fields[field] instanceof File) {
          formData.append(field, fields[field]);
        }
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(field + "[]", data[i]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveLittersuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadDogsSuccess(state, data) {
    state.dogs = data.dogs;
    state.coat_types = data.coat_types;
    state.sizes = data.sizes;
    state.colors = data.colors;
    state.markings = data.markings;
  },

  loadDogSuccess(state, data) {
    state.dog = data.dog;
    state.medications_products = data.medications;
    state.pedigreeArray = data.pedigreeArray;
  },

  saveDogSuccess(state, data) {
    state.dogs.push(data.dog);
  },

  saveTestingSuccess(state, data) {
    state.dog.testing = data.testing;
  },

  saveLittersuccess(state, data) {
    const index = state.dog.seasons.findIndex((p) => p.id === data.season.id);

    state.dog.seasons.splice(index, 1, data.season);
  },

  deleteLastSeasongSuccess(state) {
    state.dog.seasons.splice(-1);
  },

  saveImageSuccess(state, data) {
    if (data.isEditing) {
      if (data.image.type == "hidden") {
        const index = state.dog.hidden_gallery.findIndex(
          (c) => c.id === data.image.id
        );
        state.dog.hidden_gallery.splice(index, 1, data.image);
      }

      if (data.image.type == "front_end") {
        const index = state.dog.front_end_images.findIndex(
          (c) => c.id === data.image.id
        );
        state.dog.front_end_images.splice(index, 1, data.image);
      }

      if (data.image.type == "other") {
        const index = state.dog.other_images.findIndex(
          (c) => c.id === data.image.id
        );
        state.dog.other_images.splice(index, 1, data.image);
      }
    } else {
      if (data.image.type == "hidden") {
        state.dog.hidden_gallery.push(data.image);
      }

      if (data.image.type == "front_end") {
        state.dog.front_end_images.push(data.image);
      }

      if (data.image.type == "other") {
        state.dog.other_images.push(data.image);
      }
    }
  },

  deleteImageSuccess(state, data) {
    if (data.type == "hidden") {
      const index = state.dog.hidden_gallery.findIndex(
        (p) => p.id === data.imageId
      );
      state.dog.hidden_gallery.splice(index, 1);
    }

    if (data.type == "front_end") {
      const index = state.dog.front_end_images.findIndex(
        (p) => p.id === data.imageId
      );
      state.dog.front_end_images.splice(index, 1);
    }

    if (data.type == "other") {
      const index = state.dog.other_images.findIndex(
        (p) => p.id === data.imageId
      );
      state.dog.other_images.splice(index, 1);
    }
  },

  saveSeasonSuccess(state, data) {
    if (data.isEditing) {
      state.dog.seasons = data.seasons;
    } else {
      state.dog.seasons.push(data.season);
    }
  },

  saveBasicSuccess(state, data) {
    state.dog = data.dog;

    state.pedigreeArray = data.pedigreeArray;
  },

  saveTaskStatusSuccess(state, data) {
    const index = state.dog.tasks.findIndex((c) => c.id === data.task.id);
    state.dog.tasks.splice(index, 1, data.task);
  },

  saveWormingSuccess(state, data) {
    if (data.isEditing) {
      const index = state.dog.wormings.findIndex(
        (c) => c.id === data.worming.id
      );
      state.dog.wormings.splice(index, 1, data.worming);
    } else {
      state.dog.wormings.push(data.worming);
    }
  },

  saveMedicationSuccess(state, data) {
    if (data.isEditing) {
      const index = state.dog.medications.findIndex(
        (c) => c.id === data.medication.id
      );
      state.dog.medications.splice(index, 1, data.medication);
    } else {
      state.dog.medications.push(data.medication);
    }
  },

  deleteNoteSuccess(state, data) {
    const index = state.dog.health_notes.findIndex((p) => p.id === data.noteId);
    state.dog.health_notes.splice(index, 1);
  },

  deleteWeightSuccess(state, data) {
    const index = state.dog.weights.findIndex((p) => p.id === data.weightId);
    state.dog.weights.splice(index, 1);
  },

  deleteWormingSuccess(state, data) {
    const index = state.dog.wormings.findIndex((p) => p.id === data.wormingId);
    state.dog.wormings.splice(index, 1);
  },

  deleteRecordSuccess(state, data) {
    const index = state.dog.health_records.findIndex(
      (p) => p.id === data.recordId
    );
    state.dog.health_records.splice(index, 1);
  },

  deleteMedicationSuccess(state, data) {
    const index = state.dog.medications.findIndex(
      (p) => p.id === data.medicationId
    );
    state.dog.medications.splice(index, 1);
  },

  deleteDogSuccess(state, data) {
    const index = state.dogs.findIndex((p) => p.id === data.dogId);
    state.dogs.splice(index, 1);
  },

  saveNoteSuccess(state, data) {
    if (data.isEditing) {
      const index = state.dog.health_notes.findIndex(
        (c) => c.id === data.note.id
      );
      state.dog.health_notes.splice(index, 1, data.note);
    } else {
      state.dog.health_notes.push(data.note);
    }
  },

  saveDefectSuccess(state, data) {
    state.dog.defects = data.defects;
  },

  savePedigreeSuccess(state, data) {
    state.dog = data.dog;
  },

  saveDogNoteSuccess(state, data) {
    state.dog.dog_notes.push(data.note);
  },

  saveRecordSuccess(state, data) {
    if (data.isEditing) {
      const index = state.dog.health_records.findIndex(
        (c) => c.id === data.record.id
      );
      state.dog.health_records.splice(index, 1, data.record);
    } else {
      state.dog.health_records.push(data.record);
    }
  },

  saveGenerateSeasonSuccess(state, data) {
    state.dog = data.dog;
  },

  saveWeightSuccess(state, data) {
    if (data.isEditing) {
      const index = state.dog.weights.findIndex((c) => c.id === data.weight.id);
      state.dog.weights.splice(index, 1, data.weight);
    } else {
      state.dog.weights.push(data.weight);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
