import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  teams: [],
  team: {},
};

// getters
const getters = {
  all: (state) => state.teams,
};

// actions
const actions = {
  loadTeams({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/includem/teams`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadTeamsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveTeam({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/includem/teams/${data.teamId}`
      : `${getApiUrl(data.appId)}/module/includem/teams`;

    let fields = data.fields;

    if (fields.image_has_changed === false) {
      fields.image = null;
    }

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveTeamSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteTeam({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/includem/teams/${data.teamId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteTeamSuccess", {
            teamId: data.teamId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
};

// mutations
const mutations = {
  loadTeamsSuccess(state, data) {
    state.teams = data.teams;
  },
  loadTeamSuccess(state, data) {
    state.team = state.teams.find((p) => {
      return p.id === parseInt(data.teamId);
    });
  },
  saveTeamSuccess(state, data) {
    if (data.isEditing) {
      const index = state.teams.findIndex((t) => t.id === data.team.id);
      state.teams.splice(index, 1, data.team);
    } else {
      state.teams.push(data.team);
    }
  },
  deleteTeamSuccess(state, data) {
    const index = state.teams.findIndex((t) => t.id === data.teamId);
    state.teams.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
