import store from "@/stores/";

export default [
  {
    path: "",
    name: "module-paw-products",
    component: () => import("@/modules/paw/views/products/Dashboard"),
  },
  {
    path: "create",
    name: "module-paw-products-create",
    component: () => import("@/modules/paw/views/products/ProductForm"),
  },
  {
    path: ":productId",
    component: () => import("@/modules/paw/views/products/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      store
        .dispatch("paw/loadProduct", {
          productId: parseInt(to.params.productId),
        })
        .then(() => {
          store.commit("showHideLoader", false);
          next();
        });
    },
    children: [
      {
        path: "edit",
        name: "module-paw-products-edit",
        props: () => ({
          isEditing: true,
        }),
        component: () => import("@/modules/paw/views/products/ProductForm"),
      },
    ],
  },
];
