import store from "@/stores/";

export default [
  {
    path: "diaries",
    component: () => import("@/modules/sbpm/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("users/loadModule", {
          appId: to.params.id,
          module: "users",
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-sbpm-diary",
        props: () => ({
          type: "office",
        }),
        component: () => import("@/modules/sbpm/views/diaries/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("sbpm/diariesStore/loadDiaries", {
              appId: to.params.id,
              type: "office",
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: ":diaryId",
        name: "module-sbpm-diary-individual",
        component: () => import("@/modules/sbpm/views/diaries/Individual"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("sbpm/diariesStore/loadDiary", {
              appId: to.params.id,
              diaryId: to.params.diaryId,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
    ],
  },
];
