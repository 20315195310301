import axios from "axios";
import { getApiUrl } from "@/utils";

const state = {
  properties: [],
  applications_group: [],
  applications: [],
  archived: [],
  property: {},
};

// getters
const getters = {
  all: (state) => state.properties,
  get: (state) => state.property,
  properties: (state) => state.properties,
  archived: (state) => state.archived,
  applications: (state) => state.applications,
  applications_group: (state) => state.applications_group,
};

// actions
const actions = {
  loadProperties({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/easylets/properties`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadPropertiesSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  changeGroupStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/easylets/forms/groups/${
          data.groupId
        }/status`,
        data: {
          form_status: data.form_status,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("changeGroupStatusSuccess", {
            ...resp.data,
            groupId: data.groupId,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  sendGroupAgentos({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/easylets/forms/groups/${
      data.groupId
    }/send-agentos`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("changeGroupStatusSuccess", {
            ...resp.data,
            groupId: data.groupId,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  changeStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/easylets/forms/${
          data.formId
        }/status`,
        data: {
          form_status: data.form_status,
          comment_text: data.comment_text,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("changeStatusSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  assignFormToGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/easylets/properties/${
          data.propertyId
        }/merge-to-group`,
        data: {
          application_group_id: data.application_group_id,
          application_id: data.application_id,
          form_id: data.form_id,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("assignFormToGroupSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  inviteToGroup({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/easylets/properties/${
      data.propertyId
    }/group/${data.groupId}`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("assignFormToGroupSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadProperty({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/easylets/properties/${
          data.propertyId
        }`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadPropertySuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  savePropertyVisible({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/easylets/properties/${
      data.propertyId
    }/visible`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("savePropertyVisibleSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveProperty({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/easylets/properties`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("savePropertySuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteProperty({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/easylets/properties/${
          data.propertyId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deletePropertySuccess", {
            propertyId: data.propertyId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  restoreProperty({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/easylets/properties/${
          data.propertyId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restorePropertySuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteForm({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/easylets/forms/${data.formId}`,
        method: "DELETE",
      })
        .then((resp) => {
          commit("deleteFormSuccess", {
            formId: data.formId,
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadPropertiesSuccess(state, data) {
    state.properties = data.properties;
    state.archived = data.archived;
  },
  deletePropertySuccess(state, data) {
    const index = state.properties.findIndex(
      (c) => c.property_id === data.propertyId
    );
    state.properties.splice(index, 1);
  },
  deleteFormSuccess(state, data) {
    const index = state.applications.findIndex(
      (c) => c.application_id === data.formId
    );
    state.applications.splice(index, 1);

    if (data.form.application_group_id) {
      const groupIndex = state.applications_group.findIndex(
        (t) =>
          t.application_group_id === parseInt(data.form.application_group_id)
      );

      const applicationIndex = state.applications_group[
        groupIndex
      ].applications.findIndex(
        (i) => i.application_id === parseInt(data.formId)
      );

      state.applications_group[groupIndex].applications.splice(
        applicationIndex,
        1
      );
    }
  },
  restorePropertySuccess(state, data) {
    const index = state.archived.findIndex(
      (c) => c.property_id === data.property.property_id
    );
    state.archived.splice(index, 1);
    state.properties.push(data.property);
  },
  savePropertySuccess(state, data) {
    state.properties.push(data.property);
  },
  savePropertyVisibleSuccess(state, data) {
    const index = state.properties.findIndex(
      (p) => p.property_id === data.property.property_id
    );
    state.properties.splice(index, 1, data.property);
  },
  loadPropertySuccess(state, data) {
    state.property = data.property;
    state.applications = data.applications;
    state.applications_all = data.applications_all;
    state.applications_group = data.applications_group;
  },

  changeStatusSuccess(state, data) {
    if (data.form.application_group_id) {
      const indexGoup = state.applications_group.findIndex(
        (c) => c.application_group_id === data.form.application_group_id
      );

      const index = state.applications_group[indexGoup].applications.findIndex(
        (c) => c.application_id === data.form.application_id
      );

      state.applications_group[indexGoup].applications.splice(
        index,
        1,
        data.form
      );
    } else {
      const index = state.applications.findIndex(
        (c) => c.application_id === data.form.application_id
      );

      state.applications.splice(index, 1, data.form);
    }
  },

  changeGroupStatusSuccess(state, data) {
    const indexGoup = state.applications_group.findIndex(
      (c) => c.application_group_id === data.groupId
    );
    state.applications_group[indexGoup].applications = data.forms;
  },

  assignFormToGroupSuccess(state, data) {
    const index = state.applications.findIndex(
      (c) => c.application_id === data.application.application_id
    );
    state.applications.splice(index, 1);

    if (data.application_merge) {
      const indexMerge = state.applications.findIndex(
        (c) => c.application_id === data.application_merge.application_id
      );
      state.applications.splice(indexMerge, 1);
      state.applications_group.push(data.group);
    } else {
      const indexGoup = state.applications_group.findIndex(
        (c) => c.application_group_id === data.application.application_group_id
      );

      state.applications_group[indexGoup].applications.push(data.application);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
