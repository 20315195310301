import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  courses: [],
  archivedCourses: [],
  events: [],
  archivedEvents: [],
  accommodations: [],
  archivedAccommodations: [],
  sightseeings: [],
  archivedSightseeings: [],
  restaurants: [],
  archivedRestaurants: [],
  restaurantRegions: [],
  archivedRestaurantRegions: [],
  agents: [],
  archivedAgents: [],
  suppliers: [],
  archivedSuppliers: [],
  images: [],
  archivedImages: [],
  imageCategories: []
};

// getters
const getters = {
  transportSuppliers: (state) => {
    return state.suppliers.filter((s) => s.type === "transport");
  }
};

// actions
const actions = {
  loadCourses({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/courses`,
        method: "GET"
      })
        .then((resp) => {
          commit("loadCoursesSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveCourse({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/drumgolf/admin/courses/${data.courseId}`
      : `${getApiUrl(data.appId)}/module/drumgolf/admin/courses`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          ...data.fields
        },
        method: "POST"
      })
        .then((resp) => {
          commit("saveCourseSuccess", { ...resp.data, isEditing: data.isEditing });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteCourse({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/courses/${data.courseId}`,
        method: "DELETE"
      })
        .then(() => {
          commit("deleteCourseSuccess", { courseId: data.courseId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  restoreCourse({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/courses/${data.courseId}/restore`,
        method: "POST"
      })
        .then((resp) => {
          commit("restoreCourseSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadAccommodations({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/accommodations`,
        method: "GET"
      })
        .then((resp) => {
          commit("loadAccommodationsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveAccommodation({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/drumgolf/admin/accommodations/${data.accommodationId}`
      : `${getApiUrl(data.appId)}/module/drumgolf/admin/accommodations`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: { ...data.fields },
        method: "POST"
      })
        .then((resp) => {
          commit("saveAccommodationSuccess", { ...resp.data, isEditing: data.isEditing });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteAccommodation({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/accommodations/${data.accommodationId}`,
        method: "DELETE"
      })
        .then(() => {
          commit("deleteAccommodationSuccess", { accommodationId: data.accommodationId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  restoreAccommodation({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/accommodations/${data.accommodationId}/restore`,
        method: "POST"
      })
        .then((resp) => {
          commit("restoreAccommodationSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadSightseeings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/sightseeings`,
        method: "GET"
      })
        .then((resp) => {
          commit("loadSightseeingsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveSightseeing({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/drumgolf/admin/sightseeings/${data.sightseeingId}`
      : `${getApiUrl(data.appId)}/module/drumgolf/admin/sightseeings`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          ...data.fields
        },
        method: "POST"
      })
        .then((resp) => {
          commit("saveSightseeingSuccess", { ...resp.data, isEditing: data.isEditing });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteSightseeing({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/sightseeings/${data.sightseeingId}`,
        method: "DELETE"
      })
        .then(() => {
          commit("deleteSightseeingSuccess", { sightseeingId: data.sightseeingId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  restoreSightseeing({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/sightseeings/${data.sightseeingId}/restore`,
        method: "POST"
      })
        .then((resp) => {
          commit("restoreSightseeingSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadEvents({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/events`,
        method: "GET"
      })
        .then((resp) => {
          commit("loadEventsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveEvent({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/drumgolf/admin/events/${data.eventId}`
      : `${getApiUrl(data.appId)}/module/drumgolf/admin/events`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          ...data.fields
        },
        method: "POST"
      })
        .then((resp) => {
          commit("saveEventSuccess", { ...resp.data, isEditing: data.isEditing });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteEvent({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/events/${data.eventId}`,
        method: "DELETE"
      })
        .then(() => {
          commit("deleteEventSuccess", { eventId: data.eventId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  restoreEvent({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/events/${data.eventId}/restore`,
        method: "POST"
      })
        .then((resp) => {
          commit("restoreEventSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadRestaurants({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/restaurants`,
        method: "GET"
      })
        .then((resp) => {
          commit("loadRestaurantsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveRestaurant({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/drumgolf/admin/restaurants/${data.restaurantId}`
      : `${getApiUrl(data.appId)}/module/drumgolf/admin/restaurants`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          ...data.fields
        },
        method: "POST"
      })
        .then((resp) => {
          commit("saveRestaurantSuccess", { ...resp.data, isEditing: data.isEditing });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteRestaurant({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/restaurants/${data.restaurantId}`,
        method: "DELETE"
      })
        .then(() => {
          commit("deleteRestaurantSuccess", { restaurantId: data.restaurantId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  restoreRestaurant({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/restaurants/${data.restaurantId}/restore`,
        method: "POST"
      })
        .then((resp) => {
          commit("restoreRestaurantSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadRestaurantRegions({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/restaurants/regions`,
        method: "GET"
      })
        .then((resp) => {
          commit("loadRestaurantRegionsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveRestaurantRegion({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/drumgolf/admin/restaurants/regions/${data.restaurantRegionId}`
      : `${getApiUrl(data.appId)}/module/drumgolf/admin/restaurants/regions`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          ...data.fields
        },
        method: "POST"
      })
        .then((resp) => {
          commit("saveRestaurantRegionSuccess", { ...resp.data, isEditing: data.isEditing });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteRestaurantRegion({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/restaurants/regions/${data.restaurantRegionId}`,
        method: "DELETE"
      })
        .then(() => {
          commit("deleteRestaurantRegionSuccess", { restaurantRegionId: data.restaurantRegionId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  restoreRestaurantRegion({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/restaurants/regions/${data.restaurantRegionId}/restore`,
        method: "POST"
      })
        .then((resp) => {
          commit("restoreRestaurantRegionSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadSuppliers({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/suppliers`,
        method: "GET"
      })
        .then((resp) => {
          commit("loadSuppliersSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveSupplier({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/drumgolf/admin/suppliers/${data.supplierId}`
      : `${getApiUrl(data.appId)}/module/drumgolf/admin/suppliers`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          ...data.fields
        },
        method: "POST"
      })
        .then((resp) => {
          commit("saveSupplierSuccess", { ...resp.data, isEditing: data.isEditing });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteSupplier({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/suppliers/${data.supplierId}`,
        method: "DELETE"
      })
        .then(() => {
          commit("deleteSupplierSuccess", { supplierId: data.supplierId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  restoreSupplier({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/suppliers/${data.supplierId}/restore`,
        method: "POST"
      })
        .then((resp) => {
          commit("restoreSupplierSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadImages({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/images`,
        method: "GET"
      })
        .then((resp) => {
          commit("loadImagesSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveImage({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/drumgolf/admin/images/${data.imageId}`
      : `${getApiUrl(data.appId)}/module/drumgolf/admin/images`;

    let formData = new FormData();
    formData.append("name", data.fields.name);
    formData.append("is_drumgolf", data.fields.is_drumgolf);
    formData.append("is_drumscot", data.fields.is_drumscot);
    formData.append("categories", data.fields.categories);

    if (data.isEditing === false) {
      formData.append("image", data.fields.image);
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
        config: {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }
      })
        .then((resp) => {
          commit("saveImageSuccess", { ...resp.data, isEditing: data.isEditing });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveImageCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/images/categories/${data.categoryId}`,
        data: {
          ...data.fields
        },
        method: "POST"
      })
        .then((resp) => {
          commit("saveImageCategorySuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteImage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/images/${data.imageId}`,
        method: "DELETE"
      })
        .then(() => {
          commit("deleteImageSuccess", { imageId: data.imageId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    })
  },

  restoreImage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/images/${data.imageId}/restore`,
        method: "POST"
      })
        .then((resp) => {
          commit("restoreImageSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadAgents({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/agents`,
        method: "GET"
      })
        .then((resp) => {
          commit("loadAgentsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveAgent({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/drumgolf/admin/agents/${data.agentId}`
      : `${getApiUrl(data.appId)}/module/drumgolf/admin/agents`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          ...data.fields
        },
        method: "POST"
      })
        .then((resp) => {
          commit("saveAgentSuccess", { ...resp.data, isEditing: data.isEditing });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteAgent({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/agents/${data.agentId}`,
        method: "DELETE"
      })
        .then(() => {
          commit("deleteAgentSuccess", { agentId: data.agentId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  restoreAgent({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/admin/agents/${data.agentId}/restore`,
        method: "POST"
      })
        .then((resp) => {
          commit("restoreAgentSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadCoursesSuccess(state, data) {
    state.courses = data.courses;
    state.archivedCourses = data.archived;
  },
  saveCourseSuccess(state, data) {
    if (data.isEditing) {
      const index = state.courses.findIndex((c) => c.id === data.course.id);
      state.courses.splice(index, 1, data.course);
    } else {
      state.courses.push(data.course);
    }
  },
  deleteCourseSuccess(state, data) {
    const index = state.courses.findIndex((c) => c.id === data.courseId);
    state.archivedCourses.push(state.courses[index]);
    state.courses.splice(index, 1);
  },
  restoreCourseSuccess(state, data) {
    const index = state.archivedCourses.findIndex((c) => c.id === data.course.id);
    state.archivedCourses.splice(index, 1);
    state.courses.push(data.course);
  },

  loadAccommodationsSuccess(state, data) {
    state.accommodations = data.accommodations;
    state.archivedAccommodations = data.archived;
  },
  saveAccommodationSuccess(state, data) {
    if (data.isEditing) {
      const index = state.accommodations.findIndex((a) => a.id === data.accommodation.id);
      state.accommodations.splice(index, 1, data.accommodation);
    } else {
      state.accommodations.push(data.accommodation);
    }
  },
  deleteAccommodationSuccess(state, data) {
    const index = state.accommodations.findIndex((a) => a.id === data.accommodationId);
    state.archivedAccommodations.push(state.accommodations[index]);
    state.accommodations.splice(index, 1);
  },
  restoreAccommodationSuccess(state, data) {
    const index = state.archivedAccommodations.findIndex((a) => a.id === data.accommodation.id);
    state.archivedAccommodations.splice(index, 1);
    state.accommodations.push(data.accommodation);
  },

  loadSightseeingsSuccess(state, data) {
    state.sightseeings = data.sightseeings;
    state.archivedSightseeings = data.archived;
  },
  saveSightseeingSuccess(state, data) {
    if (data.isEditing) {
      const index = state.sightseeings.findIndex((s) => s.id === data.sightseeing.id);
      state.sightseeings.splice(index, 1, data.sightseeing);
    } else {
      state.sightseeings.push(data.sightseeing);
    }
  },
  deleteSightseeingSuccess(state, data) {
    const index = state.sightseeings.findIndex((s) => s.id === data.sightseeingId);
    state.archivedSightseeings.push(state.sightseeings[index]);
    state.sightseeings.splice(index, 1);
  },
  restoreSightseeingSuccess(state, data) {
    const index = state.archivedSightseeings.findIndex((s) => s.id === data.sightseeing.id);
    state.archivedSightseeings.splice(index, 1);
    state.sightseeings.push(data.sightseeing);
  },

  loadEventsSuccess(state, data) {
    state.events = data.events;
    state.archivedEvents = data.archived;
  },
  saveEventSuccess(state, data) {
    if (data.isEditing) {
      const index = state.events.findIndex((e) => e.id === data.event.id);
      state.events.splice(index, 1, data.event);
    } else {
      state.events.push(data.event);
    }
  },
  deleteEventSuccess(state, data) {
    const index = state.events.findIndex((e) => e.id === data.eventId);
    state.archivedEvents.push(state.events[index]);
    state.events.splice(index, 1);
  },
  restoreEventSuccess(state, data) {
    const index = state.archivedEvents.findIndex((e) => e.id === data.event.id);
    state.archivedEvents.splice(index, 1);
    state.events.push(data.event);
  },

  loadRestaurantsSuccess(state, data) {
    state.restaurants = data.restaurants;
    state.archivedRestaurants = data.archived;
  },
  saveRestaurantSuccess(state, data) {
    if (data.isEditing) {
      const index = state.restaurants.findIndex((r) => r.id === data.restaurant.id);
      state.restaurants.splice(index, 1, data.restaurant);
    } else {
      state.restaurants.push(data.restaurant);
    }
  },
  deleteRestaurantSuccess(state, data) {
    const index = state.restaurants.findIndex((r) => r.id === data.restaurantId);
    state.archivedRestaurants.push(state.restaurants[index]);
    state.restaurants.splice(index, 1);
  },
  restoreRestaurantSuccess(state, data) {
    const index = state.archivedRestaurants.findIndex((r) => r.id === data.restaurant.id);
    state.archivedRestaurants.splice(index, 1);
    state.restaurants.push(data.restaurant);
  },

  loadRestaurantRegionsSuccess(state, data) {
    state.restaurantRegions = data.regions;
    state.archivedRestaurantRegions = data.archived;
  },
  saveRestaurantRegionSuccess(state, data) {
    if (data.isEditing) {
      const index = state.restaurantRegions.findIndex((r) => r.id === data.region.id);
      state.restaurantRegions.splice(index, 1, data.region);
    } else {
      state.restaurantRegions.push(data.region);
    }
  },
  deleteRestaurantRegionSuccess(state, data) {
    const index = state.restaurantRegions.findIndex((r) => r.id === data.restaurantRegionId);
    state.archivedRestaurantRegions.push(state.restaurantRegions[index]);
    state.restaurantRegions.splice(index, 1);
  },
  restoreRestaurantRegionSuccess(state, data) {
    const index = state.archivedRestaurantRegions.findIndex((r) => r.id === data.region.id);
    state.archivedRestaurantRegions.splice(index, 1);
    state.restaurantRegions.push(data.region);
  },

  loadSuppliersSuccess(state, data) {
    state.suppliers = data.suppliers;
    state.archivedSuppliers = data.archived;
  },
  saveSupplierSuccess(state, data) {
    if (data.isEditing) {
      const index = state.suppliers.findIndex((s) => s.id === data.supplier.id);
      state.suppliers.splice(index, 1, data.supplier);
    } else {
      state.suppliers.push(data.supplier);
    }
  },
  deleteSupplierSuccess(state, data) {
    const index = state.suppliers.findIndex((s) => s.id === data.supplierId);
    state.archivedSuppliers.push(state.suppliers[index]);
    state.suppliers.splice(index, 1);
  },
  restoreSupplierSuccess(state, data) {
    const index = state.archivedSuppliers.findIndex((s) => s.id === data.supplier.id);
    state.archivedSuppliers.splice(index, 1);
    state.suppliers.push(data.supplier);
  },

  loadImagesSuccess(state, data) {
    state.images = data.images;
    state.archivedImages = data.archived;
    state.imageCategories = data.categories
  },
  saveImageSuccess(state, data) {
    if (data.isEditing) {
      const index = state.images.findIndex((i) => i.id === data.image.id);
      state.images.splice(index, 1, data.image);
    } else {
      state.images.push(data.image);
    }
  },
  saveImageCategorySuccess(state, data) {
    const index = state.imageCategories.findIndex((c) => c.id === data.category.id);
    state.imageCategories.splice(index, 1, data.category);
  },
  deleteImageSuccess(state, data) {
    const index = state.images.findIndex((i) => i.id === data.imageId);
    state.archivedImages.push(state.images[index]);
    state.images.splice(index, 1);
  },
  restoreImageSuccess(state, data) {
    const index = state.archivedImages.findIndex((i) => i.id === data.image.id);
    state.archivedImages.splice(index, 1);
    state.images.push(data.image);
  },

  loadAgentsSuccess(state, data) {
    state.agents = data.agents;
    state.archivedAgents = data.archived;
  },
  saveAgentSuccess(state, data) {
    if (data.isEditing) {
      const index = state.agents.findIndex((c) => c.id === data.agent.id);
      state.agents.splice(index, 1, data.agent);
    } else {
      state.agents.push(data.agent);
    }
  },
  deleteAgentSuccess(state, data) {
    const index = state.agents.findIndex((i) => i.id === data.agentId);
    state.archivedAgents.push(state.agents[index]);
    state.agents.splice(index, 1);
  },
  restoreAgentSuccess(state, data) {
    const index = state.archivedAgents.findIndex((i) => i.id === data.agent.id);
    state.archivedAgents.splice(index, 1);
    state.agents.push(data.agent);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
