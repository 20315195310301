import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  bankSortCode: null,
  bankAccountNumber: null,
  ibanNumber: null,
  vat: null,
};

// getters
const getters = {};

// actions
const actions = {
  loadSettings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/settings`,
        method: "GET",
      })
        .then((resp) => {
          commit("setSettings", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveSettings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/settings`,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("setSettings", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  setSettings(state, data) {
    state.bankSortCode = data.bank_sort_code;
    state.bankAccountNumber = data.bank_account_number;
    state.ibanNumber = data.iban_number;
    state.vat = data.vat;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
