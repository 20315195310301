import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  tasks: [],
  task: {},
};

// getters
const getters = {
  all: (state) => state.tasks,
  get: (state) => state.task,
};

// actions
const actions = {
  loadTasks({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/tasks`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadTasksSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },
  loadTask({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/tasks/${data.taskId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadTaskSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  deleteTask({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/lhl/tasks/${data.taskId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteTaskSuccess", { taskId: data.taskId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  saveCompleteTask({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/lhl/tasks/${
      data.taskId
    }/complete`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveCompleteTaskSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveTask({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/lhl/tasks/${data.taskId}`
      : `${getApiUrl(data.appId)}/module/lhl/tasks`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveTaskSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadTasksSuccess(state, data) {
    state.tasks = data.tasks;
  },

  loadTaskSuccess(state, data) {
    state.task = data.task;
  },

  saveTaskSuccess(state, data) {
    if (data.isEditing) {
      state.task = data.task;

      const index = state.tasks.findIndex((c) => c.id === data.task.id);
      state.tasks.splice(index, 1, data.task);
    } else {
      state.tasks.push(data.task);
    }
  },
  saveCompleteTaskSuccess(state, data) {
    const index = state.tasks.findIndex((p) => p.id === data.task.id);
    state.tasks.splice(index, 1, data.task);
  },

  deleteTaskSuccess(state, data) {
    const index = state.tasks.findIndex((p) => p.id === data.taskId);
    state.tasks.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
