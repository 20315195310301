import store from "@/stores/";

export default [
  {
    path: "admin",
    component: () => import("@/modules/drum/views/Template"),
    children: [
      {
        path: "",
        name: "module-drum-admin",
        component: () => import("@/modules/drum/views/admin/Dashboard"),
      },
      {
        path: "courses",
        component: () => import("@/modules/drum/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("drum/admin/loadCourses", {
              appId: to.params.id,
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
        children: [
          {
            path: "",
            name: "module-drum-admin-courses",
            component: () => import("@/modules/drum/views/admin/Courses"),
          },
          {
            path: "archived",
            name: "module-drum-admin-courses-archived",
            component: () =>
              import("@/modules/drum/views/admin/CoursesArchived"),
          },
        ],
      },
      {
        path: "accommodation",
        component: () => import("@/modules/drum/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("drum/admin/loadAccommodations", {
              appId: to.params.id,
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
        children: [
          {
            path: "",
            name: "module-drum-admin-accommodation",
            component: () => import("@/modules/drum/views/admin/Accommodation"),
          },
          {
            path: "archived",
            name: "module-drum-admin-accommodation-archived",
            component: () =>
              import("@/modules/drum/views/admin/AccommodationArchived"),
          },
        ],
      },
      {
        path: "sightseeing",
        component: () => import("@/modules/drum/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("drum/admin/loadSightseeings", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: [
          {
            path: "",
            name: "module-drum-admin-sightseeing",
            component: () => import("@/modules/drum/views/admin/Sightseeing"),
          },
          {
            path: "archived",
            name: "module-drum-admin-sightseeing-archived",
            component: () =>
              import("@/modules/drum/views/admin/SightseeingArchived"),
          },
        ],
      },
      {
        path: "restaurants",
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("drum/admin/loadRestaurants", {
              appId: to.params.id,
            }),
            store.dispatch("drum/admin/loadRestaurantRegions", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        component: () => import("@/modules/drum/views/Template"),
        children: [
          {
            path: "",
            name: "module-drum-admin-restaurants",
            component: () => import("@/modules/drum/views/admin/Restaurants"),
          },
          {
            path: "archived",
            name: "module-drum-admin-restaurants-archived",
            component: () =>
              import("@/modules/drum/views/admin/RestaurantsArchived"),
          },
          {
            path: "regions",
            component: () => import("@/modules/drum/views/Template"),
            children: [
              {
                path: "",
                name: "module-drum-admin-restaurant-regions",
                component: () =>
                  import("@/modules/drum/views/admin/RestaurantRegions"),
              },
              {
                path: "archived",
                name: "module-drum-admin-restaurant-regions-archived",
                component: () =>
                  import(
                    "@/modules/drum/views/admin/RestaurantRegionsArchived"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "images",
        component: () => import("@/modules/drum/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("drum/admin/loadImages", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: [
          {
            path: "",
            name: "module-drum-admin-images",
            component: () => import("@/modules/drum/views/admin/Images"),
          },
          {
            path: "archived",
            name: "module-drum-admin-images-archived",
            component: () =>
              import("@/modules/drum/views/admin/ImagesArchived"),
          },
          {
            path: "categories",
            name: "module-drum-admin-images-categories",
            component: () =>
              import("@/modules/drum/views/admin/ImageCategories"),
          },
        ],
      },
      {
        path: "events",
        component: () => import("@/modules/drum/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("drum/admin/loadEvents", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: [
          {
            path: "",
            name: "module-drum-admin-events",
            component: () => import("@/modules/drum/views/admin/Events"),
          },
          {
            path: "archived",
            name: "module-drum-admin-events-archived",
            component: () =>
              import("@/modules/drum/views/admin/EventsArchived"),
          },
        ],
      },
      {
        path: "suppliers",
        component: () => import("@/modules/drum/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("drum/admin/loadSuppliers", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: [
          {
            path: "",
            name: "module-drum-admin-suppliers",
            component: () => import("@/modules/drum/views/admin/Suppliers"),
          },
          {
            path: "archived",
            name: "module-drum-admin-suppliers-archived",
            component: () =>
              import("@/modules/drum/views/admin/SuppliersArchived"),
          },
        ],
      },
      {
        path: "agents",
        component: () => import("@/modules/drum/views/Template"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("drum/admin/loadAgents", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: [
          {
            path: "",
            name: "module-drum-admin-agents",
            component: () => import("@/modules/drum/views/admin/Agents"),
          },
          {
            path: "archived",
            name: "module-drum-admin-agents-archived",
            component: () =>
              import("@/modules/drum/views/admin/AgentsArchived"),
          },
        ],
      },
    ],
  },
];
