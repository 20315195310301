import store from "@/stores/";

export default [
  {
    path: "customers",
    component: () => import("@/modules/melrose/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("melrose/customersStore/loadCustomers", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-melrose-customers",
        component: () => import("@/modules/melrose/views/customers/Dashboard"),
      },
      {
        path: "all",
        name: "module-melrose-customers-customers-all",
        component: () => import("@/modules/melrose/views/customers/Dashboard"),
      },
      {
        path: "buyers",
        name: "module-melrose-customers-buyers",
        component: () => import("@/modules/melrose/views/customers/Buyers"),
      },
      {
        path: "sellers",
        name: "module-melrose-customers-sellers",
        component: () => import("@/modules/melrose/views/customers/Sellers"),
      },
      {
        path: "archived",
        name: "module-melrose-customers-archived",
        props: () => ({
          pageTitle: "Customers",
          parentRouteName: "module-melrose-customers",
        }),
        component: () => import("@/modules/melrose/views/customers/Archived"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("melrose/customersStore/loadCustomers", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: ":customerId",
        name: "module-melrose-customers-individual",
        component: () => import("@/modules/melrose/views/customers/Individual"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("melrose/customersStore/loadCustomer", {
              appId: to.params.id,
              customerId: to.params.customerId,
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
      },
    ],
  },
];
