import store from "@/stores/";

export default [
  {
    path: "venue-hires",
    component: () => import("@/modules/craigtoun/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("craigtoun/venuesStore/loadVenues", {
          appId: to.params.id,
        }),
        store.dispatch("craigtoun/venuesStore/loadSeasons", {
          appId: to.params.id,
        }),
        store.dispatch("craigtoun/customersStore/loadCustomers", {
          appId: to.params.id,
        }),
        store.dispatch("craigtoun/venuesStore/loadAllBookings", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "list",
        component: () => import("@/modules/craigtoun/views/Template"),
        children: [
          {
            path: "",
            name: "module-craigtoun-venues-list-all",
            component: () =>
              import("@/modules/craigtoun/views/venues/bookings/List"),
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("craigtoun/venuesStore/loadAllBookings", {
                  appId: to.params.id,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
          },
          {
            path: "archived",
            name: "module-craigtoun-venues-list-archived",
            component: () =>
              import("@/modules/craigtoun/views/venues/bookings/Archived"),
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("craigtoun/venuesStore/loadAllBookings", {
                  appId: to.params.id,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
          },
          {
            path: "past",
            name: "module-craigtoun-venues-list-past",
            component: () =>
              import("@/modules/craigtoun/views/venues/bookings/Past"),
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("craigtoun/venuesStore/loadAllBookings", {
                  appId: to.params.id,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
          },
        ],
      },
      {
        path: "",
        name: "module-craigtoun-venues",
        component: () => import("@/modules/craigtoun/views/venues/Dashboard"),
      },
      {
        path: "venues",
        component: () => import("@/modules/craigtoun/views/Template"),
        children: [
          {
            path: "",
            name: "module-craigtoun-venues-list",
            component: () => import("@/modules/craigtoun/views/venues/List"),
          },
          {
            path: ":venueId",
            component: () => import("@/modules/craigtoun/views/Template"),
            children: [
              {
                path: "",
                name: "module-craigtoun-venues-individual",
                component: () =>
                  import("@/modules/craigtoun/views/venues/Individual"),
                beforeEnter: (to, from, next) => {
                  store.commit("showHideLoader", true);
                  Promise.all([
                    store.dispatch("craigtoun/venuesStore/loadVenue", {
                      appId: to.params.id,
                      venueId: to.params.venueId,
                    }),
                    store.dispatch("craigtoun/venuesStore/loadBookings", {
                      appId: to.params.id,
                      venueId: to.params.venueId,
                    }),
                    store.dispatch("craigtoun/venuesStore/loadExtras", {
                      appId: to.params.id,
                    }),
                  ]).then(() => {
                    store.commit("showHideLoader", false);
                    next();
                  });
                },
              },
              {
                path: "archived",
                name: "module-craigtoun-venues-individual-archived",
                component: () =>
                  import("@/modules/craigtoun/views/venues/Archived"),
                beforeEnter: (to, from, next) => {
                  store.commit("showHideLoader", true);
                  store.dispatch("craigtoun/venuesStore/loadVenue", {
                    appId: to.params.id,
                    venueId: to.params.venueId,
                  }),
                    store
                      .dispatch("craigtoun/venuesStore/loadBookings", {
                        appId: to.params.id,
                        venueId: to.params.venueId,
                      })
                      .then(() => {
                        store.commit("showHideLoader", false);
                        next();
                      });
                },
              },
            ],
          },
        ],
      },
      {
        path: "seasons",
        name: "module-craigtoun-venues-seasons",
        component: () => import("@/modules/craigtoun/views/venues/Seasons"),
      },
    ],
  },
];
