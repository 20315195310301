import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  pages: [],
  page: {},
};

// getters
const getters = {};

// actions
const actions = {
  loadPages({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/page-content`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadPagesSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadPage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/page-content/${data.pageId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadPageSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  savePage({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/page-content/${data.pageId}`
      : `${getApiUrl(data.appId)}/module/page-content`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("savePageSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  uploadImage({ commit }, data) {
    let formData = new FormData();
    formData.append("file", data.file);

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${getApiUrl(data.appId)}/module/page-content/${data.pageId}/items/${
            data.itemId
          }/image`,
          formData
        )
        .then((resp) => {
          commit("uploadImageSuccess");
          resolve(resp);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  removeImage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/page-content/${
          data.pageId
        }/items/${data.itemId}/image`,
        method: "DELETE",
      })
        .then((resp) => {
          commit("removeImageSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  uploadVideo({ commit }, data) {
    let formData = new FormData();
    formData.append("file", data.file);

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${getApiUrl(data.appId)}/module/page-content/${data.pageId}/items/${
            data.itemId
          }/video`,
          formData
        )
        .then((resp) => {
          commit("uploadVideoSuccess");
          resolve(resp);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  removeVideo({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/page-content/${
          data.pageId
        }/items/${data.itemId}/video`,
        method: "DELETE",
      })
        .then((resp) => {
          commit("removeVideoSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadPagesSuccess(state, data) {
    state.pages = data.pages;
  },
  loadPageSuccess(state, data) {
    state.page = data.page;
  },
  savePageSuccess(state, data) {
    if (data.isEditing) {
      const index = state.pages.findIndex((p) => p.id === data.page.id);
      state.pages.splice(index, 1, data.page);
      state.page = data.page;
    } else {
      state.pages.push(data.page);
    }
  },
  uploadImageSuccess() {},
  removeImageSuccess(state, data) {
    const pageIndex = state.pages.findIndex((p) => p.id === data.item.page_id);
    const itemIndex = state.pages[pageIndex].items.findIndex(
      (i) => i.id === data.item.id
    );
    state.pages[pageIndex].items.splice(itemIndex, 1, data.item);
    state.page.items.splice(itemIndex, 1, data.item);
  },
  uploadVideoSuccess() {},
  removeVideoSuccess(state, data) {
    const pageIndex = state.pages.findIndex((p) => p.id === data.item.page_id);
    const itemIndex = state.pages[pageIndex].items.findIndex(
      (i) => i.id === data.item.id
    );
    state.pages[pageIndex].items.splice(itemIndex, 1, data.item);
    state.page.items.splice(itemIndex, 1, data.item);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
