export default [
  {
    path: "calendar",
    component: () => import("@/modules/arnprior/views/Template"),
    children: [
      {
        path: "",
        name: "module-arnprior-calendar",
        component: () => import("@/modules/arnprior/views/calendar/Calendar"),
      },
    ],
  },
];
