import store from "@/stores/";

export default [
  {
    path: "orders",
    component: () => import("@/modules/arnprior/views/Template"),
    children: [
      {
        path: "",
        name: "module-arnprior-orders",
        component: () => import("@/modules/arnprior/views/orders/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          store
            .dispatch("arnprior/ordersStore/loadOrders", {
              appId: to.params.id,
            })
            .then(() => {
              store.commit("showHideLoader", false);
              next();
            });
        },
      },
      {
        path: "vouchers",
        name: "module-arnprior-orders-arnprior-orders-vouchers",
        component: () =>
          import("@/modules/arnprior/views/orders/vouchers/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("arnprior/vouchersStore/loadVouchers", {
              appId: to.params.id,
            }),
            store.dispatch("arnprior/customersStore/loadCustomers", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "swim-badges",
        name: "module-arnprior-orders-arnprior-orders-swim-badges",
        component: () =>
          import("@/modules/arnprior/views/orders/swim-badges/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("arnprior/ordersStore/loadSwimBadgeOrders", {
              appId: to.params.id,
            }),
            store.dispatch("arnprior/ordersStore/loadSwimBadges", {
              appId: to.params.id,
            }),
            store.dispatch("arnprior/customersStore/loadCustomers", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: ":orderId",
        name: "module-arnprior-orders-individual",
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("arnprior/ordersStore/loadOrder", {
              appId: to.params.id,
              orderId: to.params.orderId,
            }),
            store.dispatch("arnprior/bookingsStore/loadExtras", {
              appId: to.params.id,
            }),
            store.dispatch("arnprior/podsStore/loadPods", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        component: () => import("@/modules/arnprior/views/orders/Individual"),
      },
    ],
  },
];
