import CoursesRoutes from "./courses.routes";
import MembersRoutes from "./members.routes";
import MonthlyTemplatesRoutes from "./monthly-templates.routes";
import ProductsRoutes from "./products.routes";

export default [
  {
    path: "flightmode",
    component: () => import("@/modules/flightmode/views/Template"),
    children: [
      ...MembersRoutes,
      ...MonthlyTemplatesRoutes,
      ...CoursesRoutes,
      ...ProductsRoutes
    ]
  }
];