import { appHasIntegration, getApiUrl } from "@/utils";

import axios from "axios";

// initial state
const state = {
  users: [],
  capacity: {},
};

// getters
const getters = {
  all: state => state.users,
  getCapacityMonths: state => state.capacity.months,
  getCapacityUsers: state => state.capacity.team_members,
  getTableTitles: state => state.capacity.table_titles,
  getCapacityTotals: state => state.capacity.totals,
  getCapacityExpectations: state => state.capacity.expected_totals,
};

// actions
const actions = {
  loadUsers({ commit }, data) {
    if (appHasIntegration("timetastic", data.appId) === false) {
      return Promise().resolve();
    }

    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/integrations/timetastic/users`,
        method: "GET",
      })
        .then(resp => {
          commit("loadUsersSuccess", resp.data);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/integrations/timetastic/users/${
          data.timetasticUserId
        }`,
        data: {
          userId: data.userId,
        },
        method: "POST",
      })
        .then(() => {
          commit("team/updateTimetasticUserId", data, { root: true });
          resolve();
        })
        .catch(() => reject());
    });
  },
  loadResourcing({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/team/resourcing`,
      })
        .then(resp => {
          commit("loadResourcingSuccess", resp.data);
          resolve(resp);
        })
        .catch(err => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadUsersSuccess(state, data) {
    state.users = data.users;
  },
  loadResourcingSuccess(state, data) {
    state.capacity = data.capacity;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
