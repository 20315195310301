import BiosRoutes from "./bios.routes";
import TeamsRoutes from "./teams.routes";
import TestimonialsRoutes from "./testimonials.routes";

export default [
  {
    path: "includem",
    component: () => import("@/modules/includem/views/Template"),
    children: [...BiosRoutes, ...TestimonialsRoutes, ...TeamsRoutes],
  },
];
