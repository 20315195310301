// import axios from "axios";

import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  properties: [],
  submissions: [],
  archived: [],
  valuation_requests: [],
  valuation_requests_archive: [],
  notes_of_interest: [],
  notes_of_interest_archived: [],
  offers: [],
  offers_archived: [],
  managers: [],
  viewings: [],
  viewings_archived: [],
  property: {},
};

// getters
const getters = {
  all: (state) => state.properties,
  get: (state) => state.property,
  submissions: (state) => state.submissions,
  archived: (state) => state.archived,
  managers: (state) => state.managers,
  viewings: (state) => state.viewings,
  notes_of_interest: (state) => state.notes_of_interest,
  offers: (state) => state.offers,
  valuation_requests: (state) => state.valuation_requests,
  valuation_requests_archive: (state) => state.valuation_requests_archive,
  notes_of_interest_archive: (state) => state.notes_of_interest_archived,
  offers_archive: (state) => state.offers_archived,
  viewings_archived: (state) => state.viewings_archived,
};

// actions
const actions = {
  loadProperty({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/properties/${
          data.propertyId
        }`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadPropertySuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadViewings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/properties/viewings`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadViewingsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadOffers({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/properties/offers`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadOffersSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadValuationRequests({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(
          data.appId
        )}/module/melrose/properties/valuation-requests`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadValuationRequestsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadNoteOfInterest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(
          data.appId
        )}/module/melrose/properties/notes-of-interest`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadNotesOfInterestSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  savePrice({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/melrose/properties/${
          data.propertyId
        }/prices/${data.priceId}`
      : `${getApiUrl(data.appId)}/module/melrose/properties/${
          data.propertyId
        }/prices`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("savePriceSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveViewing({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/melrose/properties/viewings/${
          data.viewingId
        }`
      : `${getApiUrl(data.appId)}/module/melrose/properties/viewings`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveViewingSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
            fromProperty: data.fromProperty,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  savePropertyDetails({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/melrose/properties/${
      data.propertyId
    }/details`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("savePropertyDetailsSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  savePropertyDescription({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/melrose/properties/${
      data.propertyId
    }/description`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("savePropertyDescriptionSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveFloorPlan({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/melrose/properties/${
      data.propertyId
    }/floor-plan`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("savePropertySuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  savePdfFields({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/melrose/properties/${
      data.propertyId
    }/pdf-fields`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (fields[field] === null) {
        formData.append(field, "");
      } else if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("savePropertySuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveNotes({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/melrose/properties/${
      data.propertyId
    }/notes`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("savePropertySuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  deleteProperty({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/properties/${
          data.propertyId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deletePropertySuccess", {
            propertyId: data.propertyId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteImage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/properties/${
          data.propertyId
        }/image/${data.imageId}`,
        method: "DELETE",
      })
        .then((resp) => {
          commit("deleteImageSuccess", {
            imageId: data.imageId,
            ...resp.data,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveVideo({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/melrose/properties/${
      data.propertyId
    }/video`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("savePropertySuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  savePropertyRoomDimentions({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/melrose/properties/${
      data.propertyId
    }/room-dimentions`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (field === "room_dimentions") {
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(`room_dimentions[${i}][name]`, data[i]["name"]);
        }
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("savePropertySuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  savePropertyFeatures({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/melrose/properties/${
      data.propertyId
    }/features`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (
        fields[field] === null ||
        typeof fields[field] === "undefined"
      ) {
        formData.append(field, "");
      } else if (field === "property_features") {
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(`property_features[${i}][name]`, data[i]["name"]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("savePropertySuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadPropertyPdf(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/properties/${
          data.propertyId
        }/pdf`,
        method: "POST",
        responseType: "arraybuffer",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveProperty({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/melrose/properties/${data.propertyId}`
      : `${getApiUrl(data.appId)}/module/melrose/properties`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (
        fields[field] === null ||
        typeof fields[field] === "undefined"
      ) {
        formData.append(field, "");
      } else if (typeof fields[field] === "object") {
        if (fields[field] instanceof File) {
          formData.append(field, fields[field]);
        }
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(field + "[]", data[i]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("savePropertySuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveAddress({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/melrose/addresses/${
      data.addressId
    }`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveAddressSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveNotesOfInterest({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/melrose/properties/notes-of-interest/${
          data.noteOfInterestId
        }`
      : `${getApiUrl(data.appId)}/module/melrose/properties/notes-of-interest`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveNotesOfInterestSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
            fromProperty: data.fromProperty,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveValuationRequest({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(
          data.appId
        )}/module/melrose/properties/valuation-requests/${
          data.valuationRequestId
        }`
      : `${getApiUrl(data.appId)}/module/melrose/properties/valuation-requests`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveValuationRequestSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveOffer({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/melrose/properties/offers/${
          data.offerId
        }`
      : `${getApiUrl(data.appId)}/module/melrose/properties/offers`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveOfferSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  savePdfImages({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/melrose/properties/${
      data.propertyId
    }/pdf-images`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else if (typeof fields[field] === "object") {
        const data = fields[field];

        if (field === "selectedImage") {
          formData.append(`media_id`, data["id"]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("savePdfImagesSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveMedia({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/melrose/properties/${
          data.propertyId
        }/media/${data.mediaId}`
      : `${getApiUrl(data.appId)}/module/melrose/properties/${
          data.propertyId
        }/media`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveMediaSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveAdminProperty({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/melrose/properties/${
      data.propertyId
    }/admin`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveAdminPropertySuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveCustomerProperty({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/melrose/properties/${
      data.propertyId
    }/customer`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveCustomerPropertySuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  loadProperties({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/properties`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadPropertiesSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadManagers({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/properties/managers`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadManagersSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteNoteOfInterest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(
          data.appId
        )}/module/melrose/properties/notes-of-interest/${
          data.noteOfInterestId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteNoteOfInterestSuccess", {
            noteOfInterestId: data.noteOfInterestId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  restoreProperty({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/properties/${
          data.propertyId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restorePropertySuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  restoreNoteOfInterest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(
          data.appId
        )}/module/melrose/properties/notes-of-interest/${
          data.noteOfInterestId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreNoteOfInterestSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  restoreViewing({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/properties/viewings/${
          data.viewingId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreViewingSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteViewing({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/properties/viewings/${
          data.viewingId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteViewingSuccess", {
            viewingId: data.viewingId,
            fromProperty: data.fromProperty,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteOffer({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/properties/offers/${
          data.offerId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteOfferSuccess", {
            offerId: data.offerId,
            fromProperty: data.fromProperty,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  restoreOffer({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/properties/offers/${
          data.offerId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreOfferSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteMedia({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/melrose/properties/${
          data.propertyId
        }/media/${data.mediaId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteMediaSuccess", {
            mediaId: data.mediaId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteValuationRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(
          data.appId
        )}/module/melrose/properties/valuation-requests/${
          data.valuationRequestId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteValuationRequestSuccess", {
            valuationRequestId: data.valuationRequestId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  emailSend({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/melrose/properties/${
      data.propertyId
    }/submissions/${data.submissionId}/send-email`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveSendEmailSuccess", {
            ...resp.data,
            submissionId: data.submissionId,
            propertyId: data.propertyId,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadPropertiesSuccess(state, data) {
    state.properties = data.properties;
    state.archived = data.archived;
  },

  saveSendEmailSuccess(state, data) {
    const index = state.submissions.findIndex(
      (s) => s.id === data.submissionId
    );

    state.submissions.splice(index, 1, data.submission);
  },

  loadManagersSuccess(state, data) {
    state.managers = data.managers;
  },
  loadViewingsSuccess(state, data) {
    state.viewings = data.viewings;
    state.viewings_archived = data.viewings_archived;
  },
  loadOffersSuccess(state, data) {
    state.offers = data.offers;
    state.offers_archived = data.archived;
  },
  loadNotesOfInterestSuccess(state, data) {
    state.notes_of_interest = data.notes_of_interest;
    state.notes_of_interest_archived = data.archived;
  },
  loadValuationRequestsSuccess(state, data) {
    state.valuation_requests = data.valuation_requests;
    state.valuation_requests_archive = data.archived;
  },
  loadPropertySuccess(state, data) {
    state.property = data.property;
    state.submissions = data.submissions;
  },
  savePdfImagesSuccess(state, data) {
    state.property = data.property;
  },
  savePropertySuccess(state, data) {
    state.properties.push(data.property);
    state.property = data.property;
  },
  savePropertyDetailsSuccess(state, data) {
    state.property = data.property;
  },
  savePropertyDescriptionSuccess(state, data) {
    state.property = data.property;
  },
  saveAdminPropertySuccess(state, data) {
    state.property = data.property;
  },
  saveAddressSuccess(state, data) {
    state.property.address = data.address;
  },
  saveCustomerPropertySuccess(state, data) {
    state.property.customer = data.property.customer;
  },
  savePriceSuccess(state, data) {
    if (data.isEditing) {
      const index = state.property.prices.findIndex(
        (p) => p.id === data.price.id
      );
      state.property.prices.splice(index, 1, data.price);
    } else {
      state.property.prices.push(data.price);
      state.property = data.property;
    }
  },
  saveMediaSuccess(state, data) {
    if (data.isEditing) {
      const index = state.property.images.findIndex(
        (p) => p.id === data.media.id
      );
      state.property.images.splice(index, 1, data.media);
    } else {
      state.property.images.push(data.media);
    }

    state.property.images.sort((a, b) => a.order - b.order);
  },
  saveNotesOfInterestSuccess(state, data) {
    if (data.isEditing) {
      const index = state.notes_of_interest.findIndex(
        (p) => p.id === data.notes_of_interest.id
      );
      state.notes_of_interest.splice(index, 1, data.notes_of_interest);

      if (data.fromProperty) {
        const indexOnProperty = state.property.notes_of_interest.findIndex(
          (p) => p.id === data.notes_of_interest.id
        );
        state.property.notes_of_interest.splice(
          indexOnProperty,
          1,
          data.notes_of_interest
        );
      }
    } else {
      if (data.fromProperty) {
        state.property.notes_of_interest.push(data.notes_of_interest);
      }
      state.notes_of_interest.push(data.notes_of_interest);
    }
  },
  saveOfferSuccess(state, data) {
    if (data.isEditing) {
      const index = state.offers.findIndex((p) => p.id === data.offer.id);
      state.offers.splice(index, 1, data.offer);

      const indexOffer = state.property.offers.findIndex(
        (p) => p.id === data.offer.id
      );
      state.property.offers.splice(indexOffer, 1, data.offer);
    } else {
      state.offers.push(data.offer);
      if (data.fromProperty) {
        state.property.offers.push(data.offer);
      }
    }
  },
  saveValuationRequestSuccess(state, data) {
    if (data.isEditing) {
      const index = state.offers.findIndex(
        (p) => p.id === data.valuation_requests.id
      );
      state.valuation_requests.splice(index, 1, data.valuation_request);
    } else {
      state.valuation_requests.push(data.valuation_request);
    }
  },
  saveViewingSuccess(state, data) {
    if (data.isEditing) {
      const index = state.viewings.findIndex((p) => p.id === data.viewing.id);
      state.viewings.splice(index, 1, data.viewing);

      if (data.fromProperty) {
        const indexViewing = state.property.viewings.findIndex(
          (p) => p.id === data.viewing.id
        );
        state.property.viewings.splice(indexViewing, 1, data.viewing);
      }
    } else {
      if (data.fromProperty) {
        state.property.viewings.push(data.viewing);
      }
      state.viewings.push(data.viewing);
    }
  },
  deleteViewingSuccess(state, data) {
    const index = state.viewings.findIndex((c) => c.id === data.viewingId);
    state.viewings.splice(index, 1);

    if (data.fromProperty) {
      const indexViewing = state.property.viewings.findIndex(
        (c) => c.id === data.viewingId
      );
      state.property.viewings.splice(indexViewing, 1);
    }
  },
  deleteOfferSuccess(state, data) {
    const index = state.offers.findIndex((c) => c.id === data.offerId);
    state.offers.splice(index, 1);

    if (data.fromProperty) {
      const indexOffer = state.property.offers.findIndex(
        (c) => c.id === data.offerId
      );
      state.property.offers.splice(indexOffer, 1);
    }
  },
  deleteMediaSuccess(state, data) {
    const indexMedia = state.property.images.findIndex(
      (c) => c.id === data.mediaId
    );
    state.property.images.splice(indexMedia, 1);
  },
  deleteValuationRequestSuccess(state, data) {
    const index = state.valuation_requests.findIndex(
      (c) => c.id === data.valuationRequestId
    );
    state.valuation_requests.splice(index, 1);
  },
  deleteNoteOfInterestSuccess(state, data) {
    const index = state.notes_of_interest.findIndex(
      (c) => c.id === data.noteOfInterestId
    );
    state.notes_of_interest.splice(index, 1);

    if (data.fromProperty) {
      const indexNote = state.property.notes_of_interest.findIndex(
        (c) => c.id === data.noteOfInterestId
      );
      state.property.notes_of_interest.splice(indexNote, 1);
    }
  },
  deletePropertySuccess(state, data) {
    const index = state.properties.findIndex((c) => c.id === data.propertyId);
    state.properties.splice(index, 1);
  },

  deleteImageSuccess(state, data) {
    if (data.image.page == 2) {
      const index = state.property.pdf_images.findIndex(
        (c) => c.id === data.imageId
      );

      state.property.pdf_images.splice(index, 1, null);
    } else {
      const index = state.property.pdf_images3.findIndex(
        (c) => c.id === data.imageId
      );

      state.property.pdf_images3.splice(index, 1, null);
    }
  },

  restorePropertySuccess(state, data) {
    const index = state.archived.findIndex((c) => c.id === data.property.id);
    state.archived.splice(index, 1);
    state.properties.push(data.property);
  },
  restoreNoteOfInterestSuccess(state, data) {
    const index = state.notes_of_interest_archived.findIndex(
      (c) => c.id === data.notes_of_interest.id
    );
    state.notes_of_interest_archived.splice(index, 1);
    state.notes_of_interest.push(data.notes_of_interest);
  },
  restoreOfferSuccess(state, data) {
    const index = state.offers_archived.findIndex(
      (c) => c.id === data.offer.id
    );
    state.offers_archived.splice(index, 1);
    state.offers.push(data.offer);
  },

  restoreViewingSuccess(state, data) {
    const index = state.viewings_archived.findIndex(
      (c) => c.id === data.viewing.id
    );
    state.viewings_archived.splice(index, 1);
    state.viewings.push(data.viewing);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
