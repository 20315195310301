import AttractionsRoutes from "./attractions.routes";
import BookingsRoutes from "./bookings.routes";
import CalendarRoutes from "./calendar.routes";
import CustomersRoutes from "./customers.routes";
import FaqsRoutes from "./faqs.routes";
import GlampingRoutes from "./glamping.routes";
import OrdersRoutes from "./orders.routes";
import PartyPodRoutes from "./party-pod.routes";
import SettingsRoutes from "./settings.routes";
import SwimmingRoutes from "./swimming.routes";
import VouchersRoutes from "./vouchers.routes";

export default [
  {
    path: "arnprior",
    component: () => import("@/modules/arnprior/views/Template"),
    children: [
      ...SwimmingRoutes,
      ...CustomersRoutes,
      ...SettingsRoutes,
      ...AttractionsRoutes,
      ...FaqsRoutes,
      ...GlampingRoutes,
      ...CalendarRoutes,
      ...PartyPodRoutes,
      ...OrdersRoutes,
      ...VouchersRoutes,
      ...BookingsRoutes,
    ],
  },
];
