import Vue from "vue";
import Vuex from "vuex";
import accountSettings from "./account-settings.store";
import appSettings from "./app-settings.store";
import arnprior from "@/modules/arnprior/stores/index.store";
import contactForms from "@/modules/contact-forms/stores/index.store";
import craigtoun from "@/modules/craigtoun/stores/index.store";
import customers from "@/modules/customers/stores/index.store";
import defaultStore from "./main.store";
import drum from "@/modules/drum/stores/index.store";
import easylets from "@/modules/easylets/stores/index.store";
import ecommerce from "@/modules/ecommerce/stores/index.store";
import fathom from "@/modules/fathom/stores/index.store";
import flightmode from "@/modules/flightmode/stores/index.store";
import grogreen from "@/modules/grogreen/stores/index.store";
import ibm from "@/modules/ibm/stores/index.store";
import includem from "@/modules/includem/stores/index.store";
import lhl from "@/modules/lhl/stores/index.store";
import lpb from "@/modules/lpb/stores/index.store";
import melrose from "@/modules/melrose/stores/index.store";
import news from "@/modules/news/stores/index.store";
import opportunities from "@/modules/opportunities/stores/index.store";
import pageContent from "@/modules/page-content/stores/index.store";
import paw from "@/modules/paw/stores/index.store";
import pipeline from "@/modules/pipeline/stores/index.store";
import sbpm from "@/modules/sbpm/stores/index.store";
import sensitiveData from "./sensitive-data.store";
import seo from "@/modules/seo/stores/index.store";
import tasking from "@/modules/tasking/stores/index.store";
import team from "@/modules/team/stores/index.store";
import users from "@/modules/users/stores/index.store";
import watson from "@/modules/watson/stores/index.store";
import workload from "@/modules/workload/stores/index.store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    defaultStore,
    users,
    team,
    paw,
    sbpm,
    customers,
    opportunities,
    pipeline,
    accountSettings,
    workload,
    drum,
    flightmode,
    pageContent,
    watson,
    contactForms,
    craigtoun,
    appSettings,
    sensitiveData,
    ecommerce,
    melrose,
    seo,
    news,
    fathom,
    lhl,
    lpb,
    easylets,
    includem,
    tasking,
    arnprior,
    grogreen,
    ibm,
  },
});
