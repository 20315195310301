// import axios from "axios";

import applicationformsStore from "./applicationforms.store";
import customersStore from "./customers.store";
import propertiesStore from "./properties.store";
import salesStore from "./sales.store";
import teamsStore from "./teams.store";

// default state
const getDefaultState = () => {
  return {};
};

// initial state
const state = getDefaultState();

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

// modules
const modules = {
  customersStore,
  propertiesStore,
  applicationformsStore,
  salesStore,
  teamsStore,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
