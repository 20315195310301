<template v-if="hasPermission('view-sensitive-data', $route.params.id)">
  <v-row no-gutters align="center">
    <v-text-field
      v-model="fields.value"
      v-bind="$props"
      :disabled="isLocked"
      @input="valueUpdate"
    ></v-text-field>
    <v-dialog v-model="dialog" max-width="500" @click:outside="reset()">
      <template v-slot:activator="{ on: onDialog, attrs }">
        <v-tooltip left style="pointer-events: auto" class="ml-4">
          <template v-slot:activator="{ on: onTooltip }">
            <v-icon
              v-if="isLocked"
              style="margin-left: 9px"
              v-bind="attrs"
              v-on="{ ...onDialog, ...onTooltip }"
              >mdi-lock
            </v-icon>
            <v-icon
              v-else
              style="margin-left: 9px"
              v-bind="attrs"
              v-on="{ ...onTooltip }"
              @click="isLocked = true"
            >
              mdi-lock-open
            </v-icon>
          </template>
          <span>{{ isLocked ? "Unlock" : "Lock" }} {{ label }}</span>
        </v-tooltip>
      </template>
      <v-card :loading="!appPassword.checkComplete">
        <v-card-title>
          Enter Sensitive Data Password
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4" v-if="!appPassword.checkComplete"
          >Loading...</v-card-text
        >
        <v-card-text
          class="pt-4"
          v-if="appPassword.checkComplete && !appPassword.hasPassword"
        >
          <p>
            Your App doesn't have a password. You will need to create one so you
            can unlock this field.
          </p>
          <v-btn
            v-if="
              hasPermission('edit-sensitive-data-password', $route.params.id)
            "
            depressed
            color="accent"
            :to="{ name: 'app-settings', params: { id: $route.params.id } }"
            >Create Password</v-btn
          >
        </v-card-text>
        <v-card-text
          class="pt-7 pb-0 grey lighten-4"
          v-if="appPassword.hasPassword"
        >
          <v-form
            @submit.prevent="runCheck"
            method="post"
            id="sensitive-data-password-form"
          >
            <v-text-field
              label="Password"
              v-model="fields.sensitive_data_password"
              type="password"
              background-color="white"
              outlined
              :error="checkError"
              :error-messages="checkError ? 'Password Incorrect' : null"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider v-if="appPassword.hasPassword"></v-divider>
        <v-card-actions v-if="appPassword.hasPassword">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="reset()">Close</v-btn>
          <v-btn
            type="submit"
            color="accent"
            text
            :loading="checkInProgress"
            form="sensitive-data-password-form"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: [
    "appendIcon",
    "appendOuterIcon",
    "autofocus",
    "backgroundColor",
    "clearable",
    "clearIcon",
    "color",
    "counter",
    "counterValue",
    "dense",
    "error",
    "error-messages",
    "filled",
    "flat",
    "focused",
    "fullWidth",
    "hint",
    "height",
    "hideDetails",
    "id",
    "label",
    "loading",
    "outlined",
    "persistentHint",
    "prependIcon",
    "placeholder",
    "prefix",
    "prepentInnerIcon",
    "rounded",
    "shaped",
    "singleLine",
    "solo",
    "suffix",
    "type",
    "value",
  ],

  data() {
    return {
      appPassword: {
        checkComplete: false,
        hasPassword: false,
      },
      isLocked: true,
      dialog: false,
      checkInProgress: false,
      fields: {
        sensitive_data_password: null,
        value: null,
      },
      checkError: false,
    };
  },

  created() {
    this.$store
      .dispatch("appSettings/appHasPassword", {
        appId: this.$route.params.id,
      })
      .then(resp => {
        this.appPassword.checkComplete = true;
        this.appPassword.hasPassword = resp.has_password;
      })
      .catch(() => {
        this.dialog = false;
      });
  },

  methods: {
    runCheck() {
      this.checkInProgress = true;
      this.checkError = false;

      this.$store
        .dispatch("appSettings/confirmPassword", {
          appId: this.$route.params.id,
          password: this.fields.sensitive_data_password,
        })
        .then(() => {
          this.isLocked = false;
          this.dialog = false;
          this.checkInProgress = false;
          this.valueUpdate();
        })
        .catch(() => {
          this.checkError = true;
          this.checkInProgress = false;
        });
    },

    reset() {
      this.isLocked = true;
      this.dialog = false;
      this.fields.sensitive_data_password = null;
      this.fields.value = null;
      this.checkInProgress = false;
      this.checkError = false;
    },

    valueUpdate() {
      this.$emit("input", this.fields);
    },
  },
};
</script>
