import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  current_members: [
    {
      id: 1,
      full_name: "Lewis Davie",
      first_name: "Lewis",
      last_name: "Davie",
      email: "lewis@thedavies.co.uk",
      expiry: "31st Oct 2020",
      profile_public: true
    }
  ],
  past_members: [],
  member: {},
  memberIsCurrent: true
};

// getters
const getters = {};

// actions
const actions = {
  loadMembers({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/flightmode/members`,
        method: "GET"
      })
        .then((resp) => {
          commit("loadMembersSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadMember({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/flightmode/members/${data.memberId}`,
        method: "GET"
      })
        .then((resp) => {
          commit("loadMemberSuccess", { ...resp.data, isCurrent: data.isCurrent });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveMember({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/flightmode/members/${data.memberId}`
      : `${getApiUrl(data.appId)}/module/flightmode/members`;

    return new Promise((resolve, reject) => {
      axios
        .post(url, data.fields)
        .then((resp) => {
          commit("saveMemberSuccess", { ...resp.data, isEditing: data.isEditing });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteMember({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/flightmode/members/${data.memberId}`,
        method: "DELETE"
      })
        .then(() => {
          commit("deleteMemberSuccess", { memberId: data.memberId });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  restoreMember({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/flightmode/members/${data.memberId}/restore`,
        method: "POST"
      })
        .then((resp) => {
          commit("restoreMemberSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveMembership({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${getApiUrl(data.appId)}/module/flightmode/members/${data.memberId}/memberships/${data.membershipId}`,
          data.fields
        )
        .then((resp) => {
          commit("saveMembershipSuccess", {
            ...resp.data,
            memberId: parseInt(data.memberId),
            membershipId: parseInt(data.membershipId),
            isCurrentMembership: data.isCurrentMembership
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

// mutations
const mutations = {
  loadMembersSuccess(state, data) {
    state.current_members = data.current_members;
    state.past_members = data.past_members;
  },
  loadMemberSuccess(state, data) {
    state.member = data.member;
    state.memberIsCurrent = data.member.current_memberships.length > 0 ? true : false;
  },
  saveMemberSuccess(state, data) {
    if (state.memberIsCurrent) {
      if (data.isEditing) {
        const index = state.current_members.findIndex((m) => m.id === data.member.id);
        state.current_members.splice(index, 1, data.member);
        state.member = data.member;
      } else {
        state.current_members.push(data.member);
      }
    } else {
      if (data.isEditing) {
        const index = state.past_members.findIndex((m) => m.id === data.member.id);
        state.past_members.splice(index, 1, data.member);
        state.member = data.member;
      } else {
        state.past_members.push(data.member);
      }
    }
  },
  deleteMemberSuccess(state, data) {
    const index = state.current_members.findIndex((m) => m.id === data.memberId);
    state.past_members.push(state.current_members[index]);
    state.current_members.splice(index, 1);
  },
  restoreMemberSuccess(state, data) {
    const index = state.past_members.findIndex((m) => m.id === data.member.id);
    state.past_members.splice(index, 1);
    state.current_members.push(data.member);
  },
  saveMembershipSuccess(state, data) {
    if (state.memberIsCurrent) {
      const memberIndex = state.current_members.findIndex((m) => m.id === data.memberId);
      const membershipIndex = state.current_members[memberIndex].memberships.findIndex((m) => m.id === data.membership.id);
      state.current_members[memberIndex].memberships.splice(membershipIndex, 1, data.membership);

      if (data.isCurrentMembership) {
        const currentIndex = state.member.current_memberships.findIndex((m) => m.id === data.membership.id);

        if (data.membership.status !== "Past") {
          state.member.current_memberships.splice(currentIndex, 1, data.membership);
        } else {
          state.member.current_memberships.splice(currentIndex, 1);
          state.member.past_memberships.push(data.membership);
        }
      } else {
        const pastIndex = state.member.past_memberships.findIndex((m) => m.id === data.membership.id);

        if (data.membership.status === "Past") {
          state.member.past_memberships.splice(pastIndex, 1, data.membership);
        } else {
          state.member.past_memberships.splice(pastIndex, 1);
          state.member.current_memberships.push(data.membership);
        }
      }

      if (state.member.current_memberships.length === 0) {
        state.past_members.push(state.current_members[memberIndex]);
        state.current_members.splice(memberIndex, 1);
      }
    } else {
      const memberIndex = state.past_members.findIndex((m) => m.id === data.memberId);
      const membershipIndex = state.past_members[memberIndex].memberships.findIndex((m) => m.id === data.membership.id);
      state.past_members[memberIndex].memberships.splice(membershipIndex, 1, data.membership);

      if (data.isCurrentMembership) {
        const currentIndex = state.member.current_memberships.findIndex((m) => m.id === data.membership.id);

        if (data.membership.status !== 'Past') {
          state.member.current_memberships.splice(currentIndex, 1, data.membership);
        } else {
          state.member.current_memberships.splice(currentIndex, 1);
          state.member.past_memberships.push(data.membership);
        }
      } else {
        const pastIndex = state.member.past_memberships.findIndex((m) => m.id === data.membership.id);

        if (data.membership.status === "Past") {
          state.member.past_memberships.splice(pastIndex, 1, data.membership);
        } else {
          state.member.past_memberships.splice(pastIndex, 1);
          state.member.current_memberships.push(data.membership);
        }
      }

      if (state.member.current_memberships.length > 0) {
        state.current_members.push(state.past_members[memberIndex]);
        state.past_members.splice(memberIndex, 1);
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
