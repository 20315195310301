import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  payments: [],
  total: [],
  user: {},
};

// getters
const getters = {};

// actions
const actions = {
  loadPaymentSchedule({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/payment-schedule`,
        method: "POST",
      })
        .then((resp) => {
          commit("loadPaymentScheduleSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadPaymentScheduleSuccess(state, data) {
    state.payments = data.payments;
    state.total = data.total;
    state.user = data.user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
