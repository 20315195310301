import store from "@/stores/";

export default [
  {
    path: "teams",
    component: () => import("@/modules/includem/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("includem/teamsStore/loadTeams", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-includem-teams",
        component: () => import("@/modules/includem/views/teams/Dashboard"),
      },
      {
        path: ":teamId",
        name: "module-includem-teams-individual",
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("includem/teamsStore/loadTeam", {
              appId: to.params.id,
              teamId: to.params.teamId,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        component: () => import("@/modules/includem/views/teams/Individual"),
      },
    ],
  },
];
