import _ from "lodash";
import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  products: [],
  brands: [],
  categories: [],
  tags: [],
  archived: [],
};

// getters
const getters = {};

// actions
const actions = {
  loadProducts({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/grogreen/products`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadProductsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadTags({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/grogreen/products/tags`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadTagsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadProduct(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/grogreen/products/${
          data.productId
        }`,
        method: "GET",
      })
        .then((resp) => {
          resolve(resp.data.product);
        })
        .catch((err) => reject(err));
    });
  },
  saveProduct({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/grogreen/products/${data.productId}`
      : `${getApiUrl(data.appId)}/module/grogreen/products`;

    let fields = data.fields;

    if (fields.thumbnail_has_changed === false) {
      fields.thumbnail_image_id = null;
    }

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (
        fields[field] === null ||
        typeof fields[field] === "undefined"
      ) {
        formData.append(field, "");
      } else if (field === "breakdown") {
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(`breakdown[${i}][variety]`, data[i]["variety"]);
          formData.append(`breakdown[${i}][product]`, data[i]["product"]);
          formData.append(`breakdown[${i}][percent]`, data[i]["percent"]);
        }
      } else if (field === "included_grasses") {
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(
            `included_grasses[${i}][variety]`,
            data[i]["variety"]
          );
        }
      } else if (field === "prices") {
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(`prices[${i}][name]`, data[i]["name"]);
          formData.append(`prices[${i}][price]`, data[i]["price"]);
        }
      } else if (field === "downloads") {
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(`downloads[${i}][name]`, data[i]["name"]);
          formData.append(`downloads[${i}][file]`, data[i]["file"]);

          formData.append(`downloads[${i}][changed]`, data[i]["changed"]);
          formData.append(`downloads[${i}][id]`, data[i]["id"]);
        }
      } else if (field === "included_wildflowers") {
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(
            `included_wildflowers[${i}][variety]`,
            data[i]["variety"]
          );
        }
      } else if (typeof fields[field] === "object") {
        if (fields[field] instanceof File) {
          formData.append(field, fields[field]);
        }
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(field + "[]", data[i]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveProductSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadTagsForProduct({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/grogreen/products/${
      data.productId
    }/load-tags`;
    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          productId: data.productId,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("loadTagsForProductSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveProductTags({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/grogreen/products/${
      data.productId
    }/add-tags`;
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          const product = resp.data.product;
          commit("saveProductTagsSuccess", {
            product,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/grogreen/products/${
          data.productId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteProductSuccess", { productId: data.productId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
  loadBrands({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/grogreen/products/brands`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadBrandsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadCategories({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/grogreen/products/categories`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadCategoriesSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveCategory({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/grogreen/products/categories/${
          data.categoryId
        }`
      : `${getApiUrl(data.appId)}/module/grogreen/products/categories`;

    let fields = data.fields;

    //if (fields.thumbnail_has_changed === false) {
    // fields.thumbnail = null;
    // }

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (
        fields[field] === null ||
        typeof fields[field] === "undefined"
      ) {
        formData.append(field, "");
      } else if (typeof fields[field] === "object") {
        if (fields[field] instanceof File) {
          formData.append(field, fields[field]);
        }
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(field + "[]", data[i]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveCategorySuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/grogreen/products/categories/${
          data.categoryId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteCategorySuccess", { categoryId: data.categoryId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  loadAddons({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/grogreen/products/addons`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadAddonsSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveAddon({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/grogreen/products/addons/${
          data.addonId
        }`
      : `${getApiUrl(data.appId)}/module/grogreen/products/addons`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveAddonSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  deleteAddon({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/grogreen/products/addons/${
          data.addonId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteAddonSuccess", { addonId: data.addonId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },
};

// mutations
const mutations = {
  loadProductsSuccess(state, data) {
    state.products = data.products;
    state.archived = data.archived;
  },
  loadBrandsSuccess(state, data) {
    state.brands = data.brands;
  },
  uploadImageSuccess() {},
  removeImageSuccess() {},
  saveProductSuccess(state, data) {
    if (data.isEditing) {
      const index = state.products.findIndex((p) => p.id === data.product.id);
      state.products.splice(index, 1, data.product);
    } else {
      state.products.push(data.product);
    }
  },
  loadTagsForProductSuccess(state, data) {
    state.tags = data.tags;
  },
  saveProductTagsSuccess(state, data) {
    const index = state.products.findIndex((p) => p.id === data.product.id);
    state.products.splice(index, 1, data.product);
  },
  deleteProductSuccess(state, data) {
    const index = state.products.findIndex((p) => p.id === data.productId);
    state.archived.push(state.products[index]);
    state.products.splice(index, 1);
  },
  loadTagsSuccess(state, data) {
    state.tags = data.tags;
  },
  loadCategoriesSuccess(state, data) {
    state.categories = data.categories;
  },
  saveCategorySuccess(state, data) {
    if (data.isEditing) {
      const index = state.categories.findIndex(
        (c) => c.id === data.category.id
      );
      state.categories.splice(index, 1, data.category);
    } else {
      state.categories.push(data.category);
    }

    let categories = _.cloneDeep(state.categories);
    state.categories = _.sortBy(categories, "name");
  },
  deleteCategorySuccess(state, data) {
    const index = state.categories.findIndex((c) => c.id === data.categoryId);
    state.categories.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
