import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  appHasPassword: false,
};

// getters
const getters = {
  appHasPassword: state => state.appHasPassword,
};

// actions
const actions = {
  appHasPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/settings/has-password`,
        method: "GET",
      })
        .then(resp => {
          commit("appHasPasswordSuccess", resp.data);
          resolve(resp.data);
        })
        .catch(err => reject(err));
    });
  },

  saveAppPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/settings/update-password`,
        method: "POST",
        data: data.fields,
      })
        .then(() => {
          commit("saveAppPasswordSuccess");
          resolve();
        })
        .catch(err => reject(err));
    });
  },

  confirmPassword(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/settings/confirm-password`,
        method: "POST",
        data: {
          password: data.password,
        },
      })
        .then(() => resolve())
        .catch(err => reject(err));
    });
  },
};

// mutations
const mutations = {
  appHasPasswordSuccess(state, data) {
    state.appHasPassword = data.has_password;
  },
  saveAppPasswordSuccess(state) {
    state.appHasPassword = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
