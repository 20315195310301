import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  templates: [],
  template: {}
};

// getters
const getters = {};

// actions
const actions = {
  loadTemplates({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/flightmode/monthly-templates`,
        method: "GET"
      })
        .then((resp) => {
          commit("loadTemplatesSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadTemplate({ commit }, data) {
    return new Promise((resolve) => {
      const index = state.templates.findIndex((t) => t.id === data.templateId);
      commit("loadTemplateSuccess", { template: state.templates[index] });
      resolve();
    });
  },
  saveTemplate({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/flightmode/monthly-templates/${data.templateId}`
      : `${getApiUrl(data.appId)}/module/flightmode/monthly-templates`;

    return new Promise((resolve, reject) => {
      axios
        .post(url, data.formData)
        .then((resp) => {
          commit("saveTemplateSuccess", { ...resp.data, isEditing: data.isEditing });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteTemplate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/flightmode/monthly-templates/${data.templateId}`,
        method: "DELETE"
      })
        .then(() => {
          commit("deleteTemplateSuccess", { templateId: data.templateId });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

// mutations
const mutations = {
  loadTemplatesSuccess(state, data) {
    state.templates = data.templates;
  },
  loadTemplateSuccess(state, data) {
    state.template = data.template;
  },
  saveTemplateSuccess(state, data) {
    if (data.isEditing) {
      const index = state.templates.findIndex((t) => t.id === data.template.id);
      state.templates.splice(index, 1, data.template);
      state.template = data.template;
    } else {
      state.templates.push(data.template);
    }
  },
  deleteTemplateSuccess(state, data) {
    const index = state.templates.findIndex((t) => t.id === data.templateId);
    state.templates.splice(index, 1);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
