import ArticleRoutes from "./articles.routes";
import CustomerRoutes from "./customers.routes";
import OrderRoutes from "./orders.routes";
import ProductRoutes from "./products.routes";
import SpeciesRoutes from "./species.routes";
import TestimonialsRoutes from "./testimonials.routes";

export default [
  {
    path: "watson",
    component: () => import("@/modules/watson/views/Template"),
    children: [
      ...ProductRoutes,
      ...SpeciesRoutes,
      ...OrderRoutes,
      ...TestimonialsRoutes,
      ...CustomerRoutes,
      ...ArticleRoutes,
    ],
  },
];
