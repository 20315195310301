import store from "@/stores/";

export default [
  {
    path: "testimonials",
    component: () => import("@/modules/lhl/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("lhl/testimonials/loadTestimonials", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-lhl-testimonials",
        component: () => import("@/modules/lhl/views/testimonials/Dashboard"),
      },
      {
        path: ":testimonialId",
        name: "module-lhl-testimonials-individual",
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("lhl/testimonials/loadTestimonial", {
              appId: to.params.id,
              testimonialId: to.params.testimonialId,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        component: () => import("@/modules/lhl/views/testimonials/Individual"),
      },
    ],
  },
];
