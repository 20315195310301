import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  order: {},
  partyPods: [],
  disabledDays: [],
};

// getters
const getters = {
  all: (state) => state.pods,
  disabledDays: (state) => state.disabledDays,
};

// actions
const actions = {
  loadPods({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/pods`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadPodsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getDisabledDays({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/pods/get-dates`,
        method: "POST",
        data: data.fields,
      })
        .then((resp) => {
          commit("getDisabledDaysSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  getAvailablePods(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/pods/get-available-pods`,
        method: "POST",
        data: data.fields,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => reject(err));
    });
  },
  getDisabledDaysParty({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/pods/get-dates-party`,
        method: "POST",
        data: data.fields,
      })
        .then((resp) => {
          commit("getDisabledDaysSuccess", resp.data);
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  getPartyTimeSlots(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/pods/get-party-slots`,
        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => reject(err));
    });
  },

  getSwimSlots(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/pods/get-swim-slots`,
        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => reject(err));
    });
  },
  getSummary(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/pods/get-summary`,
        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => reject(err));
    });
  },
  getPartySummary(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/pods/get-summary-party`,
        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => reject(err));
    });
  },

  createBooking(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/pods/bookings`,
        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => reject(err));
    });
  },
  createPartyBooking({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/pods/bookings/party`,
        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          commit("createPartyPodSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })

        .catch((err) => reject(err));
    });
  },
  saveRate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: data.isEditing
          ? `${getApiUrl(data.appId)}/module/arnprior/pods/${
              data.podId
            }/rates/${data.rateId}`
          : `${getApiUrl(data.appId)}/module/arnprior/pods/${data.podId}/rates`,
        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          commit("saveRateSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  deleteRate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/pods/${
          data.podId
        }/rates/${data.rateId}`,
        method: "DELETE",
      })
        .then((resp) => {
          commit("deleteRateSuccess", {
            ...resp.data,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  savePod({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: data.isEditing
          ? `${getApiUrl(data.appId)}/module/arnprior/pods/${data.podId}`
          : `${getApiUrl(data.appId)}/module/arnprior/pods`,
        method: "POST",
        data: data.fields,
        headers: {
          Accept: "application/json",
        },
      })
        .then((resp) => {
          commit("savePodSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadPodsSuccess(state, data) {
    state.pods = data.pods;
  },
  getDisabledDaysSuccess(state, data) {
    state.disabledDays = data.disabledDays;
  },
  saveRateSuccess(state, data) {
    const podIndex = state.pods.findIndex(
      (p) => p.id === data.rate.glamping_pod_id
    );

    if (data.isEditing) {
      const rateIndex = state.pods[podIndex].rates.findIndex(
        (r) => r.id === data.rate.id
      );
      state.pods[podIndex].rates.splice(rateIndex, 1, data.rate);
    } else {
      state.pods[podIndex].rates.push(data.rate);
    }
  },
  deleteRateSuccess(state, data) {
    const podIndex = state.pods.findIndex(
      (p) => p.id === data.rate.glamping_pod_id
    );
    const rateIndex = state.pods[podIndex].rates.findIndex(
      (r) => r.id === data.rate.id
    );

    state.pods[podIndex].rates.splice(rateIndex, 1);
  },
  savePodSuccess(state, data) {
    if (data.isEditing) {
      const index = state.pods.findIndex((p) => p.id === data.pod.id);
      state.pods[index] = data.pod;
    } else {
      state.pods.push(data.pod);
    }
  },
  createPartyPodSuccess(state, data) {
    state.order = data.order;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
