import couponCodesRoutes from "./coupon-codes.routes";
import deliveryRoutes from "./delivery.routes";

export default [
  {
    path: "ecommerce",
    component: () => import("@/modules/ecommerce/views/Template"),
    name: "module-ecommerce",
    children: [...deliveryRoutes, ...couponCodesRoutes],
  },
];
