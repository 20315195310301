import store from "@/stores/";

export default [
  {
    path: "customers",
    component: () => import("@/modules/drum/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      store
        .dispatch("drum/customers/loadCustomers", {
          appId: to.params.id,
        })
        .then(() => {
          store.commit("showHideLoader", false);
          next();
        });
    },
    children: [
      {
        path: "",
        name: "module-drum-customers",
        component: () => import("@/modules/drum/views/customers/Dashboard"),
      },
      {
        path: "archived",
        name: "module-drum-customers-archived",
        component: () => import("@/modules/drum/views/customers/Archived"),
      },
      {
        path: ":customerId",
        component: () => import("@/modules/drum/views/customers/Profile"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("drum/customers/loadCustomer", {
              appId: to.params.id,
              customerId: parseInt(to.params.customerId),
            }),
            store.dispatch("drum/admin/loadAgents", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: [
          {
            path: "",
            redirect: { name: "module-drum-customers-profile" },
          },
          {
            path: "basic-details",
            name: "module-drum-customers-profile",
            component: () =>
              import(
                "@/modules/drum/views/customers/components/profile/BasicDetails"
              ),
          },
          {
            path: "tours",
            name: "module-drum-customers-profile-tours",
            component: () =>
              import("@/modules/drum/views/customers/components/profile/Tours"),
          },
          {
            path: "marketing",
            name: "module-drum-customers-profile-marketing",
            component: () =>
              import(
                "@/modules/drum/views/customers/components/profile/Marketing"
              ),
          },
        ],
      },
    ],
  },
];
