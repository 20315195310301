import store from "@/stores/";

export default [
  {
    path: "application-forms",
    component: () => import("@/modules/easylets/views/Template"),
    children: [
      {
        path: "",
        name: "module-easylets-applicationforms",
        component: () =>
          import("@/modules/easylets/views/applicationforms/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("easylets/applicationformsStore/loadForms", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "withdrawn",
        name: "module-easylets-applicationforms-withdrawn",
        props: () => ({
          pageTitle: "Forms",
          parentRouteName: "module-easylets-applicationforms",
        }),
        component: () =>
          import("@/modules/easylets/views/applicationforms/Withdrawn"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("easylets/applicationformsStore/loadForms", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "archived",
        name: "module-easylets-applicationforms-archived",
        props: () => ({
          pageTitle: "Forms",
          parentRouteName: "module-easylets-applicationforms",
        }),
        component: () =>
          import("@/modules/easylets/views/applicationforms/Archived"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("easylets/applicationformsStore/loadForms", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: ":formId",
        component: () =>
          import("@/modules/easylets/views/applicationforms/Individual"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("easylets/applicationformsStore/loadForm", {
              appId: to.params.id,
              formId: to.params.formId,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        children: [
          {
            path: "",
            name: "module-easylets-applicationforms-individual",
            component: () =>
              import(
                "@/modules/easylets/views/applicationforms/Individual/Details"
              ),
          },
          {
            path: "additional-info",
            name: "module-easylets-applicationforms-individual-additional-info",
            component: () =>
              import(
                "@/modules/easylets/views/applicationforms/Individual/AdditionalInfo"
              ),
          },
          {
            path: "address",
            name: "module-easylets-applicationforms-individual-address",
            component: () =>
              import(
                "@/modules/easylets/views/applicationforms/Individual/Address"
              ),
          },
          {
            path: "guarantor",
            name: "module-easylets-applicationforms-individual-guarantor",
            component: () =>
              import(
                "@/modules/easylets/views/applicationforms/Individual/Guarantor"
              ),
          },
          {
            path: "financial-info",
            name: "module-easylets-applicationforms-individual-financial-info",
            component: () =>
              import(
                "@/modules/easylets/views/applicationforms/Individual/FinancialInfo"
              ),
          },
          {
            path: "employment-info",
            name: "module-easylets-applicationforms-individual-employment-info",
            component: () =>
              import(
                "@/modules/easylets/views/applicationforms/Individual/Employment"
              ),
          },
          {
            path: "landlord",
            name: "module-easylets-applicationforms-individual-landlord",
            component: () =>
              import(
                "@/modules/easylets/views/applicationforms/Individual/Landlord"
              ),
          },
          {
            path: "next-of-kin",
            name: "module-easylets-applicationforms-individual-next-of-kin",
            component: () =>
              import(
                "@/modules/easylets/views/applicationforms/Individual/NextOfKin"
              ),
          },
          {
            path: "upload",
            name: "module-easylets-applicationforms-individual-upload",
            component: () =>
              import(
                "@/modules/easylets/views/applicationforms/Individual/Upload"
              ),
          },
        ],
      },
    ],
  },
];
