import store from "@/stores/";

export default [
  {
    path: "landingpages",
    component: () => import("@/modules/lpb/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("lpb/landingpages/loadLandingPages", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-lpb-landingpages",
        component: () => import("@/modules/lpb/views/landingpages/Dashboard"),
      },
      {
        path: "archived",
        name: "module-lpb-landingpages-archived",
        component: () => import("@/modules/lpb/views/landingpages/Archived"),
      },
      {
        path: ":pageId",
        name: "module-lpb-landingpages-individual",
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("lpb/landingpages/loadLandingPage", {
              appId: to.params.id,
              pageId: to.params.pageId,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
        component: () => import("@/modules/lpb/views/landingpages/Individual"),
      },
    ],
  },
];
