import store from "@/stores/";

export default [
  {
    path: "",
    name: "module-paw-news",
    component: () => import("@/modules/paw/views/news/Dashboard"),
  },
  {
    path: "categories",
    component: () => import("@/modules/paw/views/news/Template"),
    children: [
      {
        path: "",
        name: "module-paw-news-categories",
        props: () => ({
          parentRouteName: "module-paw-news",
        }),
        component: () => import("@/modules/paw/views/news/Categories"),
      },
      {
        path: "",
        name: "module-paw-news-categories-archived",
        props: () => ({
          parentRouteName: "module-paw-news-categories",
        }),
        component: () => import("@/modules/paw/views/news/ArchivedCategories"),
      },
    ],
  },
  {
    path: "archived",
    name: "module-paw-news-archived",
    props: () => ({
      parentRouteName: "module-paw-news",
    }),
    component: () => import("@/modules/paw/views/news/Archived"),
  },
  {
    path: "create",
    name: "module-paw-news-create",
    props: () => ({
      parentRouteName: "module-paw-news",
    }),
    component: () => import("@/modules/paw/views/news/Article"),
  },
  {
    path: ":articleId",
    name: "module-paw-news-individual",
    props: () => ({
      isEditing: true,
      parentRouteName: "module-paw-news",
    }),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      store
        .dispatch("paw/loadArticle", {
          appId: to.params.id,
          articleId: to.params.articleId,
        })
        .then(() => {
          store.commit("showHideLoader", false);
          next();
        });
    },
    component: () => import("@/modules/paw/views/news/Article"),
  },
];
