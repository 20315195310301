// import axios from "axios";

import admin from "./admin.store";
import customers from "./customers.store";
import tours from "./tours.store";
import notes from "./notes.store";
import settings from "./settings.store";
import paymentSchedule from "./payment-schedule.store";

// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

// modules
const modules = {
  customers,
  tours,
  admin,
  notes,
  settings,
  paymentSchedule
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
};
