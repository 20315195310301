// import axios from "axios";
// import { getApiUrl } from "@/utils";

// initial state
const state = {
  articles: [
    {
      id: 1,
      title: "Test Article",
      slug: "test-article",
      categories: [
        {
          id: 1,
          name: "Events",
        },
      ],
    },
  ],
  article: {},
  categories: [
    {
      id: 1,
      name: "Events",
      slug: "events",
    },
  ],
  archived: [],
  archivedCategories: [],
};

// getters
const getters = {
  articles: state => state.articles,
  article: state => state.article,
  categories: state => state.categories,
  archived: state => state.archived,
  archivedCategories: state => state.archivedCategories,
};

// actions
const actions = {
  loadArticles({ commit }) {
    commit("loadArticlesSuccess");
    return Promise.resolve();
  },
  loadArticle({ commit }, data) {
    commit("loadArticleSuccess", data);
  },
  loadCategories({ commit }) {
    commit("loadloadCategoriesSuccess");
    return Promise.resolve();
  },
};

// mutations
const mutations = {
  loadArticlesSuccess() {},
  loadArticleSuccess(state, data) {
    state.article = state.articles.find(a => {
      return a.id === parseInt(data.articleId);
    });
  },
  loadCategoriesSuccess() {},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
