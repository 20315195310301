import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  customers: [],
  archived: [],
  customer: {},
};

// getters
const getters = {
  getMatchingCustomers: (state) => (firstName, lastName) => {
    const fullName = firstName.concat(' ', lastName);
    return state.customers.filter((c) => {
      const customerFullName = c.full_name.toLowerCase();
      return customerFullName.includes(fullName.toLowerCase());
    });
  }
};

// actions
const actions = {
  loadCustomers({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/customers`,
        method: "GET"
      })
        .then((resp) => {
          commit("loadCustomersSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadCustomer({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/customers/${data.customerId}`,
        method: "GET"
      })
        .then((resp) => {
          commit("loadCustomerSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveCustomer({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/drumgolf/customers/${data.customerId}`
      : `${getApiUrl(data.appId)}/module/drumgolf/customers`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          ...data.fields
        },
        method: "POST"
      })
        .then((resp) => {
          commit("saveCustomerSuccess", { ...resp.data, isEditing: data.isEditing });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteCustomer({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/customers/${data.customerId}`,
        method: "DELETE"
      })
        .then(() => {
          commit("deleteCustomerSuccess", { customerId: data.customerId });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  restoreCustomer({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/customers/${data.customerId}/restore`,
        method: "POST"
      })
        .then((resp) => {
          commit("restoreCustomerSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadCustomersSuccess(state, data) {
    state.customers = data.customers;
    state.archived = data.archived;
  },
  loadCustomerSuccess(state, data) {
    state.customer = data.customer;
  },
  saveCustomerSuccess(state, data) {
    if (data.isEditing) {
      const index = state.customers.findIndex((c) => c.id === data.customer.id);
      state.customers.splice(index, 1, data.customer);
      state.customer = data.customer;
    } else {
      state.customers.push(data.customer);
    }
  },
  deleteCustomerSuccess(state, data) {
    const index = state.customers.findIndex((c) => c.id === data.customerId);
    state.archived.push(state.customers[index]);
    state.customers.splice(index, 1);
  },
  restoreCustomerSuccess(state, data) {
    const index = state.archived.findIndex((c) => c.id === data.customer.id);
    state.archived.splice(index, 1);
    state.customers.push(data.customer);
  },
  addNote(state, data) {
    state.customer.notes.unshift(data.note);
  },
  removeNote(state, data) {
    const index = state.customer.notes.findIndex((n) => n.id === data.noteId);
    state.customer.notes.splice(index, 1);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
