import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  bookings: [],
  swim_bookings: [],
  pod_bookings: [],
  pool_party_bookings: [],
  party_pod_bookings: [],
  archived: [],
  booking: {},
  bookings_glamping: [],
  extras: [],
};

// getters
const getters = {
  all: (state) => state.bookings,
  allBookedSwims: (state) => state.swim_bookings,
  allParties: (state) => state.pool_party_bookings,
  allGlamping: (state) => state.pod_bookings,
  allPartyPods: (state) => state.party_pod_bookings,
  get: (state) => state.booking,
  archived: (state) => state.archived,
  extras: (state) => state.extras,
};

// actions
const actions = {
  loadBookings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/bookings`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadBookingsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadCalendarBookings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: data.hasOwnProperty("type")
          ? `${getApiUrl(data.appId)}/module/arnprior/bookings/calendar/${
              data.type
            }`
          : `${getApiUrl(data.appId)}/module/arnprior/bookings/calendar`,
        method: "POST",
        data: data.fields,
      })
        .then((resp) => {
          commit("loadBookingsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadBooking({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/bookings/${
          data.bookingId
        }`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadBookingSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadSwimBookings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/bookings/swimming`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadSwimBookingsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadSwimBooking({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/bookings/${
          data.bookingId
        }/swimming`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadBookingSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadPoolPartyBookings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(
          data.appId
        )}/module/arnprior/bookings/swimming/pool-parties`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadPoolPartyBookingsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadPodBookings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/bookings/pods`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadPodBookingsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadPartyPodBookings({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/bookings/party-pods`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadPartyPodBookingsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loadExtras({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/extras`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadExtrasSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteBooking() {
    return Promise.resolve();
  },
  restoreBooking() {
    return Promise.resolve();
  },
  saveBooking() {
    return Promise.resolve();
  },
  deleteExtra({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/arnprior/extras/${data.extraId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteExtraSuccess", {
            extraId: data.extraId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveExtra({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/arnprior/extras/${data.extraId}`
      : `${getApiUrl(data.appId)}/module/arnprior/extras`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveExtraSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadBookingsSuccess(state, data) {
    state.bookings = data.bookings;
  },
  loadSwimBookingsSuccess(state, data) {
    state.swim_bookings = data.bookings;
  },
  loadPodBookingsSuccess(state, data) {
    state.pod_bookings = data.bookings;
  },
  loadPoolPartyBookingsSuccess(state, data) {
    state.pool_party_bookings = data.bookings;
  },
  loadPartyPodBookingsSuccess(state, data) {
    state.party_pod_bookings = data.bookings;
  },
  loadBookingSuccess(state, data) {
    state.booking = data.booking;
  },
  loadExtrasSuccess(state, data) {
    state.extras = data.extras;
  },
  deleteExtraSuccess(state, data) {
    const index = state.extras.findIndex((b) => b.id === data.extraId);
    state.extras.splice(index, 1);
  },
  saveExtraSuccess(state, data) {
    if (data.isEditing) {
      const index = state.extras.findIndex((p) => p.id === data.extra.id);
      state.extras.splice(index, 1, data.extra);
    } else {
      state.extras.push(data.extra);
    }
  },
  deleteBookingSuccess(state, data) {
    const index = state.bookings.findIndex((b) => b.id === data.bookingId);
    state.bookings.splice(index, 1);
  },
  restoreBookingSuccess(state, data) {
    const index = state.archived.findIndex((b) => b.id === data.booking.id);
    state.archived.splice(index, 1);
    state.bookings.push(data.booking);
  },
  saveBookingSuccess(state, data) {
    if (data.isEditing) {
      const index = state.bookings.findIndex((b) => b.id === data.booking.id);
      state.bookings.splice(index, 1, data.booking);
      state.booking = data.booking;
    } else {
      state.bookings.push(data.booking);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
