import axios from "axios";
import { getApiUrl } from "@/utils";

const state = {
  forms: [],
  archived: [],
  form: {},
};

// getters
const getters = {
  all: (state) => state.forms,
  get: (state) => state.form,
  archived: (state) => state.archived,
};

// actions
const actions = {
  uploadStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/easylets/forms/${
          data.formId
        }/status-upload-comments`,
        data: {
          field: data.field,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("uploadStatusSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  changeStatusUpload({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/easylets/forms/${
          data.formId
        }/status-upload`,
        data: {
          form_status: data.form_status,
          comment_text: data.comment_text,
          field: data.field,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("changeStatusUploadSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  changeStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/easylets/forms/${
          data.formId
        }/status`,
        data: {
          form_status: data.form_status,
          comment_text: data.comment_text,
          individual: data.individual,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("changeStatusSuccess", {
            individual: data.individual,
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadForms({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/easylets/forms`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadFormsSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadForm({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/easylets/forms/${data.formId}`,
        method: "GET",
      })
        .then((resp) => {
          const form = resp.data.form;
          commit("loadFormSuccess", { form });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteForm({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/easylets/forms/${data.formId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteFormSuccess", {
            formId: data.formId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  restoreForm({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/easylets/forms/${
          data.formId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreFormSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  sendAgentos({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/easylets/forms/${
      data.formId
    }/send-agentos`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveAgentosSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  sendGuarantor({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/easylets/forms/${
      data.formId
    }/send-guarantor`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveGuarantorSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveComment({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/easylets/forms/${
      data.formId
    }/comment`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveCommentSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  deleteFile({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/easylets/forms/${
          data.formId
        }/file/${data.fileId}`,
        method: "DELETE",
      })
        .then((resp) => {
          commit("deleteFileSuccess", {
            ...resp.data,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveForm({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/easylets/forms/${data.formId}`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (
        fields[field] === null ||
        typeof fields[field] === "undefined"
      ) {
        formData.append(field, "");
      } else if (typeof fields[field] === "object") {
        if (fields[field] instanceof File) {
          formData.append(field, fields[field]);
        }
        const data = fields[field];
        for (let i = 0; i < data.length; i++) {
          formData.append(field + "[]", data[i]);
        }
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveFormSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadFormsSuccess(state, data) {
    state.forms = data.forms;
    state.archived = data.archived;
  },
  loadFormSuccess(state, data) {
    state.form = data.form;
  },
  uploadStatusSuccess() {},
  deleteFormSuccess(state, data) {
    const index = state.forms.findIndex(
      (c) => c.application_id === data.formId
    );
    state.forms.splice(index, 1);
  },

  restoreFormSuccess(state, data) {
    const index = state.archived.findIndex(
      (c) => c.application_id === data.form.application_id
    );
    state.archived.splice(index, 1);
    state.forms.push(data.form);
  },

  deleteFileSuccess(state, data) {
    var fileType = data.file_type;

    var index = state.form[fileType].findIndex((c) => c.id === data.fileId);
    state.form[fileType].splice(index, 1);
  },

  saveCommentSuccess(state, data) {
    if (data.comment.is_internal == 1) {
      state.form.confidential.unshift(data.comment);
    } else {
      state.form.comments.unshift(data.comment);
    }
  },
  saveAgentosSuccess(state, data) {
    state.form = data.form;
  },
  saveGuarantorSuccess(state, data) {
    state.form.audits.push(data.audit);
  },
  saveFormSuccess(state, data) {
    state.form = data.form;
  },
  changeStatusSuccess(state, data) {
    state.form = data.form;
  },
  changeStatusUploadSuccess(state, data) {
    state.form = data.form;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
