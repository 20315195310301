import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  orders: [],
  order: {},
  costResults: [],
};

// getters
const getters = {};

// actions
const actions = {
  loadOrders({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/watson/orders`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadOrdersSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },
  loadOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/watson/orders/${data.orderId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadOrderSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  saveOrder({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/watson/orders/${data.orderId}`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveOrderSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadOrdersSuccess(state, data) {
    state.orders = data.orders;
  },
  loadOrderSuccess(state, data) {
    state.order = data.order;
    state.costResults = data.cost_results;
  },
  saveOrderSuccess(state, data) {
    const index = state.orders.findIndex((o) => o.id === data.order.id);
    state.orders.splice(index, 1, data.order);
    state.order = data.order;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
