import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {
  saveNote({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/notes`,
        data: {
          ...data.fields
        },
        method: "POST"
      })
        .then((resp) => {
          if (data.fields.customer_id) {
            commit("drum/customers/addNote", { ...resp.data }, { root: true });
          } else if (data.fields.itinerary_id) {
            commit("drum/tours/addNote", { ...resp.data }, { root: true });
          }

          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteNote({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/notes/${data.noteId}`,
        method: "DELETE"
      })
        .then(() => {
          if (data.customerId) {
            commit("drum/customers/removeNote", { noteId: data.noteId }, { root: true });
          } else if (data.itineraryId) {
            commit("drum/tours/removeNote", { noteId: data.noteId }, { root: true });
          }

          resolve();
        })
        .catch(() => reject());
    });
  }
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};