import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  tasks: [],
  archived: [],
  assigned_work_chunks: [],
  user_overview: [],
  time_logged_today: [],
  time_logged_yesterday: [],
};

// getters
const getters = {
  all: (state) => state.tasks,
  archived: (state) => state.archived,
  get: (state) => state.task,
  assignedWorkChunks: (state) => state.assigned_work_chunks,
  userOverview: (state) => state.user_overview,
};

// actions
const actions = {
  loadTasks({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/tasking/recurring`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadTasksSuccess", resp.data);
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  loadMemberTime({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/tasking/tasks/time-analysis/${
          data.userId
        }`,

        method: "POST",
      })
        .then((resp) => {
          commit("loadMemberTimeSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  saveWorkChunk({ commit }, data) {
    let url = `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/chunks`;

    if (data.isEditing) {
      url = url + `/${data.id}`;
    }

    return new Promise((resolve, reject) => {
      axios({
        url: url,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          const work_chunk = resp.data.work_chunk;
          commit("saveWorkChunkSuccess", {
            work_chunk,
            isEditing: data.isEditing,
            fromWorkGroup: data.fromWorkGroup,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  closeWorkChunk({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/workload/chunks/${
          data.chunkId
        }/close`,
        method: "POST",
      })
        .then((resp) => {
          const work_chunk = resp.data.work_chunk;
          commit("closeWorkChunksSuccess", { work_chunk });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  completeWorkChunk({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/chunks/${data.chunkId}/complete`,
        method: "POST",
      })
        .then((resp) => {
          const work_chunk = resp.data.work_chunk;
          commit("completeWorkChunksSuccess", { work_chunk });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveTask({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/tasking/recurring/${data.taskId}`
      : `${getApiUrl(data.appId)}/module/tasking/recurring`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveTaskSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },
  restoreTask({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/tasking/recurring/${
          data.taskId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreTaskSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadTasksSuccess(state, data) {
    state.tasks = data.tasks;
    state.archived = data.archived;
    state.assigned_work_chunks = data.assigned_work_chunks;
  },
  loadUserOverviewSuccess(state, data) {
    state.user_overview = data.userOverview;
    state.time_logged_today = data.timeLoggedToday;
    state.time_logged_yesterday = data.timeLoggedYesterday;
  },
  loadMemberTimeSuccess(state, data) {
    state.user_overview = data.userOverview;
    state.time_logged_today = data.timeLoggedToday;
    state.time_logged_yesterday = data.timeLoggedYesterday;
  },

  restoreTaskSuccess(state, data) {
    const index = state.archived.findIndex((c) => c.id === data.task.id);
    state.archived.splice(index, 1);
    state.tasks.push(data.task);
  },
  saveTaskSuccess(state, data) {
    if (data.isEditing) {
      const index = state.tasks.findIndex((c) => c.id === data.task.id);
      state.tasks.splice(index, 1, data.task);
    } else {
      state.tasks.push(data.task);
    }
  },

  saveWorkChunkSuccess(state, data) {
    if (data.isEditing) {
      const index = state.assigned_work_chunks.findIndex(
        (c) => c.id === data.work_chunk.id
      );
      state.assigned_work_chunks.splice(index, 1, data.work_chunk);
    } else {
      state.assigned_work_chunks.push(data.work_chunk);
    }
  },

  closeWorkChunksSuccess(state, data) {
    const index = state.assigned_work_chunks.findIndex(
      (c) => c.id === data.work_chunk.id
    );
    state.assigned_work_chunks.splice(index, 1);
  },

  completeWorkChunksSuccess(state, data) {
    const index = state.assigned_work_chunks.findIndex(
      (c) => c.id === data.work_chunk.id
    );
    state.assigned_work_chunks.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
