import store from "@/stores/";

export default [
  {
    path: "applications",
    component: () => import("@/modules/lhl/views/Template"),
    children: [
      {
        path: "puppy",

        component: () => import("@/modules/lhl/views/Template"),
        children: [
          {
            path: "",
            name: "module-lhl-applications-puppy",
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("lhl/applications/loadApplications", {
                  appId: to.params.id,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
            component: () =>
              import("@/modules/lhl/views/applications/puppy/Dashboard"),
          },
          {
            path: "archived",
            name: "module-lhl-applications-puppy-archived",
            component: () =>
              import("@/modules/lhl/views/applications/puppy/Archived"),
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("lhl/applications/loadApplications", {
                  appId: to.params.id,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
          },
          {
            path: ":applicationId",
            name: "module-lhl-applications-puppy-individual",
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("lhl/litters/loadLitters", {
                  appId: to.params.id,
                }),
                store.dispatch("lhl/breeds/loadBreeds", {
                  appId: to.params.id,
                }),
                store.dispatch("lhl/customers/loadCustomers", {
                  appId: to.params.id,
                }),
                store.dispatch("lhl/applications/loadApplication", {
                  appId: to.params.id,
                  applicationId: to.params.applicationId,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
            component: () =>
              import("@/modules/lhl/views/applications/puppy/Individual"),
          },
        ],
      },
      {
        path: "fch",
        name: "module-lhl-applications-fch",
        component: () => import("@/modules/lhl/views/Template"),
        children: [
          {
            path: "",
            name: "module-lhl-applications-fch",
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("lhl/applications/loadFchApplications", {
                  appId: to.params.id,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
            component: () =>
              import("@/modules/lhl/views/applications/fch/Dashboard"),
          },
          {
            path: "archived",
            name: "module-lhl-applications-fch-archived",
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("lhl/applications/loadFchApplications", {
                  appId: to.params.id,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
            component: () =>
              import("@/modules/lhl/views/applications/fch/Archived"),
          },
          {
            path: ":applicationId",
            name: "module-lhl-applications-fch-individual",
            beforeEnter: (to, from, next) => {
              store.commit("showHideLoader", true);
              Promise.all([
                store.dispatch("lhl/litters/loadLitters", {
                  appId: to.params.id,
                }),
                store.dispatch("lhl/breeds/loadBreeds", {
                  appId: to.params.id,
                }),
                store.dispatch("lhl/customers/loadCustomers", {
                  appId: to.params.id,
                }),
                store.dispatch("lhl/applications/loadFchApplication", {
                  appId: to.params.id,
                  applicationId: to.params.applicationId,
                }),
              ]).then(() => {
                store.commit("showHideLoader", false);
                next();
              });
            },
            component: () =>
              import("@/modules/lhl/views/applications/fch/Individual"),
          },
        ],
      },
    ],
  },
];
