import axios from "axios";

// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {
  changePassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("changePasswordRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/account/change-password`,
        data: {
          email: data.email,
          current_password: data.current_password,
          password: data.password,
          password_confirmation: data.password_confirmation,
        },
        method: "POST",
      })
        .then(() => {
          commit("changePasswordSuccess");
          resolve();
        })
        .catch((e) => {
          commit("changePasswordError");
          reject(e);
        });
    });
  },
  uploadAvatar({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("uploadAvatarRequest");
      let formData = new FormData();
      formData.append("avatar", data.avatar);

      axios
        .post(
          `${process.env.VUE_APP_ACCOUNTS_URL}/api/account/upload-avatar`,
          formData
        )
        .then((resp) => {
          const avatar = resp.data.avatar;
          commit("uploadAvatarSuccess", { avatar }, { root: true });
          resolve();
        })
        .catch((e) => {
          commit("uploadAvatarError");
          reject(e);
        });
    });
  },
  deleteAvatar({ commit }) {
    return new Promise((resolve, reject) => {
      commit("deleteAvatarRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/account/delete-avatar`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteAvatarSuccess", {}, { root: true });
          resolve();
        })
        .catch(() => {
          commit("deleteAvatarError");
          reject();
        });
    });
  },
  updateInformation({ commit }, data) {
    commit("updateInformationRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/account/update`,
        data: {
          ...data,
        },
        method: "POST",
      })
        .then((resp) => {
          const user = resp.data.user;
          commit("updateInformationSuccess", { user }, { root: true });
          resolve();
        })
        .catch((e) => {
          commit("updateInformationError");
          reject(e);
        });
    });
  },
};

// mutations
const mutations = {
  changePasswordRequest() {},
  changePasswordSuccess() {},
  changePasswordError() {},
  uploadAvatarRequest() {},
  uploadAvatarError() {},
  deleteAvatarRequest() {},
  deleteAvatarError() {},
  updateInformationRequest() {},
  updateInformationError() {},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
