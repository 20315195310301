import store from "@/stores/";

export default [
  {
    path: "tasks",
    component: () => import("@/modules/lhl/views/Template"),
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      Promise.all([
        store.dispatch("lhl/tasks/loadTasks", {
          appId: to.params.id,
        }),
      ]).then(() => {
        store.commit("showHideLoader", false);
        next();
      });
    },
    children: [
      {
        path: "",
        name: "module-lhl-tasks",
        component: () => import("@/modules/lhl/views/tasks/Dashboard"),
      },
    ],
  },
];
