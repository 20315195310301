import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  tours: [],
  tour: {},
  archived: [],
  itinerary: {},
};

// getters
const getters = {
  proposals: (state) => {
    return state.tours.filter((t) => t.status === "proposal");
  },
  tours: (state) => {
    if (state.tours) {
      return state.tours.filter((t) => t.status === "tour");
    }

    return state.tours;
  },
};

// actions
const actions = {
  loadItinerary({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadItinerarySuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadTours({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/tours`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadToursSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadProposals({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/tours/proposals`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadToursSuccess", { ...resp.data });

          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteProposal({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/tours/proposals/${
          data.id
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteProposalSuccess", { proposalId: data.id });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteItinerary({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.id}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteItinerarySuccess", { itineraryId: data.id });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteTour({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/tours/${data.id}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteTourSuccess", { tourId: data.id });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadTour({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/tours/${data.tourId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadTourSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveTour({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/drumgolf/tours/${data.tourId}`
      : `${getApiUrl(data.appId)}/module/drumgolf/tours`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("saveTourSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveDeposit({ commit }, data) {
    const url = `${getApiUrl(data.appId)}/module/drumgolf/tours/${
      data.tourId
    }/deposit`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("saveDepositSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveItinerary({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}`
      : `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("saveItinerarySuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveItineraryDay({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/days/${data.dayId}`
      : `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/days`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("saveItineraryDaySuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteItineraryDay({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/days/${data.dayId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteItineraryDaySuccess", {
            dayId: data.dayId,
            itineraryId: data.itineraryId,
            tourId: data.tourId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateItineraryDayOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/days/order`,
        method: "POST",
        data: {
          displayOrder: data.displayOrder,
        },
      })
        .then((resp) => {
          commit("updateItineraryDayOrderSuccess", {
            days: resp.data.days,
            itineraryId: data.itineraryId,
            tourId: data.tourId,
          });
          resolve();
        })
        .catch(() => reject());
    });
  },

  saveItineraryCost({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/costs/${data.costId}`
      : `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/costs`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("saveItineraryCostSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveItineraryCostPayment({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/costs/${data.costId}/payments/${
          data.paymentId
        }`
      : `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/costs/${data.costId}/payments`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("saveItineraryCostPaymentSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteItineraryCostPayment({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/costs/${data.costId}/payments/${
          data.paymentId
        }`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteItineraryCostPaymentSuccess", {
            costId: data.costId,
            paymentId: data.paymentId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  deleteItineraryCost({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/costs/${data.costId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteItineraryCostSuccess", {
            costId: data.costId,
          });
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  saveItineraryPayment({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/payments/${data.paymentId}`
      : `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/payments`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("saveItineraryPaymentSuccess", {
            responseData: resp.data,
            requestData: data,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteItineraryPayment({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/payments/${data.paymentId}`,
        data: {
          ...data.fields,
        },
        method: "DELETE",
      })
        .then((resp) => {
          commit("deleteItineraryPaymentSuccess", {
            responseData: resp.data,
            requestData: data,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveItineraryPrice({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/prices/${data.priceId}`
      : `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/prices`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("saveItineraryPriceSuccess", {
            responseData: resp.data,
            requestData: data,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteItineraryPrice({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/prices/${data.priceId}`,
        data: {
          ...data.fields,
        },
        method: "DELETE",
      })
        .then((resp) => {
          commit("deleteItineraryPriceSuccess", {
            responseData: resp.data,
            requestData: data,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveGolfer({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/golfers/${data.golferId}`
      : `${getApiUrl(data.appId)}/module/drumgolf/tours/${data.tourId}/golfers`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("saveGolferSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteGolfer({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/golfers/${data.golferId}`,
        method: "DELETE",
      })
        .then((resp) => {
          commit("deleteGolferSuccess", { golferId: data.golferId });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveGolferItinerary({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/golfers/${data.golferId}`
      : `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/golfers`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("saveGolferItinerarySuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteGolferItinerary({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/golfers/${data.golferId}`,
        method: "DELETE",
      })
        .then((resp) => {
          commit("deleteGolferItinerarySuccess", { golferId: data.golferId });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveItineraryImage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/images`,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("saveItineraryImageSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadItineraryPdf(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/pdf`,
        method: "POST",
        responseType: "arraybuffer",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadItineraryPricingPdf(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/prices/pdf`,
        method: "POST",
        responseType: "arraybuffer",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadItineraryCoverPdf(_, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/cover-sheet`,
        method: "POST",
        responseType: "arraybuffer",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveItineraryInvoicing({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/drumgolf/tours/${
          data.tourId
        }/itineraries/${data.itineraryId}/invoicing`,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveItineraryInvoicingSuccess", resp);
          resolve();
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadToursSuccess(state, data) {
    state.tours = data.tours;
    state.archived = data.archived;
  },
  loadTourSuccess(state, data) {
    state.tour = data.tour;
    state.tour.archivedItineraries = data.archivedItineraries;
  },
  deleteProposalSuccess(state, data) {
    const index = state.tours.findIndex((t) => t.id === data.proposalId);
    state.archived.push(state.tours[index]);
    state.tours.splice(index, 1);
  },
  deleteItinerarySuccess(state, data) {
    const index = state.tour.itineraries.findIndex(
      (i) => i.id === data.itineraryId
    );
    state.tour.archivedItineraries.push(state.tour.itineraries[index]);
    state.tour.itineraries.splice(index, 1);
  },
  deleteTourSuccess(state, data) {
    const index = state.tours.findIndex((t) => t.id === data.tourId);
    state.archived.push(state.tours[index]);
    state.tours.splice(index, 1);
  },
  loadItinerarySuccess(state, data) {
    state.itinerary = data.itinerary;
  },
  saveTourSuccess(state, data) {
    if (data.isEditing) {
      const index = state.tours.findIndex((t) => t.id === data.tour.id);
      state.tours.splice(index, 1, data.tour);
      state.tour = data.tour;
    } else {
      state.tours.push(data.tour);
    }
  },
  saveDepositSuccess(state, data) {
    state.tour = data.tour;
  },
  saveItinerarySuccess(state, data) {
    if (data.isEditing) {
      const index = state.tour.itineraries.findIndex(
        (i) => i.id === data.itinerary.id
      );
      state.tour.itineraries.splice(index, 1, data.itinerary);
      state.itinerary = data.itinerary;
    } else {
      state.tour.itineraries.push(data.itinerary);
    }
  },
  saveItineraryInvoicingSuccess(state, data) {
    const index = state.tour.itineraries.findIndex(
      (i) => i.id === parseInt(data.itineraryId)
    );

    state.itinerary = data.itinerary;
    state.tour.itineraries.splice(index, 1, data.itinerary);
  },
  saveItineraryDaySuccess(state, data) {
    if (data.isEditing) {
      const index = state.itinerary.days.findIndex((d) => d.id === data.day.id);
      state.itinerary.days.splice(index, 1, data.day);
    } else {
      state.itinerary.days.push(data.day);
    }
  },
  deleteItineraryDaySuccess(state, data) {
    const dayIndex = state.itinerary.days.findIndex(
      (d) => d.id === parseInt(data.dayId)
    );
    // const itineraryIndex = state.tour.itineraries.findIndex(
    //   (i) => i.id === parseInt(data.itineraryId)
    // );
    // const tourIndex = state.tours.findIndex(
    //   (t) => t.id === parseInt(data.tourId)
    //  );
    state.itinerary.days.splice(dayIndex, 1);
    //  state.tour.itineraries[itineraryIndex].days.splice(dayIndex, 1);
  },
  updateItineraryDayOrderSuccess(state, data) {
    const itineraryIndex = state.tour.itineraries.findIndex(
      (i) => i.id === parseInt(data.itineraryId)
    );
    const tourIndex = state.tours.findIndex(
      (t) => t.id === parseInt(data.tourId)
    );

    state.itinerary.days = data.days;
    state.tour.itineraries[itineraryIndex].days = data.days;
    state.tour[tourIndex].itineraries[itineraryIndex].days = data.days;
  },
  saveItineraryCostSuccess(state, data) {
    if (data.isEditing) {
      const index = state.itinerary.costs.findIndex(
        (c) => c.id === data.cost.id
      );
      state.itinerary.costs.splice(index, 1, data.cost);
    } else {
      state.itinerary.costs.push(data.cost);
    }
  },
  saveItineraryCostPaymentSuccess(state, data) {
    const costIndex = state.itinerary.costs.findIndex(
      (c) => c.id === parseInt(data.payment.cost_id)
    );
    if (data.isEditing) {
      const paymentIndex = state.itinerary.costs[costIndex].payments.findIndex(
        (p) => p.id === parseInt(data.payment.id)
      );
      state.itinerary.costs[costIndex].payments.splice(
        paymentIndex,
        1,
        data.payment
      );
    } else {
      state.itinerary.costs[costIndex].payments.push(data.payment);
    }
  },
  deleteItineraryCostPaymentSuccess(state, data) {
    const costIndex = state.itinerary.costs.findIndex(
      (c) => c.id === parseInt(data.costId)
    );
    const paymentIndex = state.itinerary.costs[costIndex].payments.findIndex(
      (p) => p.id === parseInt(data.paymentId)
    );
    state.itinerary.costs[costIndex].payments.splice(paymentIndex, 1);
  },
  deleteItineraryCostSuccess(state, data) {
    const costIndex = state.itinerary.costs.findIndex(
      (c) => c.id === parseInt(data.costId)
    );
    state.itinerary.costs.splice(costIndex, 1);
  },
  saveItineraryPaymentSuccess(state, data) {
    if (data.requestData.isEditing) {
      const index = state.itinerary.payments.findIndex(
        (p) => p.id === data.responseData.payment.id
      );
      state.itinerary.payments.splice(index, 1, data.responseData.payment);
    } else {
      state.itinerary.payments.push(data.responseData.payment);
    }

    if (data.requestData.fields.changeStatus === true) {
      state.itinerary.status = data.responseData.itinerary_status;
      const itineraryIndex = state.tour.itineraries.findIndex(
        (i) => i.id === parseInt(data.requestData.itineraryId)
      );
      state.tour.itineraries[itineraryIndex].status =
        data.responseData.itinerary_status;

      state.tour.status = data.responseData.tour_status;
      const tourIndex = state.tours.findIndex(
        (t) => t.id === parseInt(data.requestData.tourId)
      );
      state.tours[tourIndex].status = data.responseData.tour_status;
    }
  },
  deleteItineraryPaymentSuccess(state, data) {
    const index = state.itinerary.payments.findIndex(
      (p) => p.id === parseInt(data.requestData.paymentId)
    );
    state.itinerary.payments.splice(index, 1);

    if (data.requestData.fields.changeStatus === true) {
      state.itinerary.status = data.responseData.itinerary_status;
      const itineraryIndex = state.tour.itineraries.findIndex(
        (i) => i.id === parseInt(data.requestData.itineraryId)
      );
      state.tour.itineraries[itineraryIndex].status =
        data.responseData.itinerary_status;

      state.tour.status = data.responseData.tour_status;
      const tourIndex = state.tours.findIndex(
        (t) => t.id === parseInt(data.requestData.tourId)
      );
      state.tours[tourIndex].status = data.responseData.tour_status;
    }
  },
  saveItineraryPriceSuccess(state, data) {
    if (data.requestData.isEditing) {
      const index = state.itinerary.pricing.findIndex(
        (c) => c.id === data.responseData.price.id
      );
      state.itinerary.pricing.splice(index, 1, data.responseData.price);
    } else {
      state.itinerary.pricing.push(data.responseData.price);
    }

    if (data.requestData.fields.changeItineraryStatus === true) {
      state.itinerary.status = data.responseData.itinerary_status;
      const itineraryIndex = state.tour.itineraries.findIndex(
        (i) => i.id === parseInt(data.requestData.itineraryId)
      );
      state.tour.itineraries[itineraryIndex].status =
        data.responseData.itinerary_status;

      state.tour.status = data.responseData.tour_status;
      const tourIndex = state.tours.findIndex(
        (t) => t.id === parseInt(data.requestData.tourId)
      );
      state.tours[tourIndex].status = data.responseData.tour_status;
    }
  },
  deleteItineraryPriceSuccess(state, data) {
    const index = state.itinerary.pricing.findIndex(
      (p) => p.id === parseInt(data.requestData.priceId)
    );
    state.itinerary.pricing.splice(index, 1);

    if (data.requestData.fields.changeStatus === true) {
      state.itinerary.status = data.responseData.itinerary_status;
      const itineraryIndex = state.tour.itineraries.findIndex(
        (i) => i.id === parseInt(data.requestData.itineraryId)
      );
      state.tour.itineraries[itineraryIndex].status =
        data.responseData.itinerary_status;

      state.tour.status = data.responseData.tour_status;
      const tourIndex = state.tours.findIndex(
        (t) => t.id === parseInt(data.requestData.tourId)
      );
      state.tours[tourIndex].status = data.responseData.tour_status;
    }
  },
  addNote(state, data) {
    state.itinerary.notes.unshift(data.note);
  },
  removeNote(state, data) {
    const index = state.itinerary.notes.findIndex((n) => n.id === data.noteId);
    state.itinerary.notes.splice(index, 1);
  },
  saveGolferSuccess(state, data) {
    state.tour.customers = data.golfers;

    if (data.hasOwnProperty("itineraryGolfers")) {
      state.itinerary.customers = data.itineraryGolfers;
    }
  },
  deleteGolferSuccess(state, data) {
    const index = state.tour.customers.findIndex((c) => c.id === data.golferId);
    state.tour.customers.splice(index, 1);
  },
  saveGolferItinerarySuccess(state, data) {
    state.itinerary.customers = data.golfers;
  },
  deleteGolferItinerarySuccess(state, data) {
    const index = state.itinerary.customers.findIndex(
      (c) => c.id === data.golferId
    );
    state.itinerary.customers.splice(index, 1);
  },
  saveItineraryImageSuccess(state, data) {
    state.itinerary[data.type] = data.image;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
