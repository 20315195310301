import store from "@/stores/";

export default [
  {
    path: "checklists",
    component: () => import("@/modules/sbpm/views/Template"),

    children: [
      {
        path: "",
        name: "module-sbpm-checklists",
        component: () => import("@/modules/sbpm/views/checklists/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("sbpm/checklistsStore/loadChecklists", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },

      {
        path: ":checklistId",
        name: "module-sbpm-checklists-individual",
        component: () => import("@/modules/sbpm/views/checklists/Individual"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("sbpm/checklistsStore/loadChecklist", {
              appId: to.params.id,
              checklistId: to.params.checklistId,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
    ],
  },
];
