import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  redirects: [],
};

// getters
const getters = {
  allRedirects: (state) => state.redirects,
};

// actions
const actions = {
  loadRedirects({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/seo/redirects`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadRedirectsSuccess", resp.data);
          resolve();
        })
        .catch((err) => reject(err));
    });
  },
  saveRedirect({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/seo/redirects/${data.redirectId}`
      : `${getApiUrl(data.appId)}/module/seo/redirects`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveRedirectSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },
};

// mutations
const mutations = {
  loadRedirectsSuccess(state, data) {
    state.redirects = data.redirects;
  },
  saveRedirectSuccess(state, data) {
    if (data.isEditing) {
      const index = state.redirects.findIndex((c) => c.id === data.redirect.id);
      state.redirects.splice(index, 1, data.redirect);
    } else {
      state.redirects.push(data.redirect);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
