import AdminRoutes from "./admin.routes";
import CustomerRoutes from "./customers.routes";
import PaymentSchedule from "./payment-schedule.routes";
import ProposalRoutes from "./proposals.routes";
import SettingsRoutes from "./settings.routes";
import TourRoutes from "./tours.routes";

export default [
  {
    path: "drumgolf",
    component: () => import("@/modules/drum/views/Template"),
    children: [
      ...AdminRoutes,
      ...CustomerRoutes,
      ...ProposalRoutes,
      ...TourRoutes,
      ...SettingsRoutes,
      ...PaymentSchedule,
    ],
  },
];
