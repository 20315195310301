import store from "@/stores/";

export default [
  {
    path: "",
    name: "module-paw-subscriptions",
    component: () => import("@/modules/paw/views/subscriptions/Dashboard"),
  },
  {
    path: "temporary-statistics",
    name: "module-paw-subscriptions-temp-stats",
    component: () =>
      import("@/modules/paw/views/subscriptions/TemporaryStatistics"),
  },
  {
    path: ":subscriptionId",
    name: "module-paw-subscriptions-individual",
    beforeEnter: (to, from, next) => {
      store.commit("showHideLoader", true);
      store
        .dispatch("paw/loadSubscription", {
          appId: to.params.id,
          subscriptionId: to.params.subscriptionId,
        })
        .then(() => {
          store.commit("showHideLoader", false);
          next();
        });
    },
    component: () => import("@/modules/paw/views/subscriptions/Individual"),
  },
];
