import store from "@/stores/";

export default [
  {
    path: "properties",
    component: () => import("@/modules/easylets/views/Template"),
    children: [
      {
        path: "",
        name: "module-easylets-properties",
        component: () =>
          import("@/modules/easylets/views/properties/Dashboard"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("easylets/propertiesStore/loadProperties", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: "archived",
        name: "module-easylets-properties-archived",
        component: () => import("@/modules/easylets/views/properties/Archived"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("easylets/propertiesStore/loadProperties", {
              appId: to.params.id,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
      {
        path: ":propertyId",
        name: "module-easylets-properties-individual",
        component: () =>
          import("@/modules/easylets/views/properties/Individual"),
        beforeEnter: (to, from, next) => {
          store.commit("showHideLoader", true);
          Promise.all([
            store.dispatch("easylets/propertiesStore/loadProperty", {
              appId: to.params.id,
              propertyId: to.params.propertyId,
            }),
          ]).then(() => {
            store.commit("showHideLoader", false);
            next();
          });
        },
      },
    ],
  },
];
