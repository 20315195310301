import axios from "axios";
import { getApiUrl } from "@/utils";

// initial state
const state = {
  work_chunks: [],
  work_chunks_customer: [],
  assigned_work_chunks: [],
  groups: [],
  groups_widget: [],
  work_group: {},
  archived_groups: [],
  closed_groups: [],
  work_chunk: {},
  user: {},
  tasks: [],
};

// getters
const getters = {
  all: (state) => state.work_chunks,

  archivedGroups: (state) => state.archived_groups,

  tasks: (state) => state.tasks,

  groupsWidget: (state) => state.groups_widget,

  openWorkChunks: (state) => {
    return state.work_chunks.filter((c) => c.status === "Open");
  },

  openOrCompletedWorkChunks: (state) => {
    return state.work_chunks.filter(
      (c) => c.status === "Open" || c.status === "Completed"
    );
  },

  activity: (state) => {
    return state.work_chunks.filter((c) => c.type === "Diary");
  },

  closedWorkGroups: (state) => {
    return state.groups.filter((c) => c.status === "Closed");
  },

  notInGroupWorkChunks: (state) => {
    return state.work_chunks.filter(
      (c) =>
        c.groups.length == 0 &&
        c.type !== "Diary" &&
        (c.status === "Open" || c.status === "Closed")
    );
  },

  assignedWorkChunks: (state) => state.assigned_work_chunks,

  parentWorkGroup: (state) => {
    return state.groups.filter((c) => c.parent_workgroup == null);
  },
};

// actions
const actions = {
  loadModule({ commit }, data) {
    const url = data.hasOwnProperty("customerId")
      ? `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/customers/${data.customerId}/work-chunks`
      : `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload`;

    return new Promise((resolve, reject) => {
      commit("loadModuleRequest");
      axios({
        url,
        method: "GET",
      })
        .then((resp) => {
          const work_chunks = resp.data.work_chunks;
          const groups = resp.data.groups;
          const user = resp.data.user;
          const archived = resp.data.archived;
          const tasks = resp.data.tasks;
          const assigned_work_chunks = resp.data.assigned_work_chunks;
          commit("loadModuleSuccess", {
            work_chunks,
            groups,
            user,
            tasks,
            archived,
            assigned_work_chunks,
          });
          resolve(resp);
        })
        .catch((err) => {
          commit("loadModuleError");
          reject(err);
        });
    });
  },

  loadWorkChunk({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("loadIndWorkChunkRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/chunks/${data.chunkId}`,
        method: "GET",
      })
        .then((resp) => {
          const work_chunk = resp.data.work_chunk;
          commit("loadIndWorkChunkSuccess", { work_chunk });
          resolve();
        })
        .catch(() => {
          commit("loadIndWorkChunkError");
          reject();
        });
    });
  },

  loadGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("loadGroupRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/groups/${data.groupId}`,
        method: "GET",
      })
        .then((resp) => {
          const work_group = resp.data.work_group;
          commit("loadGroupSuccess", { work_group });
          resolve();
        })
        .catch(() => {
          commit("loadGroupError");
          reject();
        });
    });
  },

  loadWorkGroups({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("moduleLoadWorkGroupRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/groups`,
        method: "GET",
      })
        .then((resp) => {
          const groups = resp.data.groups;
          commit("moduleLoadWorkGroupSuccess", {
            groups,
          });
          resolve(resp);
        })
        .catch((err) => {
          commit("moduleLoadWorkGroupError");
          reject(err);
        });
    });
  },

  loadWorkChunks({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("loadWorkChunksRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/chunks`,
        method: "GET",
      })
        .then((resp) => {
          const workChunks = resp.data.work_chunks;
          commit("loadWorkChunksSuccess", { workChunks });
          resolve(resp);
        })
        .catch((err) => {
          commit("loadWorkChunksError");
          reject(err);
        });
    });
  },

  closeWorkChunk({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/workload/chunks/${
          data.chunkId
        }/close`,
        method: "POST",
      })
        .then((resp) => {
          const work_chunk = resp.data.work_chunk;
          commit("closeWorkChunksSuccess", { work_chunk });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  completeWorkChunk({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/chunks/${data.chunkId}/complete`,
        method: "POST",
      })
        .then((resp) => {
          const work_chunk = resp.data.work_chunk;
          commit("closeWorkChunksSuccess", { work_chunk });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addGroup({ commit }, data) {
    commit("addGroupRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/groups`,
        data: {
          name: data.name,
          customer_id: data.customer_id,
          type: data.type,
          parent_workgroup: data.parent_workgroup,
          is_internal: data.is_internal,
          status: data.status,
          description: data.description,
        },
        method: "POST",
      })
        .then((resp) => {
          const group = resp.data.group;
          commit("addGroupSuccess", { group });
          resolve(resp.data);
        })
        .catch((err) => {
          commit("addGroupError");
          reject(err);
        });
    });
  },

  editGroup({ commit }, data) {
    commit("editGroupRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/groups/${data.groupId}`,
        data: {
          name: data.name,
          customer_id: data.customer_id,
          type: data.type,
          parent_workgroup: data.parent_workgroup,
          is_internal: data.is_internal,
          status: data.status,
          description: data.description,
        },
        method: "POST",
      })
        .then((resp) => {
          const group = resp.data.group;
          commit("editGroupSuccess", { group });
          resolve(resp.data);
        })
        .catch((err) => {
          commit("editGroupError");
          reject(err);
        });
    });
  },

  reactivateGroup({ commit }, data) {
    commit("reactivateGroupRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/groups/${data.groupId}/reactivate`,
        method: "POST",
      })
        .then((resp) => {
          const group = resp.data.group;
          commit("reactivateGroupSuccess", { group, id: data.id });
          resolve();
        })
        .catch((err) => {
          commit("reactivateGroupError");
          reject(err);
        });
    });
  },

  reopenGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/groups/${data.groupId}/reopen`,
        method: "POST",
      })
        .then((resp) => {
          commit("reOpenGroupSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  archiveWorkGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("archiveWorkGroupRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/groups/${data.groupId}/delete`,
        data: {
          id: data.groupId,
        },
        method: "POST",
      })
        .then(() => {
          commit("archiveWorkGroupSuccess", { groupId: data.groupId });
          resolve();
        })
        .catch(() => {
          commit("archiveWorkGroupError");
          reject();
        });
    });
  },

  closeWorkGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("archiveWorkGroupRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/groups/${data.groupId}/close`,
        method: "POST",
      })
        .then((resp) => {
          commit("archiveWorkGroupSuccess", {
            ...resp.data,
            groupId: data.groupId,
          });
          resolve();
        })
        .catch(() => {
          commit("archiveWorkGroupError");
          reject();
        });
    });
  },

  deleteLogTime({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("archiveLogTimeRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/logtime/${data.id}/delete`,
        data: {
          id: data.id,
        },
        method: "POST",
      })
        .then(() => {
          commit("archiveLogTimeSuccess", { id: data.id });
          resolve();
        })
        .catch((err) => {
          commit("archiveLogTimeError");
          reject(err);
        });
    });
  },

  editLogTime({ commit }, data) {
    commit("editLogTimeRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/logtime/${data.logId}`,
        data: {
          date: data.date,
          notes: data.notes,
          title: data.title,
          minutes: data.minutes,
          chunk_id: data.chunk_id,
        },
        method: "POST",
      })
        .then((resp) => {
          const time_log = resp.data.time_log;
          commit("editLogTimeSuccess", { time_log });
          resolve(resp.data);
        })
        .catch((err) => {
          commit("editLogTimeError");
          reject(err);
        });
    });
  },

  logTime({ commit }, data) {
    commit("logTimeRequest");
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/logtime`,
        data: {
          date: data.date,
          notes: data.notes,
          title: data.title,
          hours: data.hours,
          minutes: data.minutes,
          is_internal: data.is_internal,
          customer_id: data.customer_id,
          chunk_id: data.chunk_id,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("logTimeSuccess", {
            ...resp.data,
          });
          resolve();
        })
        .catch((err) => {
          commit("logTimeError");
          reject(err);
        });
    });
  },

  logTimeChunk({ commit }, data) {
    const url = `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/logtime`;
    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("logTimeChunkSuccess", {
            ...resp.data,
          });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  loadCustomerChunks({ commit }, data) {
    const url = `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/customer-chunks`;
    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          customerId: data.customerId,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("loadCustomerChunksSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  loadCustomerGroups({ commit }, data) {
    const url = `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/customer-groups`;
    return new Promise((resolve, reject) => {
      axios({
        url,
        data: {
          customerId: data.customerId,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("loadCustomerGroupsSuccess", {
            ...resp.data,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  archiveWorkload({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("archiveWorkloadRequest");
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/chunks/${data.id}/delete`,
        data: {
          id: data.id,
        },
        method: "POST",
      })
        .then(() => {
          commit("archiveWorkloadSuccess", { id: data.id });
          resolve();
        })
        .catch(() => {
          commit("archiveWorkloadError");
          reject();
        });
    });
  },

  saveWorkChunk({ commit }, data) {
    let url = `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/chunks`;

    if (data.isEditing) {
      url = url + `/${data.id}`;
    }

    return new Promise((resolve, reject) => {
      axios({
        url: url,
        data: {
          ...data.fields,
        },
        method: "POST",
      })
        .then((resp) => {
          const work_chunk = resp.data.work_chunk;
          commit("saveWorkChunkSuccess", {
            work_chunk,
            isEditing: data.isEditing,
            fromWorkGroup: data.fromWorkGroup,
            fromTasking: data.fromTasking,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  saveTask({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/tasking/recurring/${data.taskId}`
      : `${getApiUrl(data.appId)}/module/tasking/recurring`;

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: data.fields,
        method: "POST",
      })
        .then((resp) => {
          commit("saveTaskSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  saveWorkChunkNote({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: data.isEditing
          ? `${getApiUrl(data.appId)}/module/workload/chunks/${
              data.chunkId
            }/notes/${data.noteId}`
          : `${getApiUrl(data.appId)}/module/workload/chunks/${
              data.chunkId
            }/notes`,
        method: "POST",
        data: data.fields,
      })
        .then((resp) => {
          commit("saveWorkChunkNoteSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },

  filterWorkGroupTimeLogged({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${process.env.VUE_APP_ACCOUNTS_URL}/api/apps/${data.appId}/module/workload/groups/${data.groupId}/filter-time-logged`,
        data: {
          start: data.start,
          end: data.end,
        },
        method: "POST",
      })
        .then((resp) => {
          commit("groupTimeLogged", { work_group: resp.data.work_group });
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  archiveTask({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/tasking/recurring/${data.taskId}`,
        data: {
          id: data.id,
        },
        method: "DELETE",
      })
        .then((resp) => {
          commit("archiveTaskSuccess", { ...resp.data });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  archiveTaskSuccess(state, data) {
    const index = state.tasks.findIndex((c) => c.id === data.task.id);
    state.tasks.splice(index, 1);
    state.archived.push(data.task);
  },
  loadModuleRequest() {},
  loadModuleSuccess(state, data) {
    state.work_chunks = data.work_chunks;
    state.groups = data.groups;
    state.user = data.user;
    state.tasks = data.tasks;
    state.groups_widget = data.groups;
    state.archived_groups = data.archived;
    state.assigned_work_chunks = data.assigned_work_chunks;
  },
  loadModuleError() {},

  loadIndWorkChunkRequest() {},
  loadIndWorkChunkSuccess(state, data) {
    state.work_chunk = data.work_chunk;
  },
  loadIndWorkChunkError() {},

  loadCustomerChunksSuccess(state, data) {
    state.work_chunks_customer = data.workChunks;
  },
  loadCustomerGroupsSuccess(state, data) {
    state.groups_widget = data.groups;
  },
  loadGroupRequest() {},
  loadGroupSuccess(state, data) {
    state.work_group = data.work_group;
  },
  loadGroupError() {},

  moduleLoadWorkGroupRequest() {},
  moduleLoadWorkGroupSuccess(state, data) {
    state.groups = data.groups;
  },
  moduleLoadWorkGroupError() {},

  loadWorkChunksRequest() {},
  loadWorkChunksSuccess(state, data) {
    state.work_chunks = data.workChunks;
  },
  loadWorkChunksError() {},

  addGroupRequest() {},
  addGroupSuccess(state, data) {
    if (data.group.parent_workgroup != null) {
      const index = state.groups.findIndex(
        (c) => c.id === data.group.parent_workgroup
      );
      state.groups[index].children.unshift(data.group);
    } else {
      state.groups.unshift(data.group);
    }
  },
  addGroupError() {},

  logTimeRequest() {},
  logTimeSuccess() {},
  logTimeError() {},

  logTimeChunkSuccess(state, data) {
    const index = state.work_chunks.findIndex(
      (c) => c.id === data.work_chunk.id
    );
    state.work_chunks.splice(index, 1, data.work_chunk);

    state.work_chunk = data.work_chunk;
  },

  archiveWorkloadRequest() {},
  archiveWorkloadSuccess(state, data) {
    state.work_chunks = state.work_chunks.filter((c) => {
      return c.id !== data.id;
    });
  },
  archiveWorkloadError() {},

  archiveWorkGroupRequest() {},
  archiveWorkGroupSuccess(state, data) {
    const index = state.groups.findIndex((c) => c.id === data.groupId);
    state.groups.splice(index, 1);

    state.archived_groups.push(data.group);
  },
  archiveWorkGroupError() {},

  reactivateGroupRequest() {},
  reactivateGroupSuccess(state, data) {
    const index = state.archived_groups.findIndex(
      (c) => c.id === data.group.id
    );
    state.archived_groups.splice(index, 1);
    state.groups.push(data.group);
  },
  reactivateGroupError() {},

  reOpenGroupSuccess(state, data) {
    const index = state.archived_groups.findIndex(
      (c) => c.id === data.group.id
    );

    state.archived_groups.splice(index, 1);
    state.groups.push(data.group);
  },

  closeWorkChunksSuccess(state, data) {
    const index = state.work_chunks.findIndex(
      (c) => c.id === data.work_chunk.id
    );
    state.work_chunks.splice(index, 1, data.work_chunk);

    const index2 = state.assigned_work_chunks.findIndex(
      (c) => c.id === data.work_chunk.id
    );
    state.assigned_work_chunks.splice(index2, 1);
  },

  archiveLogTimeRequest() {},
  archiveLogTimeSuccess(state, data) {
    state.work_chunk.time_logs = state.work_chunk.time_logs.filter((l) => {
      return l.id !== data.id;
    });
  },
  archiveLogTimeError() {},

  editLogTimeRequest() {},
  editLogTimeSuccess(state, data) {
    const index = state.work_chunk.time_logs.findIndex(
      (l) => l.id === data.time_log.id
    );
    const time_logs = data.time_log;

    state.work_chunk.time_logs[index].date = time_logs.date;
    state.work_chunk.time_logs[index].notes = time_logs.notes;
    state.work_chunk.time_logs[index].title = time_logs.title;
    state.work_chunk.time_logs[index].minutes = time_logs.minutes;
  },
  editLogTimeError() {},

  editGroupRequest() {},
  editGroupSuccess(state, data) {
    state.work_group = data.group;

    const index = state.groups.findIndex((g) => g.id === data.group.id);
    state.groups.splice(index, 1, data.group);

    if (data.group.status == "Closed") {
      const index = state.groups.findIndex((g) => g.id === data.group.id);
      state.groups.splice(index, 1);
      state.archived_groups.push(data.group);
    }

    if (data.group.parent_workgroup != null) {
      const indexParent = state.groups.findIndex(
        (c) => c.id === data.group.parent_workgroup
      );
      state.groups[indexParent].children.unshift(data.group);
    }
  },
  editGroupError() {},

  saveWorkChunkSuccess(state, data) {
    if (data.isEditing) {
      const index = state.work_chunks.findIndex(
        (c) => c.id === data.work_chunk.id
      );
      state.work_chunks.splice(index, 1, data.work_chunk);
    } else {
      state.work_chunks.unshift(data.work_chunk);
    }
    state.work_chunk = data.work_chunk;

    if (data.fromWorkGroup) {
      state.work_group.work_chunks.unshift(data.work_chunk);
    }

    if (data.fromTasking) {
      if (data.isEditing) {
        const index = state.assigned_work_chunks.findIndex(
          (c) => c.id === data.work_chunk.id
        );
        state.assigned_work_chunks.splice(index, 1, data.work_chunk);
      } else {
        state.assigned_work_chunks.push(data.work_chunk);
      }
    }
  },

  saveWorkChunkNoteSuccess(state, data) {
    state.work_chunk.notes.unshift(data.note);
  },

  saveTaskSuccess(state, data) {
    if (data.isEditing) {
      const index = state.tasks.findIndex((c) => c.id === data.task.id);
      state.tasks.splice(index, 1, data.task);
    } else {
      state.tasks.push(data.task);
    }
  },

  groupTimeLogged(state, data) {
    state.work_group = data.work_group;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
