import axios from "axios";
import { getApiUrl } from "@/utils";

const state = {
  processes: [],
  archived: [],
  process: {},
};

// getters
const getters = {
  all: (state) => state.processes,
  archived: (state) => state.archived,
  get: (state) => state.process,
};

// actions
const actions = {
  loadProcesses({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/processes`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadProcessesSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadProcess({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/processes/${data.processId}`,
        method: "GET",
      })
        .then((resp) => {
          commit("loadProcessSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateProcessesDisplayOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/processes/display-order`,
        method: "POST",
        data: {
          displayOrder: data.displayOrder,
        },
      })
        .then((resp) => {
          commit("updateProcessesDisplayOrderSuccess", {
            processes: resp.data.processes,
          });
          resolve();
        })
        .catch((err) => reject(err));
    });
  },

  saveProcess({ commit }, data) {
    const url = data.isEditing
      ? `${getApiUrl(data.appId)}/module/sbpm/processes/${data.processId}`
      : `${getApiUrl(data.appId)}/module/sbpm/processes`;

    let fields = data.fields;

    let formData = new FormData();
    for (const field in fields) {
      if (typeof fields[field] === "boolean") {
        formData.append(field, fields[field] ? 1 : 0);
      } else if (fields[field] === null) {
        formData.append(field, "");
      } else {
        formData.append(field, fields[field]);
      }
    }

    return new Promise((resolve, reject) => {
      axios({
        url,
        data: formData,
        method: "POST",
      })
        .then((resp) => {
          commit("saveProcessSuccess", {
            ...resp.data,
            isEditing: data.isEditing,
          });
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  },
  restoreProcess({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/processes/${
          data.processId
        }/restore`,
        method: "POST",
      })
        .then((resp) => {
          commit("restoreProcessSuccess", { ...resp.data });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteProcess({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${getApiUrl(data.appId)}/module/sbpm/processes/${data.processId}`,
        method: "DELETE",
      })
        .then(() => {
          commit("deleteProcessSuccess", {
            processId: data.processId,
          });
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  loadProcessesSuccess(state, data) {
    state.processes = data.processes;
    state.archived = data.archived;
  },

  loadProcessSuccess(state, data) {
    state.process = data.process;
  },

  saveProcessSuccess(state, data) {
    if (data.isEditing) {
      const index = state.processes.findIndex((p) => p.id === data.process.id);

      state.processes.splice(index, 1, data.process);

      state.process = data.process;
    } else {
      state.processes.push(data.process);
    }
  },
  restoreProcessSuccess(state, data) {
    const index = state.archived.findIndex((c) => c.id === data.process.id);
    state.archived.splice(index, 1);
    state.processes.push(data.process);
  },

  updateProcessesDisplayOrderSuccess(state, data) {
    state.processes = data.processes;
  },

  deleteProcessSuccess(state, data) {
    const index = state.processes.findIndex((c) => c.id === data.processId);
    state.processes.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
